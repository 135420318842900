import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography, Table, Spin, Switch, DatePicker, Button } from 'antd';
import firebase from 'firebase/app';
import 'firebase/firestore';
import moment from 'moment';
import { SearchOutlined, LeftOutlined } from '@ant-design/icons';


const { Title } = Typography;
const { RangePicker } = DatePicker;

const UserSessions = () => {
    const { userId } = useParams();
    const navigate = useNavigate();

    const [sessions, setSessions] = useState([]);
    const [filteredSessions, setFilteredSessions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState(null);
    const [dateRange, setDateRange] = useState([null, null]);

    useEffect(() => {
        const fetchSessions = async () => {
            if (!userId) {
                console.error("No userId provided");
                setLoading(false);
                return;
            }

            try {

                const userRef = firebase.firestore().collection('usersData').doc(userId);
                const userDoc = await userRef.get();
                if (userDoc.exists) {
                    setUserData(userDoc.data());
                } else {
                    console.error("No such user!");
                }


                const sessionsRef = firebase.firestore().collection('shifts')
                    .where('uid', '==', userId)

                const snapshot = await sessionsRef.get();
                const sessionsData = snapshot.docs.map(doc => (doc.data().sessions.map((res, index) => ({
                    id: `${doc.id}-${index}`,
                    startTs: res.startTs,
                    endTs: res.endTs,
                })))).flat().sort((a, b) => b.startTs.seconds - a.startTs.seconds);

                setSessions(sessionsData);
                setFilteredSessions(sessionsData);
            } catch (error) {
                console.error("Error fetching sessions:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchSessions();
    }, [userId]);

    const handleDateRangeChange = (dates) => {
        setDateRange(dates);
        if (!dates || dates.length === 0) {
            setFilteredSessions(sessions);
        } else {
            const [start, end] = dates;
            const filtered = sessions.filter(session => {
                const sessionStart = session.startTs.toDate();
                const sessionEnd = session.endTs ? session.endTs.toDate() : null;
                return (
                    (!start || sessionStart >= start) &&
                    (!end || sessionEnd <= end || sessionStart <= end)
                );
            });
            setFilteredSessions(filtered);
        }
    };


    const handleBack = () => {
        navigate(-1); // This will navigate to the previous page in the history
    };





    const columns = [
        {
            title: 'Start Time',
            dataIndex: 'startTs',
            key: 'startTs',
            render: (startTs) => moment(startTs.toDate()).format('MMMM D, YYYY h:mm:ss A')
        },
        {
            title: 'End Time',
            dataIndex: 'endTs',
            key: 'endTs',
            render: (endTs) => endTs ? moment(endTs.toDate()).format('MMMM D, YYYY h:mm:ss A') : 'N/A'
        },
        {
            title: 'Duration',
            key: 'duration',
            render: (_, record) => {
                if (record.startTs) {
                    const duration = moment.duration(moment(record.endTs?.toDate()).diff(moment(record.startTs.toDate())));
                    return `${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s`;
                }
                return 'N/A';
            }
        },
    ];

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div className="header">
                <Title style={{ color: '#fff' }} level={2}>
                    User Sessions for {userData ? `${userData.name} (Driver ID: ${userData.driverId})` : `User ID: ${userId}`}
                </Title>

                <Button icon={<LeftOutlined />} onClick={handleBack} style={{ marginBottom: 20 }}>Back</Button>
            </div>
            <RangePicker
                style={{ marginBottom: 20, marginTop: 30 }}
                onChange={handleDateRangeChange}
                format="YYYY-MM-DD"
            />
            {loading ? (
                <Spin size="large" />
            ) : (
                <Table
                    dataSource={filteredSessions}
                    columns={columns}
                    rowKey="id"
                    style={{ width: '80%', marginTop: '20px' }}
                />
            )}
        </div>
    );
};

export default UserSessions;