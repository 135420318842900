import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Input, message, Table, Typography, Space, Switch, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import '../pages/css/Home.css';
import 'firebase/firestore';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { refs } from '../firebase/firebase';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import moment from 'moment';

const { Title } = Typography;
const { Option } = Select;

const Meters = ({ toggleDrawer }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [metersData, setMetersData] = useState([]);
  const [filteredMetersData, setFilteredMetersData] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editItemId, setEditItemId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [disabledFilter, setDisabledFilter] = useState('all');
  const [attachmentFilter, setAttachmentFilter] = useState('all');

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
  
    applyFilters();
  }, [metersData, disabledFilter, attachmentFilter]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const querySnapshot = await refs.meters.orderBy('createdAt', 'desc').limit(300).get();
      const newArray = querySnapshot.docs.map((documentSnapshot) => {
        const data = documentSnapshot.data();
        return {
          id: documentSnapshot.id,
          ...data,
          deviceId: data.deviceId || null,
        };
      });
      console.log('Fetched data:', newArray);
      setMetersData(newArray);
    } catch (error) {
      console.error('Error fetching meters:', error.message);
    } finally {
      setIsLoading(false);
    }
  };


  const applyFilters = () => {
    let filteredData = [...metersData];


    // Apply disabled filter
    if (disabledFilter !== 'all') {
      filteredData = filteredData.filter(meter => {
        const isDisabled = meter.disabled === (disabledFilter === 'disabled');
        console.log(`Meter ${meter.id} disabled: ${meter.disabled}, matches filter: ${isDisabled}`);
        return isDisabled;
      });
    }

    // Apply attachment filter
    if (attachmentFilter !== 'all') {
      filteredData = filteredData.filter(meter => {
        const isAttached = meter.deviceId !== null && meter.deviceId !== undefined;
        const matchesFilter = attachmentFilter === 'attached' ? isAttached : !isAttached;
        console.log(`Meter ${meter.id} attached: ${isAttached}, matches filter: ${matchesFilter}`);
        return matchesFilter;
      });
    }

    setFilteredMetersData(filteredData);
  };

  const showModal = (item = null) => {
    form.resetFields(); // Clear form fields when showing modal for "Add New"
    setIsModalVisible(true);

    if (item) {
      // When editing an existing item
      setIsEditing(true);
      setEditItemId(item.id);

      form.setFieldsValue({
        meterId: item.meterId,
        carNumber: item.carNumber,
        carOwner: item.carOwner,
        carType: item.carType,
      });
    } else {
      // When adding a new item
      setIsEditing(false);
      setEditItemId(null); // Ensure no editItemId is set
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinishMeter = async (values) => {
    try {
      const { meterId, carNumber, carOwner, carType, disabled } = values;
      const now = new Date();

      const existingMeter = await refs.meters.where('meterId', '==', meterId).get();
      const existingMeter2 = await refs.meters.where('carNumber', '==', carNumber).get();

      if (!existingMeter.empty && !isEditing) {
        message.error('Meter ID already exists. Please use a different ID.');
        return;
      }
      if (!existingMeter2.empty && !isEditing) {
        message.error('Car Number already exists. Please use a different Number.');
        return;
      }

      if (isEditing && editItemId) {
        await refs.meters.doc(editItemId).update({
          meterId,
          carNumber,
          carOwner,
          carType,
          disabled: disabled || false,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
        message.success('Meter updated successfully!');
      } else {
        await refs.meters.add({
          meterId,
          carNumber,
          carOwner,
          carType,
          disabled: disabled || false,
          createdAt: now,
          driver: null,
          deviceId: null,
        });

        await refs.users.add({
          carNumber,
          username: carOwner,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        });

        message.success('Meter added successfully!');
      }

      form.resetFields();
      fetchData();
      setIsEditing(false);
      setEditItemId(null);
    } catch (error) {
      console.error('Error adding/updating meter:', error.message);
      message.error('Failed to add/update meter. Please try again.');
    }
  };







  const handleDisableMeter = async (record) => {
    // console.log("record-------------", record);

    try {
      const newDisabledState = !record.disabled; // Toggle the disabled state

      // Only check the ride status if disabling the meter and if the driver has a uid
      if (newDisabledState && record?.driver?.uid) {
        const ridesSnapshot = await refs.ridesData
          .where("uuid", "==", record.driver.uid)  // Assuming 'uuid' refers to the driver's unique ID
          .orderBy("createdAt", "desc")
          .limit(1)
          .get();

        const lastRide = ridesSnapshot.docs[0]?.data(); // Get the most recent ride, if available

        // Check if the last ride exists and if its status is 'ended'
        if (lastRide && lastRide.status !== "ended") {
          // Block disabling if the last ride has not ended
          message.error('Cannot disable the meter as the last ride has not yet "ended".');
          return; // Stop execution here if the ride hasn't ended
        }
      }

      // Proceed to enable or disable the meter
      await refs.meters.doc(record.id).update({
        disabled: newDisabledState,
        deviceId: newDisabledState ? null : record.deviceId, // Set deviceId to null when disabling
        driver: newDisabledState ? null : record.driver,    // Clear driver info when disabling
      });

      // Provide success feedback
      message.success(`Meter ${newDisabledState ? 'disabled' : 'enabled'} successfully!`);
      fetchData();
    } catch (error) {
      console.error('Error updating meter disabled status:', error.message);
      message.error('Failed to update meter disabled status. Please try again.');
    }
  };



  const handleDelete = async (itemId) => {
    try {
      await refs.meters.doc(itemId).delete();
      message.success('Meter deleted successfully!');
      fetchData();
    } catch (error) {
      console.error('Error deleting meter:', error.message);
      message.error('Failed to delete meter. Please try again.');
    }
  };

  const handleActivateMeter = async (record) => {
    try {
      const activationDate = new Date();
      await refs.meters.doc(record.id).update({
        activationDate: activationDate
      });
      message.success('Meter activated successfully!');
      fetchData();
    } catch (error) {
      console.error('Error activating meter:', error.message);
      message.error('Failed to activate meter. Please try again.');
    }
  };

  const handleCarTypeChange = async (value, record) => {
    try {
      await refs.meters.doc(record.id).update({
        carType: value,
      });
      message.success('Car type updated successfully!');
      fetchData();
    } catch (error) {
      console.error('Error updating car type:', error.message);
      message.error('Failed to update car type. Please try again.');
    }
  };

  const handleMeterDetach = async (checked, record) => {
    try {
      const updateData = checked
        ? { deviceId: record.deviceId || 'attached' }
        : { deviceId: null };

      await refs.meters.doc(record.id).update({
        ...updateData,
        driver: checked ? record.driver : null,
      });
      message.success(`Meter ${checked ? 'attached' : 'detached'} successfully!`);
      fetchData();
    } catch (error) {
      console.error('Error updating meter attach status:', error.message);
      message.error('Failed to update meter attach status. Please try again.');
    }
  };

  const [form] = Form.useForm();

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        {dataIndex === 'carType' ? (
          <Select
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(value) => setSelectedKeys(value ? [value] : [])}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          >
            <Option value="sedan">Sedan</Option>
            <Option value="maxi">Maxi</Option>
          </Select>
        ) : (
          <Input
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
              marginBottom: 8,
              display: 'block',
            }}
          />
        )}
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      const cellValue = record[dataIndex];
      if (cellValue === undefined || cellValue === null) {
        return false;
      }
      return cellValue.toString().toLowerCase().includes((value || '').toLowerCase());
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <div>{text !== undefined && text !== null ? text.toString() : ''}</div>
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const downloadExcel = () => {
    const dataToExport = metersData.map(item => ({
      meterId: item.meterId,
      carNumber: item.carNumber,
      carOwner: item.carOwner,
      carType: item.carType,
      driverName: item.driver ? item.driver.name : 'N/A',
      createdAt: item.createdAt ? moment(item.createdAt.toDate()).format('YYYY-MM-DD HH:mm:ss') : 'N/A',
      activationDate: item.activationDate ? moment(item.activationDate.toDate()).format('YYYY-MM-DD HH:mm:ss') : 'Not Activated'
    }));
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Meters");
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    saveAs(data, 'meters_data.xlsx');
  };

  const columns = [
    {
      title: 'Meter Id',
      dataIndex: 'meterId',
      key: 'meterId',
      ...getColumnSearchProps('meterId'),
    },
    {
      title: 'Car Number',
      dataIndex: 'carNumber',
      key: 'carNumber',
      ...getColumnSearchProps('carNumber'),
    },
    {
      title: 'Car Owner',
      dataIndex: 'carOwner',
      key: 'carOwner',
      ...getColumnSearchProps('carOwner'),
    },
    {
      title: 'Car Type',
      dataIndex: 'carType',
      key: 'carType',
      ...getColumnSearchProps('carType'),
      render: (text, record) => (
        <Select
          value={text || 'sedan'}
          style={{ width: 120 }}
          onChange={(value) => handleCarTypeChange(value, record)}
        >
          <Option value="sedan">Sedan</Option>
          <Option value="maxi">Maxi</Option>
        </Select>
      ),
    },

    {
      title: 'Driver Name',
      dataIndex: ['driver', 'name'],
      key: 'driverName',
      render: (text, record) => (record.driver ? record.driver.name : 'N/A'),
    },
    {
      title: 'Driver Id',
      dataIndex: ['driver', 'id'],
      key: 'driverId',
      render: (text, record) => (record.driver ? record.driver.id : 'N/A'),
    },
    {
      title: 'Activation Date',
      dataIndex: 'activationDate',
      key: 'activationDate',
      render: (text, record) => record.activationDate ? moment(record.activationDate.toDate()).format('YYYY-MM-DD HH:mm:ss') : 'Not Activated',
    },
    {
      title: 'Disabled',
      dataIndex: 'disabled',
      key: 'disabled',
      render: (disabled, record) => (
        <Switch
          checked={!disabled}
          onChange={() => handleDisableMeter(record)}
          checkedChildren="Enabled"
          unCheckedChildren="Disabled"
        />
      ),
    },
    {
      title: 'Meter Status',
      dataIndex: 'deviceId',
      key: 'meterStatus',
      render: (deviceId, record) => (
        <Switch
          checked={deviceId !== null && deviceId !== undefined}
          onChange={(checked) => handleMeterDetach(checked, record)}
          checkedChildren="Attached"
          unCheckedChildren="Detached"
          disabled={record.disabled}
        />
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => (
        <span>
          <Button onClick={() => showModal(record)}>Edit</Button>
          <Button type="danger" onClick={() => handleDelete(record.id)} style={{ marginLeft: 8 }}>
            Delete
          </Button>
          {!record.activationDate && (
            <Button type="primary" onClick={() => handleActivateMeter(record)} style={{ marginLeft: 8 }}>
              Activate Meter
            </Button>
          )}
        </span>
      ),
    },
  ];

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div className="header">
        <Title style={{ color: '#fff' }} level={2}>
          Meters
        </Title>
      </div>
      <Space style={{ marginTop: 50, marginBottom: 16 }}>
        <Button type="primary" onClick={() => showModal()}>
          Add New
        </Button>
        <Button onClick={downloadExcel}>
          Download Excel
        </Button>
        <Select
          style={{ width: 150 }}
          placeholder="Filter by status"
          onChange={(value) => {
            console.log('Changing disabled filter to:', value);
            setDisabledFilter(value);
          }}
          value={disabledFilter}
        >
          <Option value="all">All Meters</Option>
          <Option value="enabled">Enabled Meters</Option>
          <Option value="disabled">Disabled Meters</Option>
        </Select>
        <Select
          style={{ width: 150 }}
          placeholder="Filter by attachment"
          onChange={(value) => {
            console.log('Changing attachment filter to:', value);
            setAttachmentFilter(value);
          }}
          value={attachmentFilter}
        >
          <Option value="all">All Meters</Option>
          <Option value="attached">Attached Meters</Option>
          <Option value="detached">Detached Meters</Option>
        </Select>
      </Space>

      <Modal
        title={isEditing ? "Edit Meter" : "Add New Meter"}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={() => form.submit()}>
            {isEditing ? "Update" : "Add"} Record
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical" onFinish={onFinishMeter}>
          <Form.Item label="Meter Id" name="meterId" rules={[{ required: true, message: 'Please enter Meter Id!' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Car Number" name="carNumber" rules={[{ required: true, message: 'Please enter Car Number!' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Car Owner" name="carOwner" rules={[{ required: true, message: 'Please enter Car Owner!' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Car Type" name="carType" rules={[{ required: true, message: 'Please select Car Type!' }]}>
            <Select>
              <Option value="sedan">Sedan</Option>
              <Option value="maxi">Maxi</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      <Table
        dataSource={filteredMetersData}
        columns={columns}
        loading={isLoading}
        rowKey="id"  // Add this line to provide a unique key for each row
      />
    </div>
  );
};

export default Meters;