import React, { useEffect, useRef, useState } from 'react';
import { Form, Input, Button, Typography, message, Table, Popconfirm, Switch, Modal, Badge, Avatar, Space, Collapse } from 'antd';
import 'firebase/auth';
import { refs } from '../firebase/firebase';
import { SearchOutlined, FilePdfOutlined } from '@ant-design/icons';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/firestore';
import { LeftOutlined, UploadOutlined } from '@ant-design/icons';
import firebase, { auth, storage } from 'firebase/app';
import moment from 'moment';


const { Title } = Typography;
const { Panel } = Collapse;

function RejectedUsers() {

    const [approvedUsers, setApprovedUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [form] = Form.useForm();
    const [selectedUserModal, setSelectedUserModal] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [updatingImageKey, setUpdatingImageKey] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadProgressMap, setUploadProgressMap] = useState({});
    const fileInputRef = useRef(null);

    const [searchTerm, setSearchTerm] = useState('');

    const filteredUsers = approvedUsers.filter(user =>
        user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.phone.toLowerCase().includes(searchTerm.toLowerCase())
    );


    useEffect(() => {
        const fetchPendingUsers = async () => {
            try {
                // const usersSnapshot = await firebase.firestore().collection('registrations').where('status', '==', 'approved').get();
                const usersSnapshot = await firebase.firestore()
                    .collection('registrations')
                    .where('status', '==', 'rejected')
                    .orderBy('createdAt', 'desc')
                    .limit(300)
                    .get();
                const usersData = usersSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
                setApprovedUsers(usersData);
                console.log(usersData, "=====================");
            } catch (error) {
                console.error('Error fetching pending users:', error);
            }
        };

        fetchPendingUsers();
    }, []);

    const fetchPendingUsers = async () => {
        try {
            // const usersSnapshot = await firebase.firestore().collection('registrations').where('status', '==', 'approved').get();
            const usersSnapshot = await firebase.firestore()
                .collection('registrations')
                .where('status', '==', 'rejected')
                .orderBy('createdAt', 'desc')
                .limit(300)
                .get();
            const usersData = usersSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
            setApprovedUsers(usersData);
            console.log(usersData, "=====================");
        } catch (error) {
            console.error('Error fetching pending users:', error);
        }
    };

    const handleRollBack = async (record) => {
        try {
            // Update the status to "pending"
            await refs.registrations.doc(record.id).update({
                status: 'pending',
            });
            // Show success message
            message.success(`User status rolled back to pending successfully!`);
            // Refresh the list of rejected users
            fetchPendingUsers();
        } catch (error) {
            console.error('Error rolling back user status:', error);
            message.error('Failed to roll back user status. Please try again later.');
        }
    };


    const onFinish = async (img) => {
        try {
            // const email = selectedUserModal.email; // Use the selected user's email
            const email = `${selectedUser.id}@driverappauspak.com`;
            // Create a new user in Firebase Authentication


            await refs.usersData.doc(selectedUser.userId).update({
                profilePicture: img,
            });


            // Show success message
            message.success(`User successfully updated!`);
            // Clear all fields after a successful submission
            form.resetFields();
        } catch (error) {
            console.error('Error during user registration:', error.message);
            message.error(error.message);
        }
    };


    const handleViewDetails = (user) => {
        setSelectedUser(null);
        setSelectedUser(user);
    };

    const showModal = (record) => {
        console.log(record);
        setSelectedUserModal(record)
        form.setFieldsValue({
            name: record.name,
            mail: record.email,
            Picture: record.profilePicture,
            driverId: '',
            pin: '',
        });
        setIsModalVisible(true);
    };

    const handleBack = () => {
        setSelectedUser(null);
    };


    const handleUpdateImageClick = (imageKey) => {
        setUpdatingImageKey(imageKey);
        setUploadProgressMap({ ...uploadProgressMap, [imageKey]: 0 }); // Initialize progress to 0
        fileInputRef.current.click();
    };

    const handleFileInputChange = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        try {
            const newProfilePictureUrl = await uploadImageAndGetURL(file);
            handleSubmit(newProfilePictureUrl);
            setUpdatingImageKey(null);
        } catch (error) {
            console.error('Error uploading image:', error);
            // Handle error
        } finally {
            // Reset progress once the upload is complete or fails
            setUploadProgress(0);
        }
    };

    const uploadImageAndGetURL = async (file, imageKey) => {
        try {
            const storageRef = storage().ref().child(`images/${file.name}`);

            const uploadTask = storageRef.put(file);

            // Listen for state changes and update the progress state for the specific image
            uploadTask.on('state_changed',
                (snapshot) => {
                    const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    setUploadProgressMap({ ...uploadProgressMap, [imageKey]: progress });
                }
            );

            // Return the download URL once the upload is complete
            const snapshot = await uploadTask;
            const downloadURL = await snapshot.ref.getDownloadURL();
            return downloadURL;
        } catch (error) {
            console.error('Error uploading image:', error);
            throw error;
        }
    };

    const handleSubmit = async (newProfilePictureUrl) => {
        try {
            await refs.registrations.doc(selectedUser.id).update({
                [updatingImageKey]: newProfilePictureUrl,
            });

            // Append a unique query parameter to the image URL to force browser to fetch the updated image
            const timestamp = Date.now();
            const updatedImageUrl = `${newProfilePictureUrl}?timestamp=${timestamp}`;

            // Update the selectedUser object with the new image URL
            setSelectedUser(prevState => ({
                ...prevState,
                [updatingImageKey]: updatedImageUrl
            }));

            onFinish(updatedImageUrl)

            // If the update operation is successful, show a success message to the user
            console.log('Success', 'Image updated successfully');
        } catch (error) {
            // If there's an error during the update operation, show an error message to the user
            console.error('Error updating image:', error);
            console.log('Error', 'Failed to update image. Please try again later.');
        }
    };


    const columns = [
        {
            title: 'Profile Picture',
            dataIndex: 'profilePicture',
            key: 'profilePicture',
            render: (profilePicture) => (
                <Avatar src={profilePicture} />
            ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt) => moment(createdAt.toDate()).format('MMMM D, YYYY')
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <Space size="middle">
                    {record.status === "rejected" ? (
                        <>
                            <Button type="link" onClick={() => handleViewDetails(record)}>
                                View Details
                            </Button>
                            <Button style={{ color: 'red' }} type="text">
                                Rejected
                            </Button>
                            <Popconfirm
                                title="Are you sure to roll back?"
                                onConfirm={() => handleRollBack(record)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button type="primary">Roll Back</Button>
                            </Popconfirm>
                        </>
                    ) : (
                        <Button type="primary">
                            Approved
                        </Button>
                    )}
                </Space>
            ),
        },
    ];


    const isPDF = (url) => {
        const urlWithoutParams = url.split('?')[0]; // Remove query parameters
        return urlWithoutParams.toLowerCase().endsWith('.pdf');
    };


    if (selectedUser) {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div className="header" style={{ marginBottom: 20 }}>
                    <Button icon={<LeftOutlined />} onClick={handleBack} style={{ marginBottom: 20 }}>Back</Button>
                    <Title style={{ color: '#fff' }} level={2}>Driver Detail</Title>
                </div>


                <div style={{ background: '#B4D4FF', padding: 20, borderRadius: 10, marginBottom: 20, width: '80%' }}>
                    <p style={{ fontSize: 18, fontWeight: 'bold', marginBottom: 10 }}>Personal Information</p>
                    <div style={{ marginBottom: 10 }}>
                        <p style={{ marginBottom: 5 }}>Name: {selectedUser.name}</p>
                    </div>
                    <div style={{ marginBottom: 10 }}>
                        <p style={{ marginBottom: 5 }}>Email: {selectedUser.email}</p>
                    </div>
                    <div style={{ marginBottom: 10 }}>
                        <p style={{ marginBottom: 5 }}>Phone: {selectedUser.phone}</p>
                    </div>
                    <div>
                        <p style={{ marginBottom: 5 }}>Address: {selectedUser.address}</p>
                    </div>
                </div>

                {/* "Send Mail" button */}
                {/* <Button type="primary" onClick={showModalMail} style={{ marginBottom: 20 }}>Send Mail</Button> */}

                {/* Modal */}
                {/* <Modal title="Send Mail" visible={isModalMail} onCancel={handleCancelMail} footer={[
                    <Button key="cancel" onClick={handleCancelMail}>Cancel</Button>,
                    <Button key="send" type="primary" onClick={handleSend}>Send</Button>
                ]}>
                    <Input.TextArea
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="Enter your message..."
                        autoSize={{ minRows: 3, maxRows: 6 }}
                    />
                </Modal> */}

                {/* Image rendering */}

                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                    {Object.entries(selectedUser).map(([key, value]) => {
                        if (typeof value === 'string' && value.startsWith('http')) {
                            return (
                                // Inside the map function where you render the images or PDF icons
                                <div key={key} style={{ margin: 10, textAlign: 'center', position: 'relative' }}>
                                    <p style={{ marginBottom: 5 }}>{key}</p>
                                    <div style={{ position: 'relative', width: 200, height: 200 }}>
                                        {isPDF(value) ? (
                                            <FilePdfOutlined
                                                onClick={() => window.open(value, '_blank')}
                                                style={{
                                                    fontSize: 100, // Set to 100% to inherit parent font size
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -80%)',
                                                    color: '#FF0000'
                                                }}
                                            />
                                        ) : (
                                            <img
                                                src={value}
                                                alt={key}
                                                style={{ width: '100%', height: '100%', objectFit: 'cover', cursor: 'pointer', borderRadius: 5, }}
                                                onClick={() => window.open(value, '_blank')}
                                            />
                                        )}
                                    </div>
                                    {/* Update Image button */}
                                    <Button
                                        icon={<UploadOutlined />}
                                        onClick={() => handleUpdateImageClick(key)}
                                        style={{ position: 'absolute', bottom: 10, left: '50%', transform: 'translateX(-50%)' }}
                                    >
                                        {isPDF(value) ? 'Update PDF / Image' : 'Update Image'}
                                    </Button>
                                </div>

                            );
                        }
                        return null;
                    })}
                </div>

                {selectedUser?.extras && <Collapse bordered={false} style={{ width: '80%' }}>
                    <Panel header="View More" key="1">
                        <div style={{ background: '#F0F0F0', padding: 20, borderRadius: 10 }}>
                            <p style={{ fontSize: 18, fontWeight: 'bold', marginBottom: 10 }}>Additional Information</p>
                            {Object.entries(selectedUser?.extras).map(([key, value]) => {
                                // Filter out obligation fields
                                if (!key.startsWith('obligation')) {
                                    return (
                                        <div key={key} style={{ marginBottom: 10 }}>
                                            <p style={{ marginBottom: 5 }}>
                                                <strong>{key}:</strong> {typeof value === 'object' && value.seconds ? new Date(value.seconds * 1000).toLocaleDateString() : value.toString()}
                                            </p>
                                        </div>
                                    );
                                }
                                return null;
                            })}
                        </div>
                    </Panel>
                </Collapse>}

                {/* Hidden file input */}
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileInputChange}
                />
            </div>
        );
    }




    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {/* Blue Header */}
            <div className="header">
                <Title style={{ color: '#fff' }} level={2}>
                    Rejected Drivers
                </Title>
            </div>

            <div style={{ paddingTop: '50px' }}>
                <Input.Search
                    placeholder="Search by name, email, or phone"
                    onSearch={value => setSearchTerm(value)}
                    style={{ width: 300, marginBottom: 10 }}
                />
            </div>

            <Table dataSource={filteredUsers} columns={columns} pagination={true} />

        </div>
    )
}

export default RejectedUsers