import React, { useEffect, useRef, useState } from 'react';
import { Form, Input, Button, Typography, message, Table, Popconfirm, Switch, Modal, Badge, Avatar, Space, Collapse, Row, Col, Select } from 'antd';
import 'firebase/auth';
import { refs } from '../firebase/firebase';
import { SearchOutlined, FilePdfOutlined, DownloadOutlined } from '@ant-design/icons';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/firestore';
import { LeftOutlined, UploadOutlined } from '@ant-design/icons';
import firebase, { auth, storage } from 'firebase/app';
import axios from 'axios';
import moment from 'moment';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Tag } from 'antd';


const { Title } = Typography;
const { Panel } = Collapse;

function Drivers() {

    const [approvedUsers, setApprovedUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [form] = Form.useForm();
    const [selectedUserModal, setSelectedUserModal] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [updatingImageKey, setUpdatingImageKey] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadProgressMap, setUploadProgressMap] = useState({});
    const fileInputRef = useRef(null);
    const [searchTerm, setSearchTerm] = useState('');

    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [editedUser, setEditedUser] = useState(null);

    const [licenseFilter, setLicenseFilter] = useState('all');




    // const filteredUsers = approvedUsers.filter(user =>
    //     user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //     user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //     user.phone.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //     (user.driverId && user.driverId.toLowerCase().includes(searchTerm.toLowerCase())) ||
    //     (user.extras && user.extras.driversLicenseNumber &&
    //         user.extras.driversLicenseNumber.toLowerCase().includes(searchTerm.toLowerCase()))
    // );

    const getLicenseStatus = (expiryDate) => {
        if (!expiryDate) return 'unknown';
        const today = moment();
        const expiry = moment(expiryDate.toDate());
        const daysUntilExpiry = expiry.diff(today, 'days');

        if (daysUntilExpiry < 0) return 'expired';
        if (daysUntilExpiry <= 30) return 'nearExpiry';
        return 'active';
    };
 

    const filterUsersByLicenseStatus = (users) => {
        if (licenseFilter === 'all') return users;
        return users.filter(user => {
            const status = getLicenseStatus(user.extras?.licenseExpiry);
            return licenseFilter === status;
        });
    };

    const filteredUsers = filterUsersByLicenseStatus(
        approvedUsers.filter(user =>
            user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.phone.toLowerCase().includes(searchTerm.toLowerCase()) ||
            (user.driverId && user.driverId.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (user.extras && user.extras.driversLicenseNumber &&
                user.extras.driversLicenseNumber.toLowerCase().includes(searchTerm.toLowerCase()))
        )
    );

  

    const downloadExcel = () => {
        const dataToExport = approvedUsers.map(user => ({
            Name: user.name + ' ' + user?.extras?.lastName,
           'License No': user.extras?.driversLicenseNumber ,// Adjust this field name if it's different in your data
            Address: user.address,
            Phone: user.phone,
            'Approved Date': user.createdAt ? moment(user.createdAt.toDate()).format('MMMM D, YYYY') : ''
        }));

        const workSheet = XLSX.utils.json_to_sheet(dataToExport);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, "Approved Users");

        // Generate buffer
        const excelBuffer = XLSX.write(workBook, { bookType: 'xlsx', type: 'array' });

        // Save to file
        const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
        saveAs(data, 'approved_users.xlsx');
    };



    useEffect(() => {
        const fetchPendingUsers = async () => {
            try {
                // const usersSnapshot = await firebase.firestore().collection('registrations').where('status', '==', 'approved').get();
                const usersSnapshot = await firebase.firestore()
                    .collection('registrations')
                    .where('status', '==', 'approved')
                    .orderBy('createdAt', 'desc')
                    .limit(300)
                    .get();
                const usersData = usersSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
                setApprovedUsers(usersData);
                console.log(usersData, "=====================");
            } catch (error) {
                console.error('Error fetching pending users:', error);
            }
        };

        fetchPendingUsers();
    }, []);


    const onFinish = async (img) => {
        try {
            // const email = selectedUserModal.email; // Use the selected user's email
            const email = `${selectedUser.id}@driverappauspak.com`;
            // Create a new user in Firebase Authentication


            await refs.usersData.doc(selectedUser.userId).update({
                profilePicture: img,
            });


            // Show success message
            message.success(`User successfully updated!`);
            // Clear all fields after a successful submission
            form.resetFields();
        } catch (error) {
            console.error('Error during user registration:', error.message);
            message.error(error.message);
        }
    };


    const handleViewDetails = (user) => {
        setSelectedUser(null);
        setSelectedUser(user);
    };

    const showModal = (record) => {
        console.log(record);
        setSelectedUserModal(record)
        form.setFieldsValue({
            name: record.name,
            mail: record.email,
            Picture: record.profilePicture,
            driverId: '',
            pin: '',
        });
        setIsModalVisible(true);
    };

    const handleBack = () => {
        setSelectedUser(null);
    };


    const handleUpdateImageClick = (imageKey) => {
        setUpdatingImageKey(imageKey);
        setUploadProgressMap({ ...uploadProgressMap, [imageKey]: 0 }); // Initialize progress to 0
        fileInputRef.current.click();
    };

    const handleFileInputChange = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        try {
            const newProfilePictureUrl = await uploadImageAndGetURL(file);
            handleSubmit(newProfilePictureUrl);
            setUpdatingImageKey(null);
        } catch (error) {
            console.error('Error uploading image:', error);
            // Handle error
        } finally {
            // Reset progress once the upload is complete or fails
            setUploadProgress(0);
        }
    };

    const uploadImageAndGetURL = async (file, imageKey) => {
        try {
            const storageRef = storage().ref().child(`images/${file.name}`);

            const uploadTask = storageRef.put(file);

            // Listen for state changes and update the progress state for the specific image
            uploadTask.on('state_changed',
                (snapshot) => {
                    const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    setUploadProgressMap({ ...uploadProgressMap, [imageKey]: progress });
                }
            );

            // Return the download URL once the upload is complete
            const snapshot = await uploadTask;
            const downloadURL = await snapshot.ref.getDownloadURL();
            return downloadURL;
        } catch (error) {
            console.error('Error uploading image:', error);
            throw error;
        }
    };

    const handleSubmit = async (newProfilePictureUrl) => {
        try {
            await refs.registrations.doc(selectedUser.id).update({
                [updatingImageKey]: newProfilePictureUrl,
            });

            // Append a unique query parameter to the image URL to force browser to fetch the updated image
            const timestamp = Date.now();
            const updatedImageUrl = `${newProfilePictureUrl}?timestamp=${timestamp}`;

            // Update the selectedUser object with the new image URL
            setSelectedUser(prevState => ({
                ...prevState,
                [updatingImageKey]: updatedImageUrl
            }));

            onFinish(updatedImageUrl)

            // If the update operation is successful, show a success message to the user
            console.log('Success', 'Image updated successfully');
        } catch (error) {
            // If there's an error during the update operation, show an error message to the user
            console.error('Error updating image:', error);
            console.log('Error', 'Failed to update image. Please try again later.');
        }
    };


    const [isModalMail, setIsModalMail] = useState(false);
    const [messagee, setMessage] = useState('');


    const showModalMail = () => {
        setIsModalMail(true);
    };


    const handleCancelMail = () => {
        setIsModalMail(false);
    };

    const handleSend = async () => {

        try {
            const apiKey = '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'; // Replace with your SendinBlue API key
            const senderEmail = 'Info@harbourcabs.com.au'; // Replace with your sender email address
            const senderName = 'Harbour Cabs'; // Replace with your sender name

            const response = await axios.post(
                'https://api.sendinblue.com/v3/smtp/email',
                {
                    sender: { name: senderName, email: senderEmail },
                    to: [{ email: selectedUser.email }],
                    subject: 'Provide the mentioned Data on this mail Info@harbourcabs.com.au',
                    htmlContent: `
              <!DOCTYPE html>
              <html lang="en">
              <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>Welcome Email</title>
                <style>
                  body {
                    font-family: Arial, sans-serif;
                    line-height: 1.6;
                    background-color: #f9f9f9;
                    padding: 20px;
                  }
                  .container {
                    max-width: 600px;
                    margin: 0 auto;
                    background-color: #fff;
                    padding: 20px;
                    border-radius: 10px;
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                  }
                  h1 {
                    color: #333;
                  }
                  p {
                    margin-bottom: 10px;
                  }
                  .message {
                    background-color: #f3f3f3;
                    padding: 15px;
                    border-radius: 5px;
                  }
                  .footer {
                    margin-top: 20px;
                    text-align: center;
                  }
                </style>
              </head>
              <body>
                <div class="container">
                  <h1>To Proceed to  Harbour Cabs App Please complete the mentioned Details !</h1>
                  <div class="message">
                    <p>Hi ${selectedUser.name},</p>
                    <p>${messagee}</p>
                    <p>If you have any questions or need assistance, feel free to contact us at Info@harbourcabs.com.au</p>
                  </div>
                  <div class="footer">
                    <p>Best regards,</p>
                    <p>${senderName}</p>
                    <p>${senderEmail}</p>
                  </div>
                </div>
              </body>
              </html>
                    `,
                },
                {
                    headers: {
                        'api-key': apiKey,
                        'Content-Type': 'application/json',
                    },
                }
            );
            setIsModalMail(false);
            console.log('Email sent successfully:', response.data);
            setMessage('')
            message.success('Email sent successfully')
        } catch (error) {
            console.error('Error sending email:', error.message);
        }

    };

    const columns = [
        {
            title: 'Profile Picture',
            dataIndex: 'profilePicture',
            key: 'profilePicture',
            render: (profilePicture) => (
                <Avatar src={profilePicture} />
            ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (name, record) => `${name} ${record.extras?.lastName || ''}`,
        },
        {
            title: 'Driver ID',
            dataIndex: 'driverId',
            key: 'driverId',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'License Number',
            dataIndex: 'LicenseNumber',
            key: 'LicenseNumber',
            render: (name, record) => `${record.extras?.driversLicenseNumber || ''}`,
        },
        {
            title: 'License Expiry',
            dataIndex: 'licenseExpiry',
            key: 'licenseExpiry',
            render: (text, record) => {
                const expiryDate = record.extras?.licenseExpiry;
                if (!expiryDate) return 'N/A';
                
                const status = getLicenseStatus(expiryDate);
                const color = status === 'expired' ? 'red' : status === 'nearExpiry' ? 'orange' : 'green';
                
                return (
                    <Tag color={color}>
                        {moment(expiryDate.toDate()).format('MMMM D, YYYY')}
                    </Tag>
                );
            },
        },
        {
            title: 'Approved Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt) => moment(createdAt.toDate()).format('MMMM D, YYYY')
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (

                record.status == "approved" ? (
                    <>
                        <Space size="middle">
                            <Button type="link" onClick={() => handleViewDetails(record)}>
                                View Details
                            </Button>
                            <Button type="text" >
                                Approved
                            </Button>
                        </Space>
                    </>
                ) : (
                    <>
                        <Space size="middle">

                            <Button type="primary">
                                Approved
                            </Button>

                        </Space>
                    </>
                )
            ),
        },
    ];



    const isPDF = (url) => {
        const urlWithoutParams = url.split('?')[0]; // Remove query parameters
        return urlWithoutParams.toLowerCase().endsWith('.pdf');
    };


    const InfoItem = ({ label, value }) => (
        <div style={{ marginBottom: 16 }}>
            <Typography.Text strong style={{ display: 'block', color: '#555', marginBottom: 4 }}>
                {label}:
            </Typography.Text>
            <Typography.Text style={{ color: '#333' }}>
                {value}
            </Typography.Text>
        </div>
    );


    const showEditModal = () => {
        setEditedUser({
            ...selectedUser,
            extras: selectedUser.extras || {}
        });
        setIsEditModalVisible(true);
    };

    const handleEditCancel = () => {
        setIsEditModalVisible(false);
        setEditedUser(null);
    };

    const handleEditSave = async () => {
        try {
            await refs.registrations.doc(selectedUser.id).update({
                extras: editedUser.extras
            });

            setSelectedUser({ ...selectedUser, extras: editedUser.extras });
            message.success('User information updated successfully');
            setIsEditModalVisible(false);
        } catch (error) {
            console.error('Error updating user information:', error);
            message.error('Failed to update user information');
        }
    };

    const handleEditInputChange = (field, value) => {
        setEditedUser(prev => ({
            ...prev,
            extras: {
                ...prev.extras,
                [field]: field.includes('Date') || field === 'dob' || field === 'licenseExpiry'
                    ? firebase.firestore.Timestamp.fromDate(new Date(value))
                    : value
            }
        }));
    };




    if (selectedUser) {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div className="header" style={{ marginBottom: 20 }}>
                    <Button icon={<LeftOutlined />} onClick={handleBack} style={{ marginBottom: 20 }}>Back</Button>
                    <Title style={{ color: '#fff' }} level={2}>Driver Detail</Title>
                </div>

                <div style={{ background: '#B4D4FF', padding: 20, borderRadius: 10, marginBottom: 20, width: '80%' }}>
                    <p style={{ fontSize: 18, fontWeight: 'bold', marginBottom: 10 }}>Personal Information</p>
                    <div style={{ marginBottom: 10 }}>
                        <p style={{ marginBottom: 5 }}>Name: {selectedUser?.name + " " + selectedUser?.extras?.lastName}</p>
                    </div>
                    <div style={{ marginBottom: 10 }}>
                        <p style={{ marginBottom: 5 }}>Email: {selectedUser.email}</p>
                    </div>
                    <div style={{ marginBottom: 10 }}>
                        <p style={{ marginBottom: 5 }}>Phone: {selectedUser.phone}</p>
                    </div>
                    <div>
                        <p style={{ marginBottom: 5 }}>Address: {selectedUser.address}</p>
                    </div>
                    <div>
                        <p style={{ marginBottom: 5 }}>Driver Id: {selectedUser.driverId}</p>
                    </div>
                    <div>
                        <p style={{ marginBottom: 5 }}>Driver PIN: {selectedUser.Pin}</p>
                    </div>
                </div>

                {/* "Send Mail" button */}
                <Button type="primary" onClick={showModalMail} style={{ marginBottom: 20 }}>Send Mail</Button>

                {/* Modal */}
                <Modal title="Send Mail" visible={isModalMail} onCancel={handleCancelMail} footer={[
                    <Button key="cancel" onClick={handleCancelMail}>Cancel</Button>,
                    <Button key="send" type="primary" onClick={handleSend}>Send</Button>
                ]}>
                    <Input.TextArea
                        value={messagee}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="Enter your message..."
                        autoSize={{ minRows: 3, maxRows: 6 }}
                    />
                </Modal>

                {/* Image rendering */}
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                    {Object.entries(selectedUser).map(([key, value]) => {
                        if (typeof value === 'string' && value.startsWith('http')) {
                            return (
                                // Inside the map function where you render the images or PDF icons
                                <div key={key} style={{ margin: 10, textAlign: 'center', position: 'relative' }}>
                                    <p style={{ marginBottom: 5 }}>{key}</p>
                                    <div style={{ position: 'relative', width: 200, height: 200 }}>
                                        {isPDF(value) ? (
                                            <FilePdfOutlined
                                                onClick={() => window.open(value, '_blank')}
                                                style={{
                                                    fontSize: 100, // Set to 100% to inherit parent font size
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -80%)',
                                                    color: '#FF0000'
                                                }}
                                            />
                                        ) : (
                                            <img
                                                src={value}
                                                alt={key}
                                                style={{ width: '100%', height: '100%', objectFit: 'cover', cursor: 'pointer', borderRadius: 5, }}
                                                onClick={() => window.open(value, '_blank')}
                                            />
                                        )}
                                    </div>
                                    {/* Update Image button */}
                                    <Button
                                        icon={<UploadOutlined />}
                                        onClick={() => handleUpdateImageClick(key)}
                                        style={{ position: 'absolute', bottom: 10, left: '50%', transform: 'translateX(-50%)' }}
                                    >
                                        {isPDF(value) ? 'Update PDF / Image' : 'Update Image'}
                                    </Button>
                                </div>

                            );
                        }
                        return null;
                    })}
                </div>


                <Collapse bordered={false} style={{ width: '80%' }}>
                    <Panel
                        header="Additional Information"
                        key="1"
                        extra={
                            <Button
                                icon={selectedUser.extras ? <EditOutlined /> : <PlusOutlined />}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    showEditModal();
                                }}
                            >
                                {selectedUser.extras ? 'Edit' : 'Add'}
                            </Button>
                        }
                    >
                        {selectedUser.extras ? (
                            <div style={{ background: '#F8F9FA', padding: 24, borderRadius: 8, boxShadow: '0 2px 8px rgba(0,0,0,0.1)' }}>
                                <Row gutter={[24, 16]}>
                                    <Col span={12}>
                                        <InfoItem label="Emergency Contact" value={selectedUser?.extras?.emergencyContactRelation || 'N/A'} />
                                        <InfoItem label="Full License (NSW)" value={selectedUser?.extras?.fullLicenseNSW ? 'Yes' : 'No'} />
                                        <InfoItem label="Driver Rules Acceptance" value={selectedUser?.extras?.driverRules ? 'Accepted' : 'Not Accepted'} />
                                        <InfoItem label="Emergency Contact Number" value={selectedUser?.extras?.emergencyContactNumber || 'N/A'} />
                                        {/* <InfoItem label="Preferred Name" value={selectedUser?.extras?.preferredName || 'N/A'} /> */}
                                        <InfoItem label="Code of Conduct Acceptance" value={selectedUser?.extras?.codeOfConduct ? 'Accepted' : 'Not Accepted'} />
                                        <InfoItem label="License Expiry Date" value={selectedUser?.extras?.licenseExpiry ? moment(selectedUser.extras.licenseExpiry.toDate()).format('MMMM D, YYYY') : 'N/A'} />
                                    </Col>
                                    <Col span={12}>

                                        <InfoItem label="Date of Birth" value={selectedUser?.extras?.dob ? moment(selectedUser.extras.dob.toDate()).format('MMMM D, YYYY') : 'N/A'} />
                                        <InfoItem label="Last Name" value={selectedUser?.extras?.lastName || 'N/A'} />
                                        <InfoItem label="ABN Number" value={selectedUser?.extras?.abnNumber || 'N/A'} />
                                        <InfoItem label="Driver's License Number" value={selectedUser?.extras?.driversLicenseNumber || 'N/A'} />
                                        <InfoItem label="Emergency Contact Name" value={selectedUser?.extras?.emergencyContactName || 'N/A'} />
                                        <InfoItem label="Assistance Animal Understanding" value={selectedUser?.extras?.understandAssistanceAnimal ? 'Yes' : 'No'} />
                                    </Col>
                                </Row>
                            </div>
                        ) : (
                            <Typography.Text>No additional information available.</Typography.Text>
                        )}
                    </Panel>
                </Collapse>


                {/* Hidden file input */}
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileInputChange}
                />

                <Modal
                    title={selectedUser.extras ? "Edit Additional Information" : "Add Additional Information"}
                    visible={isEditModalVisible}
                    onOk={handleEditSave}
                    onCancel={handleEditCancel}
                    width={800} // Increased width for better layout
                >
                    <Form layout="vertical">
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Last Name">
                                    <Input
                                        value={editedUser?.extras?.lastName}
                                        onChange={(e) => handleEditInputChange('lastName', e.target.value)}
                                    />
                                </Form.Item>
                                <Form.Item label="Emergency Contact Name">
                                    <Input
                                        value={editedUser?.extras?.emergencyContactName}
                                        onChange={(e) => handleEditInputChange('emergencyContactName', e.target.value)}
                                    />
                                </Form.Item>
                                <Form.Item label="Emergency Contact Relation">
                                    <Input
                                        value={editedUser?.extras?.emergencyContactRelation}
                                        onChange={(e) => handleEditInputChange('emergencyContactRelation', e.target.value)}
                                    />
                                </Form.Item>
                                <Form.Item label="Emergency Contact Number">
                                    <Input
                                        value={editedUser?.extras?.emergencyContactNumber}
                                        onChange={(e) => handleEditInputChange('emergencyContactNumber', e.target.value)}
                                    />
                                </Form.Item>
                                <Form.Item label="ABN Number">
                                    <Input
                                        value={editedUser?.extras?.abnNumber}
                                        onChange={(e) => handleEditInputChange('abnNumber', e.target.value)}
                                    />
                                </Form.Item>
                                <Form.Item label="Driver's License Number">
                                    <Input
                                        value={editedUser?.extras?.driversLicenseNumber}
                                        onChange={(e) => handleEditInputChange('driversLicenseNumber', e.target.value)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Date of Birth">
                                    <Input
                                        type="date"
                                        value={editedUser?.extras?.dob ? moment(editedUser.extras.dob.toDate()).format('YYYY-MM-DD') : ''}
                                        onChange={(e) => handleEditInputChange('dob', e.target.value)}
                                    />
                                </Form.Item>
                                <Form.Item label="License Expiry Date">
                                    <Input
                                        type="date"
                                        value={editedUser?.extras?.licenseExpiry ? moment(editedUser.extras.licenseExpiry.toDate()).format('YYYY-MM-DD') : ''}
                                        onChange={(e) => handleEditInputChange('licenseExpiry', e.target.value)}
                                    />
                                </Form.Item>
                                <Form.Item label="Full License (NSW)">
                                    <Switch
                                        checked={editedUser?.extras?.fullLicenseNSW}
                                        onChange={(checked) => handleEditInputChange('fullLicenseNSW', checked)}
                                    />
                                </Form.Item>
                                <Form.Item label="Driver Rules Acceptance">
                                    <Switch
                                        checked={editedUser?.extras?.driverRules}
                                        onChange={(checked) => handleEditInputChange('driverRules', checked)}
                                    />
                                </Form.Item>
                                <Form.Item label="Code of Conduct Acceptance">
                                    <Switch
                                        checked={editedUser?.extras?.codeOfConduct}
                                        onChange={(checked) => handleEditInputChange('codeOfConduct', checked)}
                                    />
                                </Form.Item>
                                <Form.Item label="Assistance Animal Understanding">
                                    <Switch
                                        checked={editedUser?.extras?.understandAssistanceAnimal}
                                        onChange={(checked) => handleEditInputChange('understandAssistanceAnimal', checked)}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>

            </div>
        );
    }




    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {/* Blue Header */}
            <div className="header">
                <Title style={{ color: '#fff' }} level={2}>
                    Approved Drivers Profile
                </Title>
            </div>
            <div style={{ paddingTop: '50px', display: 'flex', justifyContent: 'space-between', width: '100%', maxWidth: '800px' }}>
                <Input.Search
                    placeholder="Search by name, email, or phone"
                    onSearch={value => setSearchTerm(value)}
                    style={{ width: 300, marginBottom: 10 }}
                />
                 <Select
                    defaultValue="all"
                    style={{ width: 200, marginBottom: 10 }}
                    onChange={(value) => setLicenseFilter(value)}
                >
                    <Select.Option value="all">All Licenses</Select.Option>
                    <Select.Option value="active">Active</Select.Option>
                    <Select.Option value="nearExpiry">Near Expiry</Select.Option>
                    <Select.Option value="expired">Expired</Select.Option>
                </Select>
                <Button
                    type="primary"
                    onClick={downloadExcel}
                    icon={<DownloadOutlined />}
                >
                    Download Excel
                </Button>
            </div>

            <Table dataSource={filteredUsers} columns={columns} pagination={true} />



        </div>
    )
}

export default Drivers