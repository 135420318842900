import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, Table, Typography, Space, Dropdown, Menu, Avatar, Alert, Form, Input, Collapse, Col, Row } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import firebase, { auth, storage } from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import { LeftOutlined, UploadOutlined, FilePdfOutlined } from '@ant-design/icons';
import { refs } from '../firebase/firebase';
import { Progress } from 'antd';
import Item from 'antd/es/list/Item';
import axios from 'axios';
import { message } from 'antd';
import moment from 'moment';


const { Title } = Typography;
const { Panel } = Collapse;


const Registrations = () => {
  const [pendingUsers, setPendingUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUserModal, setSelectedUserModal] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const [updatingImageKey, setUpdatingImageKey] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadProgressMap, setUploadProgressMap] = useState({});
  const [approvedd, setapproved] = useState(false)
  const [form] = Form.useForm();
  const [name, setName] = useState('');
  const [driverId, setDriverId] = useState('');
  const [pin, setPin] = useState('');


  const fileInputRef = useRef(null);

  const handleUpdateImageClick = (imageKey) => {
    setUpdatingImageKey(imageKey);
    setUploadProgressMap({ ...uploadProgressMap, [imageKey]: 0 }); // Initialize progress to 0
    fileInputRef.current.click();
  };

  const uploadImageAndGetURL = async (file, imageKey) => {
    try {
      const storageRef = storage().ref().child(`images/${file.name}`);

      const uploadTask = storageRef.put(file);

      // Listen for state changes and update the progress state for the specific image
      uploadTask.on('state_changed',
        (snapshot) => {
          const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          setUploadProgressMap({ ...uploadProgressMap, [imageKey]: progress });
        }
      );

      // Return the download URL once the upload is complete
      const snapshot = await uploadTask;
      const downloadURL = await snapshot.ref.getDownloadURL();
      return downloadURL;
    } catch (error) {
      console.error('Error uploading image:', error);
      throw error;
    }
  };



  const handleFileInputChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    try {
      const newProfilePictureUrl = await uploadImageAndGetURL(file);
      handleSubmit(newProfilePictureUrl);
      setUpdatingImageKey(null);
    } catch (error) {
      console.error('Error uploading image:', error);
      // Handle error
    } finally {
      // Reset progress once the upload is complete or fails
      setUploadProgress(0);
    }
  };


  // modallll  

  const showModal = (record) => {
    console.log(record);
    setSelectedUserModal(record)
    form.setFieldsValue({
      name: record.name,
      mail: record.email,
      Picture: record.profilePicture,
      driverId: '',
      pin: '',
    });
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };



  // modal cancel 


  const handleSubmit = async (newProfilePictureUrl) => {
    try {
      await refs.registrations.doc(selectedUser.id).update({
        [updatingImageKey]: newProfilePictureUrl,
      });

      // Append a unique query parameter to the image URL to force browser to fetch the updated image
      const timestamp = Date.now();
      const updatedImageUrl = `${newProfilePictureUrl}?timestamp=${timestamp}`;

      // Update the selectedUser object with the new image URL
      setSelectedUser(prevState => ({
        ...prevState,
        [updatingImageKey]: updatedImageUrl
      }));

      // If the update operation is successful, show a success message to the user
      console.log('Success', 'Image updated successfully');
      message.success('updated successfully')
    } catch (error) {
      // If there's an error during the update operation, show an error message to the user
      console.error('Error updating image:', error);
      console.log('Error', 'Failed to update image. Please try again later.');
    }
  };


  useEffect(() => {
    const fetchPendingUsers = async () => {
      try {
        const usersSnapshot = await firebase.firestore()
          .collection('registrations')
          .where('status', '==', 'pending')
          .orderBy('createdAt', 'desc')
          .limit(300)
          .get();
        const usersData = usersSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        setPendingUsers(usersData);
      } catch (error) {
        console.error('Error fetching pending users:', error);
      }
    };

    fetchPendingUsers();
  }, [approvedd]);

  const handleViewDetails = (user) => {
    setSelectedUser(null);
    setSelectedUser(user);
  };

  const handleBack = () => {
    setSelectedUser(null);
  };

  const handleApprove = () => {
    setIsModalVisible(true);
  };

  const handleReject = () => {
    // Implement logic to reject registration
    setVisible(false);
  };

  const handleMenuClick = (e) => {
    if (e.key === 'approve') {
      handleApprove();
    } else if (e.key === 'reject') {
      handleReject();
    }
  };



  const columns = [
    {
      title: 'Profile Picture',
      dataIndex: 'profilePicture',
      key: 'profilePicture',
      render: (profilePicture) => (
        <Avatar src={profilePicture} />
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => `${name} ${record.extras?.lastName || ''}`,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => moment(createdAt.toDate()).format('MMMM D, YYYY')
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (

        record.status == "pending" ? (
          <>
            <Space size="middle">
              <Button type="link" onClick={() => handleViewDetails(record)}>
                View Details
              </Button>
              <Button type="primary" onClick={() => showModal(record)}>
                Approve
              </Button>
              <Button danger onClick={() => handleRejectedd(record)}>
                Reject
              </Button>
            </Space>
          </>
        ) : (
          <>
            <Space size="middle">

              <Button type="primary">
                Approved
              </Button>

            </Space>
          </>
        )
      ),
    },
  ];

  const [isModalMail, setIsModalMail] = useState(false);
  const [messagee, setMessage] = useState('');

  const showModalMail = () => {
    setIsModalMail(true);
  };

  const handleSend = async () => {


    try {
      const apiKey = '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'; // Replace with your SendinBlue API key
      const senderEmail = 'Info@harbourcabs.com.au'; // Replace with your sender email address
      const senderName = 'Harbour Cabs'; // Replace with your sender name

      const response = await axios.post(
        'https://api.sendinblue.com/v3/smtp/email',
        {
          sender: { name: senderName, email: senderEmail },
          to: [{ email: selectedUser.email }],
          subject: 'Provide the mentioned Data on this mail Info@harbourcabs.com.au',
          htmlContent: `
          <!DOCTYPE html>
          <html lang="en">
          <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Welcome Email</title>
            <style>
              body {
                font-family: Arial, sans-serif;
                line-height: 1.6;
                background-color: #f9f9f9;
                padding: 20px;
              }
              .container {
                max-width: 600px;
                margin: 0 auto;
                background-color: #fff;
                padding: 20px;
                border-radius: 10px;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
              }
              h1 {
                color: #333;
              }
              p {
                margin-bottom: 10px;
              }
              .message {
                background-color: #f3f3f3;
                padding: 15px;
                border-radius: 5px;
              }
              .footer {
                margin-top: 20px;
                text-align: center;
              }
            </style>
          </head>
          <body>
            <div class="container">
              <h1>To Proceed to  Harbour Cabs App Please complete the mentioned Details!</h1>
              <div class="message">
                <p>Hi ${selectedUser.name},</p>
                <p>${messagee}</p>
                <p>If you have any questions or need assistance, feel free to contact us at Info@harbourcabs.com.au</p>
              </div>
              <div class="footer">
                <p>Best regards,</p>
                <p>${senderName}</p>
                <p>${senderEmail}</p>
              </div>
            </div>
          </body>
          </html>
                `,
        },
        {
          headers: {
            'api-key': apiKey,
            'Content-Type': 'application/json',
          },
        }
      );
      setIsModalMail(false);
      console.log('Email sent successfully:', response.data);
      setMessage('')
      message.success('Email sent successfully')
    } catch (error) {
      console.error('Error sending email:', error.message);
    }

  };

  const handleCancelMail = () => {
    setIsModalMail(false);
  };






  const sendEmail = async (name, email, driverId, pin) => {
    try {
      const apiKey = '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'; // Replace with your SendinBlue API key
      const senderEmail = 'Info@harbourcabs.com.au'; // Replace with your sender email address
      const senderName = 'Harbour Cabs'; // Replace with your sender name
      console.log(email, "email tooooooooo==============");
      const response = await axios.post(
        'https://api.sendinblue.com/v3/smtp/email',
        {
          sender: { name: senderName, email: senderEmail },
          to: [{ email: email }],
          subject: 'Welcome to Harbour Cabs !',
          htmlContent: `
          <!DOCTYPE html>
          <html lang="en">
          <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Welcome Email</title>
            <style>
              body {
                font-family: Arial, sans-serif;
                line-height: 1.6;
                background-color: #f9f9f9;
                padding: 20px;
              }
              .container {
                max-width: 600px;
                margin: 0 auto;
                background-color: #fff;
                padding: 20px;
                border-radius: 10px;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
              }
              h1 {
                color: #333;
              }
              p {
                margin-bottom: 10px;
              }
              .message {
                background-color: #f3f3f3;
                padding: 15px;
                border-radius: 5px;
              }
              .footer {
                margin-top: 20px;
                text-align: center;
              }
            </style>
          </head>
          <body>
            <div class="container">
              <h1>Welcome to  Harbour Cabs !</h1>
              <div class="message">
                <p>Hi ${name},</p>
                <p>We are excited to welcome you to Harbour Cabs !</p>
                <p>Your driver ID is: ${driverId}</p>
                <p>Your PIN is: ${pin}</p>
                <p>If you have any questions or need assistance, feel free to contact us.</p>
              </div>
              <div class="footer">
                <p>Best regards,</p>
                <p>${senderName}</p>
                <p>${senderEmail}</p>
              </div>
            </div>
          </body>
          </html>
          
                `,
        },
        {
          headers: {
            'api-key': apiKey,
            'Content-Type': 'application/json',
          },
        }
      );
      message.success('Email sent successfully:')
      console.log('Email sent successfully:', response.data);
    } catch (error) {
      message.success(error.message)
      console.error('Error sending email:', error.message);
    }
  };

  const onFinish = async (values) => {
    try {
      const { name, driverId, pin } = values;
      // const email = selectedUserModal.email; // Use the selected user's email
      const email = `${driverId}@driverappauspak.com`;
      // Create a new user in Firebase Authentication
      const userCredential = await auth().createUserWithEmailAndPassword(email, pin);
      const userId = userCredential.user.uid;

      // Add user data to the Firestore collection
      await refs.usersData.doc(userId).set({
        regId: selectedUserModal.id,
        name: name,
        email: email,
        profilePicture: selectedUserModal.profilePicture,
        driverId: driverId,
        showPackage: false,
        userId: userId,
        disabled: false,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
      await refs.registrations.doc(selectedUserModal.id).update({
        userId: userId,
        driverId: driverId,
        Pin: pin

      })
      // Send email containing PIN and Driver ID to the selected user
      await sendEmail(name, selectedUserModal.email, driverId, pin);

      // Show success message
      message.success(`User ${name} successfully registered!`);
      // Clear all fields after a successful submission
      form.resetFields();
      setIsModalVisible(false)
      handleApplrovedd()
    } catch (error) {
      console.error('Error during user registration:', error.message);
      message.error(error.message);
    }
  };




  const handleApplrovedd = async () => {

    try {
      await refs.registrations.doc(selectedUserModal.id).update({
        status: 'approved',
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        driversLicenseNumber: selectedUserModal?.extras?.driversLicenseNumber,
        fullName: selectedUserModal?.name + ' ' + selectedUserModal?.extras?.lastName
      });

      setapproved(prv => !prv)
      // If the update operation is successful, show a success message to the user
      message.success('Approved successfully')
      console.log('Success', 'Approved successfully');
    } catch (error) {
      // If there's an error during the update operation, show an error message to the user
      console.error('Error Approved image:', error);
      console.log('Error', 'Failed to Approved . Please try again later.');
    }
  };
  const handleRejectedd = async (record) => {
    try {
      await refs.registrations.doc(record.id).update({
        status: 'rejected',
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      });

      setapproved(prv => !prv)
      // If the update operation is successful, show a success message to the user
      message.success('Rejected successfully')
      console.log('Success', 'Rejected successfully');
    } catch (error) {
      // If there's an error during the update operation, show an error message to the user
      console.error('Error Approved image:', error);
      console.log('Error', 'Failed to Rejected . Please try again later.');
    }
  };

  const isPDF = (url) => {
    const urlWithoutParams = url.split('?')[0]; // Remove query parameters
    return urlWithoutParams.toLowerCase().endsWith('.pdf');
  };

  const InfoItem = ({ label, value }) => (
    <div style={{ marginBottom: 16 }}>
      <Typography.Text strong style={{ display: 'block', color: '#555', marginBottom: 4 }}>
        {label}:
      </Typography.Text>
      <Typography.Text style={{ color: '#333' }}>
        {value}
      </Typography.Text>
    </div>
  );

  if (selectedUser) {
    console.log('selectedUser:---------------------- ', selectedUser);
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div className="header" style={{ marginBottom: 20 }}>
          <Button icon={<LeftOutlined />} onClick={handleBack} style={{ marginBottom: 20 }}>Back</Button>
          <Title style={{ color: '#fff' }} level={2}>Drivers Details</Title>
        </div>

        <div style={{ background: '#B4D4FF', padding: 20, borderRadius: 10, marginBottom: 20, width: '80%' }}>
          <p style={{ fontSize: 18, fontWeight: 'bold', marginBottom: 10 }}>Personal Information</p>
          <div style={{ marginBottom: 10 }}>
            <p style={{ marginBottom: 5 }}>Name: {selectedUser?.name + " " + selectedUser?.extras?.lastName}</p>
          </div>
          <div style={{ marginBottom: 10 }}>
            <p style={{ marginBottom: 5 }}>Email: {selectedUser.email}</p>
          </div>
          <div style={{ marginBottom: 10 }}>
            <p style={{ marginBottom: 5 }}>Phone: {selectedUser.phone}</p>
          </div>
          <div>
            <p style={{ marginBottom: 5 }}>Address: {selectedUser.address}</p>
          </div>
        </div>

        {/* "Send Mail" button */}
        <Button type="primary" onClick={showModalMail} style={{ marginBottom: 20 }}>Send Mail</Button>

        {/* Modal */}
        <Modal title="Send Mail" visible={isModalMail} onCancel={handleCancelMail} footer={[
          <Button key="cancel" onClick={handleCancelMail}>Cancel</Button>,
          <Button key="send" type="primary" onClick={handleSend}>Send</Button>
        ]}>
          <Input.TextArea
            value={messagee}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Enter your message..."
            autoSize={{ minRows: 3, maxRows: 6 }}
          />
        </Modal>

        {/* Image rendering */}
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
          {Object.entries(selectedUser).map(([key, value]) => {
            if (typeof value === 'string' && value.startsWith('http')) {
              return (
                <div key={key} style={{ margin: 10, textAlign: 'center', position: 'relative' }}>
                  <p style={{ marginBottom: 5 }}>{key}</p>
                  <div style={{ position: 'relative', width: 200, height: 200 }}>
                    {isPDF(value) ? (
                      <FilePdfOutlined
                        onClick={() => window.open(value, '_blank')}
                        style={{
                          fontSize: 100,
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -80%)',
                          color: '#FF0000'
                        }}
                      />
                    ) : (
                      <img
                        src={value}
                        alt={key}
                        style={{ width: '100%', height: '100%', objectFit: 'cover', cursor: 'pointer', borderRadius: 5 }}
                        onClick={() => window.open(value, '_blank')}
                      />
                    )}
                  </div>
                  {/* Update Image button */}
                  <Button
                    icon={<UploadOutlined />}
                    onClick={() => handleUpdateImageClick(key)}
                    style={{ position: 'absolute', bottom: 10, left: '50%', transform: 'translateX(-50%)' }}
                  >
                    {isPDF(value) ? 'Update PDF / Image' : 'Update Image'}
                  </Button>
                </div>
              );
            }
            return null;
          })}
        </div>

        {/* View More Extras */}
        {/* View More Extras */}
        {selectedUser?.extras &&
          <Collapse bordered={false} style={{ width: '80%' }}>
            <Panel header="Additional Information" key="1">
              <div style={{ background: '#F8F9FA', padding: 24, borderRadius: 8, boxShadow: '0 2px 8px rgba(0,0,0,0.1)' }}>
                <Row gutter={[24, 16]}>
                  <Col span={12}>
                    <InfoItem label="Emergency Contact" value={selectedUser?.extras?.emergencyContactRelation || 'N/A'} />
                    <InfoItem label="Full License (NSW)" value={selectedUser?.extras?.fullLicenseNSW ? 'Yes' : 'No'} />
                    <InfoItem label="Driver Rules Acceptance" value={selectedUser?.extras?.driverRules ? 'Accepted' : 'Not Accepted'} />
                    <InfoItem label="Emergency Contact Number" value={selectedUser?.extras?.emergencyContactNumber || 'N/A'} />
                    {/* <InfoItem label="Preferred Name" value={selectedUser?.extras?.preferredName || 'N/A'} /> */}
                    <InfoItem label="Code of Conduct Acceptance" value={selectedUser?.extras?.codeOfConduct ? 'Accepted' : 'Not Accepted'} />
                    <InfoItem label="License Expiry Date" value={selectedUser?.extras?.licenseExpiry ? moment(selectedUser.extras.licenseExpiry.toDate()).format('MMMM D, YYYY') : 'N/A'} />
                  </Col>
                  <Col span={12}>
                   
                    <InfoItem label="Date of Birth" value={selectedUser?.extras?.dob ? moment(selectedUser.extras.dob.toDate()).format('MMMM D, YYYY') : 'N/A'} />
                    <InfoItem label="Last Name" value={selectedUser?.extras?.lastName || 'N/A'} />
                    <InfoItem label="ABN Number" value={selectedUser?.extras?.abnNumber || 'N/A'} />
                    <InfoItem label="Driver's License Number" value={selectedUser?.extras?.driversLicenseNumber || 'N/A'} />
                    <InfoItem label="Emergency Contact Name" value={selectedUser?.extras?.emergencyContactName || 'N/A'} />
                    <InfoItem label="Assistance Animal Understanding" value={selectedUser?.extras?.understandAssistanceAnimal ? 'Yes' : 'No'} />
                  </Col>
                </Row>
              </div>
            </Panel>
          </Collapse>
        }


        {/* Hidden file input */}
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileInputChange}
        />
      </div>
    );
  }




  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div className="header">
        <Title style={{ color: '#fff' }} level={2}>Drivers Registrations</Title>
      </div>
      <Table dataSource={pendingUsers} columns={columns} pagination={false} />
      <Modal
        title="Approve Registration"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="approve" type="primary" onClick={() => form.submit()}>
            Approve User And Send Mail
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item
            label="Profile Picture"
            name="profilePicture"
            rules={[{ required: false, message: 'Please select a Profile Picture!' }]}
          >
            <Avatar src={selectedUserModal ? selectedUserModal.profilePicture : null} />
          </Form.Item>
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: 'Please enter the Name!' }]}
          >
            <Input disabled={true} />
          </Form.Item>
          <Form.Item
            label="Email"
            name="mail"
            rules={[{ required: true, message: 'Please enter the Email!' }]}
          >
            <Input disabled={true} />
          </Form.Item>
          <Form.Item
            label="Driver ID"
            name="driverId"
            rules={[
              { required: true, message: 'Please enter the Driver ID!' },
              { pattern: /^[0-9]+$/, message: 'Please enter a valid Driver ID (only numbers allowed)' }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="PIN"
            name="pin"
            rules={[
              { required: true, message: 'Please enter the PIN!' },
              { pattern: /^[0-9]+$/, message: 'Please enter a valid PIN (only numbers allowed)' }
            ]}
          >
            <Input.Password />
          </Form.Item>

        </Form>
      </Modal>

    </div>
  );
};

export default Registrations;
