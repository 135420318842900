import React, { useEffect, useState } from 'react';
import { Typography, Tabs, Form, Input, Button, message, Space, Popconfirm, Table, Modal } from 'antd';
import firebase, { firestore } from 'firebase';
import { auth, refs } from '../firebase/firebase';
import { v4 as uuidv4 } from 'uuid';
import MapComponent from '../components/MapComponent';
import axios from 'axios';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Api_Key } from '../components/Constants';


const { Title } = Typography;
const { TabPane } = Tabs;

const Settings = () => {


  const [form] = Form.useForm();

  const [tollData, setTollData] = useState([]);

  const [tollDataAuto, setTollDataAuto] = useState([]);

  const [packagesData, setPackagesData] = useState([]);

  const [startDate, setStartDate] = useState(); // State for start date
  const [endDate, setEndDate] = useState(); // State for end date
  const [loading, setLoading] = useState(false); // Add loading state

  const [addTollForm] = Form.useForm(); // Create a separate form for Add Toll
  const [editTollForm] = Form.useForm(); // Create a separate form for Edit Toll

  const [addTollFormAuto] = Form.useForm(); // Create a separate form for Add Toll
  const [editTollFormAuto] = Form.useForm(); // Create a separate form for Edit Toll

  const [addPackageForm] = Form.useForm(); // Create a separate form for Add Toll
  const [editPackageForm] = Form.useForm(); // Create a separate form for Edit Toll

  const [editTollModalVisible, setEditTollModalVisible] = useState(false);

  const [editTollAutoModalVisible, setEditTollAutoModalVisible] = useState(false);

  const [editPackageModalVisible, setEditPackageModalVisible] = useState(false);

  const [editingToll, setEditingToll] = useState(null);

  const [editingTollAuto, setEditingTollAuto] = useState(null);

  const [editingPackage, setEditingPackage] = useState(null);

  const [circleCoordinates, setCircleCoordinates] = useState([]);
  const [circleCoordinates2, setCircleCoordinates2] = useState([]);


  const [startPoint, setStartPoint] = useState(null);
  const [endPoint, setEndPoint] = useState(null);


  const [TollNameShow, setTollNameShow] = useState([''])

  const fetchPlaceName = async (lat, lng) => {
    try {
      const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${Api_Key}`);
      if (response.data.results.length > 0) {
        const placeName = response.data.results[0].formatted_address; // Extract place name from API response
        return placeName;
      } else {
        return 'Unknown Place'; // Return a default value if no result is found
      }
    } catch (error) {
      console.error('Error fetching place name:', error.message);
      return 'Error fetching place name';
    }
  };


  const addTollTypeToCoordinates = (coordinatesArray) => {
    // Map over each coordinate and add tollType
    return coordinatesArray.map(coordinate => ({
      ...coordinate,
      tollType: 'preTollCheck'
    }));
  };






  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);



  useEffect(() => {
    fetchDataAutoTolls()
    fetchData();
    fetchDataPackages();
    console.log('Editing useefect:', editingToll);
  }, [editingToll]); // Log when editingToll changes

  useEffect(() => {
    if (editingToll) {
      editTollForm.setFieldsValue({
        tollName: editingToll.tollName,
        tollPrice: editingToll.tollPrice,

      });
    }
  }, [editingToll, editTollForm]);

  useEffect(() => {
    if (editingTollAuto) {
      console.log('editingTollAuto: ', editingTollAuto);
      editTollFormAuto.setFieldsValue({
        tollName: editingTollAuto.tollName,
        tollPrice: editingTollAuto.tollPrice,
        tollWidth: editingTollAuto.width,
      });
    }
  }, [editingTollAuto, editTollFormAuto]);

  useEffect(() => {
    if (editingPackage) {

    
      editPackageForm.setFieldsValue({
        packageName: editingPackage.packageName,
        start: editingPackage.start,
        pricePerkm: editingPackage.pricePerkm,
        waitingTime: editingPackage.waitingTime,
        slowSpeedLimit:editingPackage.slowSpeedLimit,
        slowSpeedWindow: editingPackage.slowSpeedWindow
      });
    }
  }, [editingPackage, editPackageForm]);



  const fetchDataAutoTolls = async () => {
    try {
      setLoading(true);

      const querySnapshot = await refs.tollsAuto.orderBy('orderIndex', 'asc').limit(300).get();

      const newArray = querySnapshot.docs.map((documentSnapshot) => ({
        id: documentSnapshot.id,
        ...documentSnapshot.data(),

      }));

      setTollDataAuto(newArray);
      console.log(newArray, "newArray");
    } catch (err) {
      console.error('Error fetching toll data:', err);
      message.error('Failed to fetch toll data. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);

      const querySnapshot = await refs.tolls.orderBy('orderIndex', 'asc').limit(300).get();

      const newArray = querySnapshot.docs.map((documentSnapshot) => ({
        id: documentSnapshot.id,
        ...documentSnapshot.data(),

      }));

      setTollData(newArray);
      console.log(newArray, "newArray");
    } catch (err) {
      console.error('Error fetching toll data:', err);
      message.error('Failed to fetch toll data. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  const fetchDataPackages = async () => {
    try {
      setLoading(true);

      const querySnapshot = await refs.packages.limit(300).get();

      const newArray = querySnapshot.docs.map((documentSnapshot) => ({
        id: documentSnapshot.id,
        ...documentSnapshot.data(),
      }));

      setPackagesData(newArray);
      console.log(newArray, "newArraysetPackagesData");
    } catch (err) {
      console.error('Error fetching toll data:', err);
      message.error('Failed to fetch toll data. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleShowLocation = (record) => {
    console.log('record: ', record);

    try {
      // Extract latitude and longitude from the start location
      // We're using the start location, but you can use end if that's what you prefer
      const latitude = record.start.u_;
      const longitude = record.start.h_;

      if (!latitude || !longitude) {
        throw new Error('Latitude or longitude is missing');
      }

      // Open Google Maps in a new tab with the location
      openGoogleMaps(latitude, longitude, record.tollPlaceName);
    } catch (error) {
      console.error('Error opening location:', error.message);
      message.error('Failed to open location. Please try again.');
    }
  };

  const openGoogleMaps = (latitude, longitude, placeName) => {
    // Construct the Google Maps URL with the location
    const googleMapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;

    // Open Google Maps in a new tab
    window.open(googleMapsUrl, '_blank');

    // Show a message with the place name
    message.success(`Showing location for ${placeName}`);
  };


  const columns = [
    {
      title: 'Toll Name',
      dataIndex: 'tollName',
      key: 'tollName',
    },
    {
      title: 'Toll Price',
      dataIndex: 'tollPrice',
      key: 'tollPrice',
    },

    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Space size="middle">
          {/* <a type='link' onClick={() => handleShowLocation(record)}>Show Location</a> */}
          {/* Edit Toll */}
          <a onClick={() => handleEditToll(record)}>Edit</a>
          {/* Delete Toll */}
          <Popconfirm
            title="Are you sure to delete this toll?"
            onConfirm={() => handleDeleteToll(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <a>Delete</a>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  const columnsPackages = [
    {
      title: 'Package Name',
      dataIndex: 'packageName',
      key: 'packageName',
    },
    {
      title: 'Start',
      dataIndex: 'start',
      key: 'start',
    },
    {
      title: 'Price Per Km',
      dataIndex: 'pricePerkm',
      key: 'pricePerkm',
    },
    {
      title: 'Waiting Time',
      dataIndex: 'waitingTime',
      key: 'waitingTime',
    },
    {
      title: 'Slow Speed Limit',
      dataIndex: 'slowSpeedLimit',
      key: 'slowSpeedLimit',
    },
    {
      title: 'Slow Speed Window',
      dataIndex: 'slowSpeedWindow',
      key: 'slowSpeedWindow',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Space size="middle">
          {/* Edit Package */}
          <a onClick={() => handleEditPackages(record)}>Edit</a>
          {/* Delete Package */}
          <Popconfirm
            title="Are you sure to delete this package?"
            onConfirm={() => handleDeletePackages(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <a>Delete</a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const columnsTollAuto = [
    {
      title: 'Toll Name',
      dataIndex: 'tollName',
      key: 'tollName',
    },
    {
      title: 'Toll Place Name',
      dataIndex: 'tollPlaceName',
      key: 'tollPlaceName',
    },
    {
      title: 'Toll Price',
      dataIndex: 'tollPrice',
      key: 'tollPrice',
    },
    {
      title: 'Toll Width',
      dataIndex: 'width',
      key: 'tollWidth',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Space size="middle">
          <a type='link' onClick={() => handleShowLocation(record)}>Show Location</a>
          {/* Edit Toll */}
          <a onClick={() => handleEditTollAuto(record)}>Edit</a>
          {/* Delete Toll */}
          <Popconfirm
            title="Are you sure to delete this toll?"
            onConfirm={() => handleDeleteTollAuto(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <a>Delete</a>
          </Popconfirm>
        </Space>
      ),
    },
  ];




  const handleEditToll = async (record) => {
    console.log('Editing Toll:', record);
    setEditingToll(record);
    setEditTollModalVisible(true);


    // Now you have the place name, you can display it in your UI or do whatever you need with it
  };
  const handleEditTollAuto = async (record) => {
    console.log('Editing Toll:', record);
    setEditingTollAuto(record);
    setEditTollAutoModalVisible(true);


    // Now you have the place name, you can display it in your UI or do whatever you need with it
  };

  const handleEditPackages = (record) => {
    setEditingPackage(record);
    setEditPackageModalVisible(true);
  };

  const handleEditTollCancel = () => {
    setEditTollModalVisible(false);
    editTollForm.resetFields(); // Reset form fields when the modal is closed
  };

  const handleEditTollAutoCancel = () => {
    setEditTollAutoModalVisible(false);
    editTollFormAuto.resetFields(); // Reset form fields when the modal is closed
  };

  const handleEditPackagesCancel = () => {
    setEditPackageModalVisible(false);
    editPackageForm.resetFields(); // Reset form fields when the modal is closed
  };


  const handleEditTollFinish = async (values) => {
    try {
      // Ensure price is a number
      const updatedValues = {
        ...values,
        tollPrice: Number(values.tollPrice)
      };

      await refs.tolls.doc(editingToll.id).update(updatedValues);
      message.success('Toll updated successfully!');
      fetchData();
    } catch (error) {
      console.error('Error updating toll:', error.message);
      message.error('Failed to update toll. Please try again.');
    } finally {
      editTollForm.resetFields();
      setEditTollModalVisible(false);
    }
  };


  const handleEditTollAutoFinish = async (values) => {
    try {
      const updatedValues = {
        // ...values,
        tollPrice: Number(values.tollPrice),
        width: Number(values.tollWidth),
        tollName: values.tollName,
      };

      await refs.tollsAuto.doc(editingTollAuto.id).update(updatedValues);
      message.success('Toll updated successfully!');
      fetchDataAutoTolls();
    } catch (error) {
      console.error('Error updating toll:', error.message);
      message.error('Failed to update toll. Please try again.');
    } finally {
      editTollFormAuto.resetFields();
      setEditTollAutoModalVisible(false);
    }
  };

  const handleEditPackagesFinish = async (values) => {
    console.log('values: ', values);

    try {
        // Explicitly convert slowSpeedLimit and slowSpeedWindow to numbers
        const updatedValues = {
            ...values,
            slowSpeedLimit: Number(values.slowSpeedLimit),
            slowSpeedWindow: Number(values.slowSpeedWindow),
        };

        await refs.packages.doc(editingPackage.id).update(updatedValues);
        message.success('Package updated successfully!');
        fetchDataPackages();
    } catch (error) {
        console.error('Error updating package:', error.message);
        message.error('Failed to update package. Please try again.');
    } finally {
        editPackageForm.resetFields(); // Reset form fields after the operation (success or failure)
        setEditPackageModalVisible(false);
    }
};





  const handleDeletePackages = async (id) => {
    try {
      await refs.packages.doc(id).delete();
      message.success('Package deleted successfully!');
      fetchDataPackages();
    } catch (error) {
      console.error('Error deleting package:', error.message);
      message.error('Failed to delete package. Please try again.');
    }
  };
  const handleDeleteToll = async (id) => {
    try {
      await refs.tolls.doc(id).delete();
      message.success('Toll deleted successfully!');
      fetchData();
    } catch (error) {
      console.error('Error deleting toll:', error.message);
      message.error('Failed to delete toll. Please try again.');
    }
  };
  const handleDeleteTollAuto = async (id) => {
    try {
      await refs.tollsAuto.doc(id).delete();
      message.success('Toll deleted successfully!');
      fetchDataAutoTolls();
    } catch (error) {
      console.error('Error deleting toll:', error.message);
      message.error('Failed to delete toll. Please try again.');
    }
  };





  const onFinishTollAuto = async (values) => {
    try {
      const { tollName, tollPrice, tollWidth } = values;

      // Check if startPoint and endPoint are set
      if (!startPoint || !startPoint.lat || !startPoint.lng || !endPoint || !endPoint.lat || !endPoint.lng) {
        message.error('Please add start and end markers first.');
        return; // Exit the function early
      }

      // Query the collection to find the highest existing orderIndex
      const querySnapshot = await refs.tollsAuto.orderBy('orderIndex', 'desc').limit(1).get();
      let maxOrderIndex = 0;
      if (!querySnapshot.empty) {
        maxOrderIndex = querySnapshot.docs[0].data().orderIndex;
      }

      const startGeoPoint = new firebase.firestore.GeoPoint(startPoint.lat, startPoint.lng);
      const endGeoPoint = new firebase.firestore.GeoPoint(endPoint.lat, endPoint.lng);

      const docRef = await refs.tollsAuto.add({
        tollName,
        tollPrice: parseFloat(tollPrice),
        start: startGeoPoint,
        end: endGeoPoint,
        tollPlaceName: TollNameShow,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        orderIndex: maxOrderIndex + 1, // Add 1 to the highest existing orderIndex
        width: parseFloat(tollWidth)
      });

      const tollId = docRef.id; // Retrieve the ID generated by Firebase
      await refs.tollsAuto.doc(tollId).set({ id: tollId }, { merge: true }); // Add the id field to the document

      message.success('Toll added successfully!');
      addTollFormAuto.resetFields(); // Clear the form fields after successful submission
      fetchDataAutoTolls(); // Refresh data after addition
    } catch (error) {
      console.error('Error adding toll:', error.message);
      message.error('Failed to add toll. Please try again.');
    }
  };


  const onFinishToll = async (values) => {
    try {
      const { tollName, tollPrice } = values;

      // Query the collection to find the highest existing orderIndex
      const querySnapshot = await refs.tolls.orderBy('orderIndex', 'desc').limit(1).get();
      let maxOrderIndex = 0;
      if (!querySnapshot.empty) {
        maxOrderIndex = querySnapshot.docs[0].data().orderIndex;
      }

      // console.log(startPoint, "ssssss");
      // return



      const docRef = await refs.tolls.add({
        tollName,
        tollPrice: parseFloat(tollPrice),
        // start: startGeoPoint,
        // end: endGeoPoint,
        // tollPlaceName: TollNameShow,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        orderIndex: maxOrderIndex + 1, // Add 1 to the highest existing orderIndex
        // width: 15
      });

      const tollId = docRef.id; // Retrieve the ID generated by Firebase
      await refs.tolls.doc(tollId).set({ id: tollId }, { merge: true }); // Add the id field to the document

      message.success('Toll added successfully!');
      addTollForm.resetFields(); // Clear the form fields after successful submission
      fetchData(); // Refresh data after addition
    } catch (error) {
      console.error('Error adding toll:', error.message);
      message.error('Failed to add toll. Please try again.');
    }
  };


  const onFinishPackages = async (values) => {
    try {
      const { packageName, start, pricePerkm, waitingTime, slowSpeedLimit, slowSpeedWindow } = values;
      const createdAt = firebase.firestore.FieldValue.serverTimestamp();

      const slowSpeedLimitNumber = Number(slowSpeedLimit);
      const slowSpeedWindowNumber = Number(slowSpeedWindow);

      const docRef = await refs.packages.add({
        packageName,
        start: start,
        pricePerkm: pricePerkm,
        waitingTime: waitingTime,
        slowSpeedLimit: slowSpeedLimitNumber,
        slowSpeedWindow: slowSpeedWindowNumber,
        createdAt,
      });

      const pkgId = docRef.id;
      await refs.packages.doc(pkgId).set({ id: pkgId }, { merge: true });

      message.success('Package created successfully!');
      addPackageForm.resetFields();
      fetchDataPackages();
    } catch (error) {
      console.error('Error creating package:', error.message);
      message.error('Failed to create package. Please try again.');
    }
  };


  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(tollData);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setTollData(items);  // Update your local state with new order
    handleReorderTolls(items);  // Function to update order in Firestore
  };


  const handleReorderTolls = async (newTollOrder) => {
    const batch = firebase.firestore().batch(); // Get the batch instance

    newTollOrder.forEach((toll, index) => {
      const tollRef = firebase.firestore().collection('tolls').doc(toll.id);
      batch.update(tollRef, {
        orderIndex: index, // Assuming you have an 'orderIndex' field to control the order
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
    });

    try {
      await batch.commit(); // Commit the batch
      message.success('Toll order updated successfully!');
    } catch (error) {
      console.error('Error updating toll order:', error);
      message.error('Failed to update toll order. Please try again.');
    }
  };


  const handleStartPointChange = async (newStartPoint) => {
    console.log('newStartPoint: ', newStartPoint);
    const placeName = await fetchPlaceName(newStartPoint?.lat, newStartPoint?.lng);
    setTollNameShow(placeName)
    setStartPoint(newStartPoint);
    // Do any additional processing here
  };

  const handleEndPointChange = (newEndPoint) => {
    console.log('newEndPoint: ', newEndPoint);
    setEndPoint(newEndPoint);
    // Do any additional processing here
  };


  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div className="header">
        <Title style={{ color: '#fff' }} level={2}>Settings Page</Title>
      </div>

      {/* Add Tabs component for Packages and Toll */}
      <Tabs defaultActiveKey="packages" style={{ marginTop: '16px', width: '80%', maxWidth: '80%' }}>
        <TabPane tab="Packages" key="packages">
          {/* Content for Packages tab */}
          <div style={{ padding: '16px', background: 'white', borderRadius: '8px', marginBottom: '16px' }}>
            <h3>Create Package</h3>

            {/* Ant Design Form for Package Creation */}
            <Form
              form={addPackageForm}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              onFinish={onFinishPackages}
              autoComplete="off"
            >
              <Form.Item label="Package Name" name="packageName" rules={[{ required: true, message: 'Please enter package name' }]}>
                <Input placeholder="Enter package name" />
              </Form.Item>

              <Form.Item label="Start" name="start" rules={[{ required: true, message: 'Please enter start price' }]}>
                <Input
                  placeholder="Enter start price"
                  type='number'
                  addonBefore="$"
                />
              </Form.Item>

              <Form.Item label="Price per km" name="pricePerkm" rules={[{ required: true, message: 'Please enter price per km' }]}>
                <Input
                  placeholder="Enter price per km"
                  type='number'
                  addonBefore="$"
                />
              </Form.Item>

              <Form.Item label="Waiting Time" name="waitingTime" rules={[{ required: true, message: 'Please enter waiting time price' }]}>
                <Input
                  placeholder="Enter waiting time price"
                  type='number'
                  addonBefore="$"
                />
              </Form.Item>

              <Form.Item label="Slow Speed Limit" name="slowSpeedLimit" rules={[{ required: true, message: 'Please enter Slow Speed Limit' }]}>
                <Input
                  placeholder="Enter Slow Speed Limit"
                  type='number'
                // addonBefore="$"
                />
              </Form.Item>

              <Form.Item label="Slow Speed Window" name="slowSpeedWindow" rules={[{ required: true, message: 'Please enter Slow Speed Window' }]}>
                <Input
                  placeholder="Enter Slow Speed Window"
                  type='number'
                // addonBefore="$"
                />
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button

                  type="primary" htmlType="submit">
                  Create Package
                </Button>
              </Form.Item>
            </Form>
            <Table dataSource={packagesData} columns={columnsPackages} loading={loading} />
          </div>
        </TabPane>
        <TabPane tab="Toll Auto" key="tollAuto">
          {/* Content for Toll tab */}
          <TabPane tab="Manual" key="manual">

          </TabPane>
          <TabPane tab="Auto" key="auto">

          </TabPane>
          <div style={{ padding: '16px', background: 'white', borderRadius: '8px', marginBottom: '16px' }}>
            <h2>Set Location</h2>

            <MapComponent
              startPoint={startPoint}
              endPoint={endPoint}
              onStartPointChange={handleStartPointChange}
              onEndPointChange={handleEndPointChange}
            />
            {/* <p>Latitude: {latitude}</p>
            <p>Longitude: {longitude}</p> */}
            <h3>Add tolls</h3>

            {/* Ant Design Form for Toll Name and Toll Price */}
            <Form
              form={addTollFormAuto}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              onFinish={onFinishTollAuto}
              autoComplete="off"
            >
              <Form.Item label="Toll Name" name="tollName" rules={[{ required: true, message: 'Please enter toll name' }]}>
                <Input placeholder="Enter toll name" />
              </Form.Item>

              <Form.Item label="Toll Price" name="tollPrice" rules={[{ required: true, message: 'Please enter toll price' }]}>
                <Input type='number' placeholder="Enter toll price" />
              </Form.Item>

              <Form.Item label="Toll Road Width" name="tollWidth" rules={[{ required: true, message: 'Please enter toll Width' }]}>
                <Input type='number' placeholder="Enter toll Road Width" />
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit">
                  Add Toll
                </Button>
              </Form.Item>
            </Form>

            {/* Ant Design Table for Toll Data */}

            {/* <DragDropContext onDragEnd={onDragEndAutoTolls}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {tollDataAuto.map((item, index) => (
                      <Draggable key={item.id} draggableId={item.id} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >

                            <div>{item.tollName}</div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext> */}
            <Table dataSource={tollDataAuto} columns={columnsTollAuto} loading={loading} />
          </div>
        </TabPane>
        <TabPane tab="Toll" key="toll">
          {/* Content for Toll tab */}
          <TabPane tab="Manual" key="manual">

          </TabPane>
          <TabPane tab="Auto" key="auto">

          </TabPane>
          <div style={{ padding: '16px', background: 'white', borderRadius: '8px', marginBottom: '16px' }}>
            {/* <h2>Set Location</h2>

            <MapComponent
              startPoint={startPoint}
              endPoint={endPoint}
              onStartPointChange={handleStartPointChange}
              onEndPointChange={handleEndPointChange}
            />
            <p>Latitude: {latitude}</p>
            <p>Longitude: {longitude}</p>
            <h3>Add tolls</h3> */}

            {/* Ant Design Form for Toll Name and Toll Price */}
            <Form
              form={addTollForm}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              onFinish={onFinishToll}
              autoComplete="off"
            >
              <Form.Item label="Toll Name" name="tollName" rules={[{ required: true, message: 'Please enter toll name' }]}>
                <Input placeholder="Enter toll name" />
              </Form.Item>

              <Form.Item label="Toll Price" name="tollPrice" rules={[{ required: true, message: 'Please enter toll price' }]}>
                <Input type='number' placeholder="Enter toll price" />
              </Form.Item>



              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit">
                  Add Toll
                </Button>
              </Form.Item>
            </Form>

            {/* Ant Design Table for Toll Data */}

            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {tollData.map((item, index) => (
                      <Draggable key={item.id} draggableId={item.id} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >

                            <div>{item.tollName}</div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <Table dataSource={tollData} columns={columns} loading={loading} />
          </div>
        </TabPane>
      </Tabs>
      <Modal
        title="Edit Toll Auto"
        visible={editTollAutoModalVisible}
        onCancel={handleEditTollAutoCancel}
        footer={null}
      >
        <Form
          key={editingTollAuto ? editingTollAuto.id : 'newForm'}
          form={editTollFormAuto}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={handleEditTollAutoFinish}
          initialValues={editingTollAuto}
        >
          {/* Similar form fields as the "Add Toll" form */}
          <Form.Item label="Toll Name" name="tollName" rules={[{ required: true, message: 'Please enter toll name' }]}>
            <Input placeholder="Enter toll name" />
          </Form.Item>

          <Form.Item label="Toll Price" name="tollPrice" rules={[{ required: true, message: 'Please enter toll price' }]}>
            <Input placeholder="Enter toll price" />
          </Form.Item>

          <Form.Item label="Toll Road Width" name="tollWidth" rules={[{ required: true, message: 'Please enter toll Width' }]}>
            <Input type='number' placeholder="Enter toll Road Width" />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Update Toll
            </Button>
          </Form.Item>
        </Form>
      </Modal>


      <Modal
        title="Edit Toll"
        visible={editTollModalVisible}
        onCancel={handleEditTollCancel}
        footer={null}
      >
        <Form
          key={editingToll ? editingToll.id : 'newForm'}
          form={editTollForm}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={handleEditTollFinish}
          initialValues={editingToll}
        >
          {/* Similar form fields as the "Add Toll" form */}
          <Form.Item label="Toll Name" name="tollName" rules={[{ required: true, message: 'Please enter toll name' }]}>
            <Input placeholder="Enter toll name" />
          </Form.Item>

          <Form.Item label="Toll Price" name="tollPrice" rules={[{ required: true, message: 'Please enter toll price' }]}>
            <Input placeholder="Enter toll price" />
          </Form.Item>



          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Update Toll
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* Edit Package Modal */}
      <Modal
        title="Edit Package"
        visible={editPackageModalVisible}
        onCancel={handleEditPackagesCancel}
        footer={null}
      >
        <Form
          key={editingPackage ? editingPackage.id : 'newPackageForm'}
          form={editPackageForm}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={handleEditPackagesFinish}
          initialValues={editingPackage}
        >
          {/* Similar form fields as the "Create Package" form */}
          <Form.Item label="Package Name" name="packageName" rules={[{ required: true, message: 'Please enter package name' }]}>
            <Input placeholder="Enter package name" />
          </Form.Item>

          <Form.Item label="Start" name="start" rules={[{ required: true, message: 'Please enter start price' }]}>
            <Input
              placeholder="Enter start price"

              addonBefore="$"
            />
          </Form.Item>

          <Form.Item label="Price per km" name="pricePerkm" rules={[{ required: true, message: 'Please enter price per km' }]}>
            <Input
              placeholder="Enter price per km"

              addonBefore="$"
            />
          </Form.Item>

          <Form.Item label="Waiting Time" name="waitingTime" rules={[{ required: true, message: 'Please enter waiting time price' }]}>
            <Input
              placeholder="Enter waiting time price"

              addonBefore="$"
            />
          </Form.Item>

          <Form.Item label="Slow Speed Limit" name="slowSpeedLimit" rules={[{ required: true, message: 'Please enter Slow Speed Limit' }]}>
            <Input
              placeholder="Enter Slow Speed Limit"
              type='number'
            // addonBefore="$"
            />
          </Form.Item>

          <Form.Item label="Slow Speed Window" name="slowSpeedWindow" rules={[{ required: true, message: 'Please enter Slow Speed Window' }]}>
            <Input
              placeholder="Enter Slow Speed Window"
              type='number'
            // addonBefore="$"
            />
          </Form.Item>

          {/* ... Add other form fields for editing packages as needed */}

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Update Package
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Settings;
