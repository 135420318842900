import React, { useEffect, useState } from 'react';
import { Typography, Tabs, Form, Input, Button, message, Space, Popconfirm, Table, Modal, Select, TimePicker } from 'antd';
import firebase, { firestore } from 'firebase';
import { auth, refs } from '../firebase/firebase';
import { v4 as uuidv4 } from 'uuid';
import MapComponent from '../components/MapComponent';
import axios from 'axios';
import moment from 'moment';

const { Title } = Typography;
const { Option } = Select;

export const Packages = () => {
  const [addPackageForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [packagesData, setPackagesData] = useState([]);
  console.log('packagesData: ', packagesData);
  const [oldDataPkg, setOldDataPkg] = useState([]);
  // console.log('oldDataPkg: ', oldDataPkg);
  const [editingPackage, setEditingPackage] = useState(null);
  const [editPackageModalVisible, setEditPackageModalVisible] = useState(false);
  const [editPackageForm] = Form.useForm();
  const [addedPackages, setAddedPackages] = useState([]);

  useEffect(() => {
    fetchDataPackages();
    fetchDataOldPackages()

  }, []);

  const fetchDataPackages = async () => {
    try {
      setLoading(true);
      const querySnapshot = await refs.tariffpackages.limit(300).get();
      const newArray = querySnapshot.docs.map((documentSnapshot) => ({
        id: documentSnapshot.id,
        ...documentSnapshot.data(),
      }));
      setPackagesData(newArray);
    } catch (err) {
      console.error('Error fetching toll data:', err);
      message.error('Failed to fetch toll data. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const fetchDataOldPackages = async () => {
    try {
      setLoading(true);
      const querySnapshot = await refs.packages.limit(300).get();
      const newArray = querySnapshot.docs.map((documentSnapshot) => ({
        id: documentSnapshot.id,
        ...documentSnapshot.data(),
      }));
      setOldDataPkg(newArray);
    } catch (err) {
      console.error('Error fetching toll data:', err);
      message.error('Failed to fetch toll data. Please try again.');
    } finally {
      setLoading(false);
    }
  };


  const timeOverlap = (start1, end1, start2, end2) => {
    return Math.max(start1, start2) < Math.min(end1, end2);
  }


  const onFinishPackages = async (values) => {
    try {
      const { packageName, start, pricePerKm, waitingTime, tariffDetails } = values;
      const createdAt = firebase.firestore.Timestamp.now();

      const formattedTariffDetails = tariffDetails.map(detail => ({
        ...detail,
        startTime: detail.startTime.toDate(),
        endTime: detail.endTime.toDate(),
        package: addedPackages
      }));

      // Check for time conflicts before adding a new package
      let conflictFound = false;
      for (let detail of formattedTariffDetails) {
        const dayToCheck = detail.day;
        const startTimeToCheck = detail.startTime.getTime();
        const endTimeToCheck = detail.endTime.getTime();

        conflictFound = packagesData.some(pkg => {
          return pkg.tariffDetails.some(existingDetail => {
            return existingDetail.day === dayToCheck && timeOverlap(
              existingDetail.startTime.toDate().getTime(),
              existingDetail.endTime.toDate().getTime(),
              startTimeToCheck,
              endTimeToCheck
            );
          });
        });

        if (conflictFound) {
          message.error('A conflicting package exists on the same day and time.');
          return;
        }
      }

      if (!conflictFound) {
        const docRef = await refs.tariffpackages.add({
          tariffDetails: formattedTariffDetails,
          createdAt,
        });

        const pkgId = docRef.id;
        await refs.tariffpackages.doc(pkgId).set({ id: pkgId }, { merge: true });

        message.success('Package created successfully!');
        addPackageForm.resetFields();
        fetchDataPackages();
      }
    } catch (error) {
      console.error('Error creating package:', error.message);
      message.error('Failed to create package. Please try again.');
    }
  };



  const handleEditPackages = (record) => {
    setEditingPackage(record);
    setEditPackageModalVisible(true);
  };

  const handleDeletePackages = async (id) => {
    try {
      await refs.tariffpackages.doc(id).delete();
      message.success('Package deleted successfully!');
      fetchDataPackages();
    } catch (error) {
      console.error('Error deleting package:', error.message);
      message.error('Failed to delete package. Please try again.');
    }
  };

  const handleEditPackagesCancel = () => {
    setEditPackageModalVisible(false);
    editPackageForm.resetFields();
  };

  const handleEditPackagesFinish = async (values) => {
    try {
      await refs.tariffpackages.doc(editingPackage.id).update(values);
      message.success('Package updated successfully!');
      fetchDataPackages();
    } catch (error) {
      console.error('Error updating package:', error.message);
      message.error('Failed to update package. Please try again.');
    } finally {
      editPackageForm.resetFields();
      setEditPackageModalVisible(false);
    }
  };


  const dayOrder = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  const sortByDay = (a, b) => {
    const dayA = a.tariffDetails[0]?.day;
    const dayB = b.tariffDetails[0]?.day;
    return dayOrder.indexOf(dayA) - dayOrder.indexOf(dayB);
  };

  const columnsPackages = [
    {
      title: 'Day',
      dataIndex: ['tariffDetails', '0', 'day'],
      key: 'day',
      sorter: sortByDay,
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Time Range',
      key: 'timeRange',
      render: (text, record) => {
        const detail = record.tariffDetails[0];
        if (detail && detail.startTime && detail.endTime) {
          const start = moment(detail.startTime.toDate()).format('HH:mm');
          const end = moment(detail.endTime.toDate()).format('HH:mm');
          return `${start} - ${end}`;
        }
        return 'N/A';
      },
    },
    {
      title: 'Package Name',
      key: 'packageName',
      render: (text, record) => record.tariffDetails[0]?.package?.packageName || 'N/A',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Space size="middle">
          <Popconfirm
            title="Are you sure to delete this package?"
            onConfirm={() => handleDeletePackages(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <a>Delete</a>
          </Popconfirm>
        </Space>
      ),
    },
  ];


  const handleSelectChange = (index) => {
    const selectedPackage = oldDataPkg[index];
    // console.log('selectedPackage: ', selectedPackage);
    setAddedPackages(selectedPackage);
  }


  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div className="header">
        <Title style={{ color: '#fff' }} level={2}>Packages Page</Title>
      </div>
      <div style={{ padding: '16px', background: 'white', borderRadius: '8px', marginBottom: '16px', width: '100%' }}>
        <h3>Create Package</h3>
        <Form
          form={addPackageForm}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={onFinishPackages}
          autoComplete="off"
        >

          <Form.Item label="Tariff Details" name="tariffDetails">
            <Form.List name="tariffDetails">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                      <Form.Item
                        {...restField}
                        name={[name, 'day']}
                        fieldKey={[fieldKey, 'day']}
                        rules={[{ required: true, message: 'Day is required' }]}
                      >
                        <Select placeholder="Select day">
                          <Option value="Monday">Monday</Option>
                          <Option value="Tuesday">Tuesday</Option>
                          <Option value="Wednesday">Wednesday</Option>
                          <Option value="Thursday">Thursday</Option>
                          <Option value="Friday">Friday</Option>
                          <Option value="Saturday">Saturday</Option>
                          <Option value="Sunday">Sunday</Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'startTime']}
                        fieldKey={[fieldKey, 'startTime']}
                        rules={[{ required: true, message: 'Start time is required' }]}
                      >
                        <TimePicker format="HH:mm" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'endTime']}
                        fieldKey={[fieldKey, 'endTime']}
                        rules={[{ required: true, message: 'End time is required' }]}
                      >
                        <TimePicker format="HH:mm" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'tariff']}
                        fieldKey={[fieldKey, 'tariff']}
                        rules={[{ required: true, message: 'Tariff is required' }]}
                      >
                        <Select
                          placeholder="Select tariff"
                          onChange={(index) => handleSelectChange(index)}
                        >
                          {oldDataPkg.map((pkg, index) => (
                            <Select.Option key={pkg.id} value={index}>{pkg.packageName}</Select.Option>
                          ))}
                        </Select>




                      </Form.Item>
                      <Button onClick={() => remove(name)}>Remove</Button>
                    </Space>
                  ))}
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block>
                      Add Tariff
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">Create Package</Button>
          </Form.Item>
        </Form>
        <Table
          dataSource={packagesData}
          columns={columnsPackages}
          loading={loading}
          rowKey="id"
          defaultSortOrder="ascend"
          sortDirections={['ascend']}
        />

      </div>
      <Modal
        title="Edit Package"
        visible={editPackageModalVisible}
        onCancel={handleEditPackagesCancel}
        footer={null}
      >
        <Form
          key={editingPackage ? editingPackage.id : 'newPackageForm'}
          form={editPackageForm}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={handleEditPackagesFinish}
          initialValues={editingPackage}
        >

          <Form.Item label="Tariff Details" name="tariffDetails">
            <Form.List name="tariffDetails">
              {(fields, { add, remove }) => (
                <>
                  {fields?.map(({ key, name, fieldKey, ...restField }) => (
                    <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                      <Form.Item
                        {...restField}
                        name={[name, 'day']}
                        fieldKey={[fieldKey, 'day']}
                        rules={[{ required: true, message: 'Day is required' }]}
                      >
                        <Select placeholder="Select day">
                          <Option value="Monday">Monday</Option>
                          <Option value="Tuesday">Tuesday</Option>
                          <Option value="Wednesday">Wednesday</Option>
                          <Option value="Thursday">Thursday</Option>
                          <Option value="Friday">Friday</Option>
                          <Option value="Saturday">Saturday</Option>
                          <Option value="Sunday">Sunday</Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'startTime']}
                        fieldKey={[fieldKey, 'startTime']}
                        rules={[{ required: true, message: 'Start time is required' }]}
                      >
                        <TimePicker format="HH:mm" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'endTime']}
                        fieldKey={[fieldKey, 'endTime']}
                        rules={[{ required: true, message: 'End time is required' }]}
                      >
                        <TimePicker format="HH:mm" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'tariff']}
                        fieldKey={[fieldKey, 'tariff']}
                        rules={[{ required: true, message: 'Tariff is required' }]}
                      >
                        <Select placeholder="Select tariff">
                          <Option value="1">Tariff 1</Option>
                          <Option value="2">Tariff 2</Option>
                          <Option value="3">Tariff 3</Option>
                        </Select>
                      </Form.Item>
                      <Button onClick={() => remove(name)}>Remove</Button>
                    </Space>
                  ))}
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block>
                      Add Tariff
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">Update Package</Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}