import { Select, Typography, Table, Button, message, Modal, Input, Popconfirm, DatePicker } from 'antd';
import React, { useEffect, useState } from 'react';
import { refs } from '../firebase/firebase';
import 'firebase/firestore';
import firebase from 'firebase/app';
import axios from 'axios';
import { isDev } from '../utils';
import moment from 'moment';
import { DeleteOutlined, EyeOutlined, MailOutlined, DownloadOutlined, PlusOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';

const { Title } = Typography;
const { Option } = Select;

const Complaints = () => {
    const [complaints, setComplaints] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedComplaint, setSelectedComplaint] = useState(null); // State to hold the selected complaint for details
    const [notes, setNotes] = useState({}); // State to hold notes for each complaint
    const [noteInput, setNoteInput] = useState(''); // State to hold the note input
    const [sendMailModalVisible, setSendMailModalVisible] = useState(false); // State for send mail modal visibility
    const [emailContent, setEmailContent] = useState(''); // State to hold the email content
    const [currentEmail, setCurrentEmail] = useState(''); // State to hold the current email address
    const [currentEmailname, setCurrentEmailName] = useState(''); // State to hold the current email address
    const [currentComplaintId, setCurrentComplaintId] = useState(''); // State to hold the current email address
    const [searchTerm, setSearchTerm] = useState(''); // State for search term
    const [statusFilter, setStatusFilter] = useState('All'); // New state for status filter
    const [dateRange, setDateRange] = useState(null);


    useEffect(() => {
        // Set up real-time listener
        setLoading(true);
        const unsubscribe = refs.complaints
            .orderBy('createdAt', 'desc')
            .onSnapshot(
                (snapshot) => {
                    const newComplaints = snapshot.docs.map((doc) => ({
                        key: doc.id,
                        ...doc.data(),
                    }));
                    setComplaints(newComplaints);
                    setLoading(false);
                },
                (error) => {
                    console.error('Error listening to complaints:', error);
                    message.error('Failed to listen to complaints updates');
                    setLoading(false);
                }
            );

        // Cleanup listener on component unmount
        return () => unsubscribe();
    }, []); // Empty dependency array means this effect runs once on mount






    const fetchComplaints = async () => {
        setLoading(true);
        try {
            const querySnapshot = await refs.complaints.orderBy('createdAt', 'desc').get();
            const newArray = querySnapshot.docs.map((documentSnapshot) => {
                const data = documentSnapshot.data();
                return {
                    key: documentSnapshot.id, // Add a unique key for each complaint
                    ...data,
                };
            });
            setComplaints(newArray);
        } catch (error) {
            console.error('Error fetching complaints:', error.message);
            message.error('Failed to fetch complaints. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const filteredComplaints = complaints.filter(complaint => {
        // Search filter
        const matchesSearch = (complaint.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            complaint.email?.toLowerCase().includes(searchTerm.toLowerCase()));

        // Status filter
        const matchesStatus = statusFilter === 'All' || complaint.status === statusFilter;

        // Date range filter
        let matchesDateRange = true;
        if (dateRange && dateRange[0] && dateRange[1]) {
            // Get the timestamp
            const timestamp = complaint.createdAt;

            // Convert to JavaScript Date
            const complaintDate = timestamp instanceof firebase.firestore.Timestamp
                ? timestamp.toDate()
                : new Date(timestamp);

            // Convert selected range to start/end of day
            const startDate = dateRange[0].startOf('day').toDate();
            const endDate = dateRange[1].endOf('day').toDate();

            // Compare dates
            matchesDateRange = complaintDate >= startDate && complaintDate <= endDate;

            // Debug logging
            console.log({
                complaintId: complaint.complaintId,
                complaintDate: complaintDate.toISOString(),
                startDate: startDate.toISOString(),
                endDate: endDate.toISOString(),
                matchesDateRange,
                originalTimestamp: timestamp,
                dateRange
            });
        }

        const matches = matchesSearch && matchesStatus && matchesDateRange;

        // Debug log for overall filtering
        if (dateRange && dateRange[0] && dateRange[1]) {
            console.log(`Complaint ${complaint.complaintId}: search=${matchesSearch}, status=${matchesStatus}, date=${matchesDateRange}, final=${matches}`);
        }

        return matches;
    });

    // Update the handleDateRangeChange function
    const handleDateRangeChange = (dates) => {
        console.log('Date Range Changed:', {
            dates,
            raw: dates ? [dates[0]?.valueOf(), dates[1]?.valueOf()] : null
        });

        setDateRange(dates);

        // Immediately log the current complaints and date range
        console.log('Current Complaints:', complaints);
        console.log('New Date Range:', dates);
    };

    const handleDeleteComplaint = async (complaintId) => { // New delete function
        try {
            await refs.complaints.doc(complaintId).delete(); // Delete the complaint from Firestore
            message.success('Complaint deleted successfully');
            fetchComplaints(); // Refresh the complaints list
        } catch (error) {
            console.error('Error deleting complaint:', error.message);
            message.error('Failed to delete complaint. Please try again.');
        }
    };

    const showSendMailModal = (email, name, complaintId) => { // Add complaintId parameter
        setCurrentEmail(email); // Set the email of the selected complaint
        setCurrentEmailName(name); // Set the email of the selected complaint
        setCurrentComplaintId(complaintId); // Store the complaintId
        setSendMailModalVisible(true); // Show the send mail modal
    };

    const handleSendEmail = async () => {
        if (currentEmail && emailContent.trim()) {
            try {
                await sendEmailOwn(currentEmail, currentComplaintId); // Pass complaintId to the function
                message.success('Email sent successfully');
                setSendMailModalVisible(false); // Close the modal after sending
                setEmailContent(''); // Clear the email content
            } catch (error) {
                message.error('Failed to send email: ' + error.message);
            }
        } else {
            message.error('Please enter a valid email content.');
        }
    };
    // ... existing code ...
    const handleStatusChange = async (complaintId, status) => {
        try {
            const updateData = { status };
            if (status === 'Resolved') {
                updateData.resolvedDate = firebase.firestore.FieldValue.serverTimestamp();
            }
            await refs.complaints.doc(complaintId).update(updateData);
            message.success(`Status updated to ${status}`);

            // Fetch the complaint details to get the email and other necessary info
            const complaintSnapshot = await refs.complaints.doc(complaintId).get();
            const complaintData = complaintSnapshot.data();

            // Send email based on the status
            await sendEmail(complaintData.name, complaintData.email, complaintData.driverId, complaintData.pin, status, complaintData.complaintId);

            fetchComplaints(); // Refresh the complaints list
        } catch (error) {
            console.error('Error updating status:', error.message);
            message.error('Failed to update status. Please try again.');
        }
    };


    const sendEmailOwn = async (email, complaintId) => { // Add complaintId as a parameter
        try {
            const apiKey = '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'; // Replace with your SendinBlue API key
            const senderEmail = 'Helpdesk@harbourcabs.com.au'; // Replace with your sender email address
            const senderName = 'Harbour Cabs'; // Replace with your sender name

            const response = await axios.post(
                'https://api.sendinblue.com/v3/smtp/email',
                {
                    sender: { name: senderName, email: senderEmail },
                    to: [{ email: email }],
                    subject: 'Harbour Cabs',
                    htmlContent: `
                    <!DOCTYPE html>
                    <html lang="en">
                    <head>
                        <meta charset="UTF-8">
                        <meta name="viewport" content="width=device-width, initial-scale=1.0">
                        <title>Complaint Status Update</title>
                        <style>
                            body {
                                font-family: Arial, sans-serif;
                                line-height: 1.6;
                                background-color: #f9f9f9;
                                padding: 20px;
                            }
                            .container {
                                max-width: 600px;
                                margin: 0 auto;
                                background-color: #fff;
                                padding: 20px;
                                border-radius: 10px;
                                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                            }
                            h1 {
                                color: #333;
                            }
                            p {
                                margin-bottom: 10px;
                            }
                            .message {
                                background-color: #f3f3f3;
                                padding: 15px;
                                border-radius: 5px;
                            }
                            .footer {
                                margin-top: 20px;
                                text-align: center;
                            }
                        </style>
                    </head>
                    <body>
                        <div class="container">
                            <h1>Harbour Cabs</h1>
                            <div class="message">
                               <p>Hi ${currentEmailname},</p> 
                                <p>${emailContent}</strong>.</p>
                                <p>Your Complaint ID is: <strong>${currentComplaintId}</strong></p> <!-- Include complaintId -->
                                <p>If you have any questions or need assistance, feel free to contact us at Helpdesk@harbourcabs.com.au </p>
                            </div>
                            <div class="footer">
                                <p>Best regards,</p>
                                <p>Harbour Cabs</p>
                            </div>
                        </div>
                    </body>
                    </html>
                    `,
                },
                {
                    headers: {
                        'api-key': apiKey,
                        'Content-Type': 'application/json',
                    },
                }
            );
            message.success('Email sent successfully');
            console.log('Email sent successfully:', response.data);
        } catch (error) {
            message.error('Error sending email: ' + error.message);
            console.error('Error sending email:', error.message);
        }
    };
    // ... existing code ...

    const sendEmail = async (name, email, driverId, pin, currentStatus, complaintId) => { // Add currentStatus as a parameter
        try {
            const apiKey = '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'; // Replace with your SendinBlue API key
            const senderEmail = 'Helpdesk@harbourcabs.com.au'; // Replace with your sender email address
            const senderName = 'Harbour Cabs'; // Replace with your sender name

            const response = await axios.post(
                'https://api.sendinblue.com/v3/smtp/email',
                {
                    sender: { name: senderName, email: senderEmail },
                    to: [{ email: email }],
                    subject: currentStatus === "In-Process" ? 'Your Complaint is Now In-Process' : 'Your Complaint has been Resolved',
                    htmlContent: `
                    <!DOCTYPE html>
                    <html lang="en">
                    <head>
                        <meta charset="UTF-8">
                        <meta name="viewport" content="width=device-width, initial-scale=1.0">
                        <title>Complaint Status Update</title>
                        <style>
                            body {
                                font-family: Arial, sans-serif;
                                line-height: 1.6;
                                background-color: #f9f9f9;
                                padding: 20px;
                            }
                            .container {
                                max-width: 600px;
                                margin: 0 auto;
                                background-color: #fff;
                                padding: 20px;
                                border-radius: 10px;
                                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                            }
                            h1 {
                                color: #333;
                            }
                            p {
                                margin-bottom: 10px;
                            }
                            .message {
                                background-color: #f3f3f3;
                                padding: 15px;
                                border-radius: 5px;
                            }
                            .footer {
                                margin-top: 20px;
                                text-align: center;
                            }
                        </style>
                    </head>
                    <body>
                        <div class="container">
                            <h1>Complaint Status Update</h1>
                            <div class="message">
                                <p>Hi ${name},</p>
                                <p>Your complaint status has been updated to: <strong>${currentStatus}</strong>.</p>
                                ${currentStatus === "In-Process" ?
                            `<p>Your Compaint is in process we are founding out the  issues we will let you know soon </p>
                                 <p> ` :
                            `<p>Thank you for your patience.</p>`}
                             <p>Your Complaint ID is: <strong>${complaintId}</strong></p> <!-- Include complaintId -->
                                <p>If you have any questions or need assistance, feel free to contact us at Helpdesk@harbourcabs.com.au </p>
                            </div>
                            <div class="footer">
                                <p>Best regards,</p>
                                <p>${senderName}</p>
                                <p>${senderEmail}</p>
                            </div>
                        </div>
                    </body>
                    </html>
                    `,
                },
                {
                    headers: {
                        'api-key': apiKey,
                        'Content-Type': 'application/json',
                    },
                }
            );
            message.success('Email sent successfully');
            console.log('Email sent successfully:', response.data);
        } catch (error) {
            message.error('Error sending email: ' + error.message);
            console.error('Error sending email:', error.message);
        }
    };

    // ... existing code ...

    // ... existing code ...

    const showDetailsModal = async (complaint) => {
        setSelectedComplaint(complaint);

        // If complaint is unread, mark it as read
        if (!complaint.isRead) {
            try {
                await refs.complaints.doc(complaint.key).update({
                    isRead: true,
                    readAt: firebase.firestore.FieldValue.serverTimestamp()
                });
            } catch (error) {
                console.error('Error marking complaint as read:', error);
            }
        }
    };

    const handleModalClose = () => {
        setSelectedComplaint(null);
    };

    const handleAddNote = (complaintId, note) => {
        if (note.trim()) { // Check if note is not empty
            const newNotes = { ...notes, [complaintId]: [...(notes[complaintId] || []), { text: note, date: new Date() }] };
            setNotes(newNotes);
            setNoteInput(''); // Clear the note input after adding
        }
    };

    const handleDeleteNote = (complaintId, noteIndex) => {
        const newNotes = { ...notes };
        newNotes[complaintId].splice(noteIndex, 1); // Remove the note at the specified index
        setNotes(newNotes);
    };

    useEffect(() => {
        fetchComplaints();
    }, []);


    const handleDownload = () => {
        const dataToExport = filteredComplaints.map(complaint => ({
            'Complaint ID': complaint.complaintId,
            'Client Name': complaint.name,
            'Email': complaint.email,
            'Phone': complaint.phone,
            'Date of Travel': moment(complaint.dateOfTravel.toDate()).format('MMMM D, YYYY'),
            'Time of Travel': complaint.timeOfTravel,
            'Pick Up Location': complaint.pickUpLocation,
            'Drop Off Location': complaint.dropOffLocation,
            'Driver Name': complaint.driverName,
            'Car Number': complaint.carNumber,
            'Meter ID': complaint.meterId,
            'Query Regarding': complaint.queryRegarding,
            'Receipt Type': complaint.receiptType,
            'Exact Amount Charged': complaint.exactAmountCharged,
            'Additional Comments': complaint.additionalComments,
            'Status': complaint.status,
            'Created At': moment(complaint.createdAt.toDate()).format('MMMM D, YYYY, h:mm A'),
            'Resolved Date': complaint.resolvedDate ? moment(complaint.resolvedDate.toDate()).format('MMMM D, YYYY, h:mm A') : '-'
        }));

        const ws = XLSX.utils.json_to_sheet(dataToExport);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Complaints");

        // Generate & save the file
        XLSX.writeFile(wb, "Complaints_Data.xlsx");
    };


    const columns = [
        {
            title: 'Client Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Bill',
            dataIndex: 'exactAmountCharged',
            key: 'exactAmountCharged',
        },

        {
            title: 'Date of Travel',
            dataIndex: 'dateOfTravel',
            key: 'dateOfTravel',
            render: (text) => {
                const date = moment(text instanceof Date ? text : text?.toDate());
                return date.isValid() ? date.format('MMMM D, YYYY') : 'Invalid Date';
            },
        },
        {
            title: 'Time of Travel',
            dataIndex: 'timeOfTravel',
            key: 'timeOfTravel',
            render: (text) => {
                if (!text) return 'N/A';

                // Parse the time string and format it
                const time = moment(text, 'HH:mm');

                // Check if the parsed time is valid
                if (!time.isValid()) return 'Invalid Time';

                // Format the time as desired
                return time.format('hh:mm A'); // This will display time in 12-hour format with AM/PM
            },
        },
        {
            title: 'Pick Up Location',
            dataIndex: 'pickUpLocation',
            key: 'pickUpLocation',
        },
        {
            title: 'Drop Off Location',
            dataIndex: 'dropOffLocation',
            key: 'dropOffLocation',
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text) => {
                const date = moment(text instanceof Date ? text : text?.toDate());
                return date.isValid() ? date.format('MMMM D, YYYY, h:mm A') : 'Invalid Date';
            },
            sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => (
                <Select
                    defaultValue={text}
                    onChange={(value) => handleStatusChange(record.key, value)}
                >
                    <Option value="In-Process">In-Process</Option>
                    <Option value="Resolved">Resolved</Option>
                </Select>
            ),
        },
        {
            title: 'Resolved Date',
            dataIndex: 'resolvedDate',
            key: 'resolvedDate',
            render: (text, record) => {
                if (record.status === 'Resolved' && text) {
                    return moment(text.toDate()).format('MMMM D, YYYY, h:mm A');
                }
                return '-';
            },
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <div style={{ display: 'flex', gap: '10px' }}>
                    <Button type="link" onClick={() => showDetailsModal(record)}>
                        <EyeOutlined style={{ fontSize: '16px', color: 'blue' }} />
                    </Button>
                    <Popconfirm
                        title="Are you sure you want to delete this complaint?"
                        onConfirm={() => handleDeleteComplaint(record.key)}
                        onCancel={() => console.log('Delete action cancelled')}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="link">
                            <DeleteOutlined />
                        </Button>
                    </Popconfirm>
                    <Button
                        type="link"
                        onClick={() => showSendMailModal(record.email, record.name, record.complaintId)}
                    >
                        <MailOutlined style={{ fontSize: '16px', color: 'blue' }} />
                    </Button>
                </div>
            ),
        },
    ];

    const handleSaveChanges = async (complaint) => {
        try {
            await refs.complaints.doc(complaint.key).update({
                driverName: complaint.driverName,
                carNumber: complaint.carNumber,
                meterId: complaint.meterId,
            });
            message.success('Changes saved successfully');
            fetchComplaints(); // Refresh the complaints list
        } catch (error) {
            console.error('Error saving changes:', error.message);
            message.error('Failed to save changes. Please try again.');
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
            <div className="header">
                <Title style={{ color: '#fff' }} level={2}>
                    Complaints
                </Title>


            </div>

            <div style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                marginTop: '20px'
            }}>
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    href={isDev() ? "https://harbourcabsdevcomplains.web.app" : "https://harbourcabscomplaints.web.app/"}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                        marginRight: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '200px',
                        height: '40px'
                    }}
                >
                    Add New Complaint
                </Button>
                <Button
                    type="primary"
                    icon={<DownloadOutlined />}
                    onClick={handleDownload}
                    style={{
                        marginLeft: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '200px',
                        height: '40px'
                    }}
                >
                    Download Data
                </Button>
            </div>


            <div style={{ display: 'flex', justifyContent: 'space-between', width: '80%', marginTop: '20px', gap: '16px' }}>
                <Input
                    placeholder="Search by name or email"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    style={{ width: '30%' }}
                />
                <Select
                    defaultValue="All"
                    style={{ width: '20%' }}
                    onChange={(value) => setStatusFilter(value)}
                >
                    <Option value="All">All Statuses</Option>
                    <Option value="Pending">Pending</Option>
                    <Option value="In-Process">In Progress</Option>
                    <Option value="Resolved">Resolved</Option>
                </Select>
                <DatePicker.RangePicker
                    style={{ width: '40%' }}
                    onChange={handleDateRangeChange}
                    format="DD-MM-YYYY"
                    placeholder={['Start Date', 'End Date']}
                    showTime={false}
                    allowClear={true}
                    value={dateRange}
                />
            </div>


            <Table
                dataSource={filteredComplaints}
                columns={columns}
                loading={loading}
                pagination={true}
                style={{ width: '100%', marginTop: '20px' }}
            />

            {/* Modal for Viewing Details */}
            <Modal
                title="Complaint Details"
                visible={!!selectedComplaint}
                onCancel={handleModalClose}
                footer={[
                    <Button key="close" onClick={handleModalClose}>
                        Close
                    </Button>,
                    <Button key="save" type="primary" onClick={() => handleSaveChanges(selectedComplaint)}>
                        Save Changes
                    </Button>, // Add a button to save changes
                ]}
            >
                {selectedComplaint && (
                    <div style={{ padding: '20px', borderRadius: '8px', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)', backgroundColor: '#fff' }}>
                        <h2 style={{ marginBottom: '20px' }}>Complaint Details</h2>

                        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))', gap: '15px' }}>
                            {/* Driver Name */}
                            <div>
                                <strong>Driver Name:</strong>
                                <Input
                                    value={selectedComplaint.driverName}
                                    onChange={(e) => setSelectedComplaint({ ...selectedComplaint, driverName: e.target.value })}
                                    style={{ marginTop: '5px', borderRadius: '4px', boxShadow: 'inset 0 1px 3px rgba(0, 0, 0, 0.2)' }}
                                />
                            </div>

                            {/* Car Number */}
                            <div>
                                <strong>Car Number:</strong>
                                <Input
                                    value={selectedComplaint.carNumber}
                                    onChange={(e) => setSelectedComplaint({ ...selectedComplaint, carNumber: e.target.value })}
                                    style={{ marginTop: '5px', borderRadius: '4px', boxShadow: 'inset 0 1px 3px rgba(0, 0, 0, 0.2)' }}
                                />
                            </div>

                            {/* Meter ID */}
                            <div>
                                <strong>Meter ID:</strong>
                                <Input
                                    value={selectedComplaint.meterId}
                                    onChange={(e) => setSelectedComplaint({ ...selectedComplaint, meterId: e.target.value })}
                                    style={{ marginTop: '5px', borderRadius: '4px', boxShadow: 'inset 0 1px 3px rgba(0, 0, 0, 0.2)' }}
                                />
                            </div>

                            {/* Other Information */}
                            <div>
                                <strong>Complaint Id:</strong> {selectedComplaint.complaintId}
                            </div>
                            <div>
                                <strong>Date of Travel:</strong> {new Date(selectedComplaint.dateOfTravel.seconds * 1000).toLocaleString()}
                            </div>
                            <div>
                                <strong>Pick Up Suburb:</strong> {selectedComplaint.pickUpLocation}
                            </div>
                            <div>
                                <strong>Drop Off Suburb:</strong> {selectedComplaint.dropOffLocation}
                            </div>
                            <div>
                                <strong>Status:</strong> {selectedComplaint.status}
                            </div>
                            <div>
                                <strong>Additional Comments:</strong> {selectedComplaint.additionalComments}
                            </div>
                            <div>
                                <strong>Email:</strong> {selectedComplaint.email}
                            </div>
                            <div>
                                <strong>Phone:</strong> {selectedComplaint.phone}
                            </div>
                            <div>
                                <strong>Query Regarding:</strong> {selectedComplaint.queryRegarding}
                            </div>
                            <div>
                                <strong>Receipt Type:</strong> {selectedComplaint.receiptType}
                            </div>
                            <div>
                                <strong>Exact Amount Charged:</strong> {selectedComplaint.exactAmountCharged}
                            </div>
                        </div>

                        {/* File Upload Section */}
                        <div style={{ marginTop: '20px' }}>
                            <strong>Files:</strong>
                            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
                                {selectedComplaint.files1 && selectedComplaint.files1.map((file, index) => (
                                    <div key={index} style={{ marginBottom: '5px' }}>
                                        <img src={file} alt={`File ${index + 1}`} style={{ width: '100px', height: 'auto', marginRight: '10px' }} />
                                        <a href={file} target="_blank" rel="noopener noreferrer" style={{ marginLeft: '10px', color: '#1890ff' }}>View File</a>
                                    </div>
                                ))}
                                {selectedComplaint.files2 && selectedComplaint.files2.map((file, index) => (
                                    <div key={index} style={{ marginBottom: '5px' }}>
                                        <img src={file} alt={`File ${index + 1}`} style={{ width: '100px', height: 'auto', marginRight: '10px' }} />
                                        <a href={file} target="_blank" rel="noopener noreferrer" style={{ marginLeft: '10px', color: '#1890ff' }}>View File</a>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {/* Add Note Section */}
                        <div style={{ marginTop: '30px' }}>
                            <h4>Add Note</h4>
                            <textarea
                                rows="4"
                                value={noteInput}
                                onChange={(e) => setNoteInput(e.target.value)}
                                placeholder="Add your note here..."
                                style={{ width: '100%', marginBottom: '10px', borderRadius: '4px', padding: '8px', border: '1px solid #d9d9d9' }}
                            />
                            <Button type="primary" onClick={() => handleAddNote(selectedComplaint.key, noteInput)} style={{ marginBottom: '10px' }}>Add Note</Button>
                            <h5>Notes:</h5>
                            <ul style={{ listStyleType: 'none', paddingLeft: '0' }}>
                                {notes[selectedComplaint.key] && notes[selectedComplaint.key].map((note, index) => (
                                    <li key={index} style={{ marginBottom: '10px', padding: '10px', border: '1px solid #f0f0f0', borderRadius: '4px' }}>
                                        <strong>{note.date.toLocaleString()}:</strong> {note.text}
                                        <Button type="link" onClick={() => handleDeleteNote(selectedComplaint.key, index)} style={{ marginLeft: '10px' }}>
                                            Delete
                                        </Button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                )}

            </Modal>
            <Modal
                title="Send Email"
                visible={sendMailModalVisible}
                onCancel={() => setSendMailModalVisible(false)}
                footer={[
                    <Button key="cancel" onClick={() => setSendMailModalVisible(false)}>
                        Cancel
                    </Button>,
                    <Button key="send" type="primary" onClick={handleSendEmail}>
                        Send
                    </Button>,
                ]}
            >
                <textarea
                    rows="4"
                    value={emailContent}
                    onChange={(e) => setEmailContent(e.target.value)}
                    placeholder="Type your message here..."
                    style={{ width: '100%', marginBottom: '10px' }}
                />
            </Modal>
        </div>
    );
};

export default Complaints;