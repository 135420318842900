import React, { useEffect, useState } from 'react';
import { Typography, Form, Input, Button, message, Space, Popconfirm, Table, Modal, Select, TimePicker } from 'antd';
import firebase, { firestore } from 'firebase';
import { refs } from '../firebase/firebase';
import moment from 'moment';

const { Title } = Typography;
const { Option } = Select;

export const PackagesAssign = () => {
  const [addPackageForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [packagesData, setPackagesData] = useState([]);
  const [oldDataPkg, setOldDataPkg] = useState([]);
  const [editingPackage, setEditingPackage] = useState(null);
  const [editPackageModalVisible, setEditPackageModalVisible] = useState(false);
  const [editPackageForm] = Form.useForm();
  const [addedPackages, setAddedPackages] = useState([]);

  useEffect(() => {
    fetchDataPackages();
    fetchDataOldPackages();
  }, []);

  const fetchDataPackages = async () => {
    try {
      setLoading(true);
      const querySnapshot = await refs.tariffData.limit(300).get();
      const newArray = querySnapshot.docs.map((documentSnapshot) => ({
        id: documentSnapshot.id,
        ...documentSnapshot.data(),
      }));
      setPackagesData(newArray);
    } catch (err) {
      console.error('Error fetching toll data:', err);
      message.error('Failed to fetch toll data. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const fetchDataOldPackages = async () => {
    try {
      setLoading(true);
      const querySnapshot = await refs.packages.limit(300).get();
      const newArray = querySnapshot.docs.map((documentSnapshot) => ({
        id: documentSnapshot.id,
        ...documentSnapshot.data(),
      }));
      setOldDataPkg(newArray);
    } catch (err) {
      console.error('Error fetching toll data:', err);
      message.error('Failed to fetch toll data. Please try again.');
    } finally {
      setLoading(false);
    }
  };


  const onFinishPackages = async (values) => {
    try {
      const { day, startTime, endTime } = values.tariffDetails[0];
      
      // Convert to minutes since midnight for easier comparison
      const startMinutes = startTime.hour() * 60 + startTime.minute();
      const endMinutes = endTime.hour() * 60 + endTime.minute();
  
      console.log(`Attempting to add new tariff: ${day} ${startMinutes}-${endMinutes}`);
  
      // Validate that end time is after start time
      if (endMinutes <= startMinutes) {
        message.error('End time must be after start time.');
        return;
      }
  
      // Fetch ALL existing packages
      const packagesCollection = await firestore()
        .collection('tariffData')
        .get();
  
      console.log(`Found ${packagesCollection.docs.length} total packages in the database`);
  
      // Filter and check for conflicts
      let conflict = false;
      packagesCollection.docs.forEach(doc => {
        const data = doc.data();
        data.tariffDetails.forEach(detail => {
          if (detail.day === day) {
            const existingStartMinutes = detail.startMinutes;
            const existingEndMinutes = detail.endMinutes;
            
            console.log(`Existing slot for ${day}: ${existingStartMinutes}-${existingEndMinutes}`);
            
            // Check for any overlap
            if (
              (startMinutes < existingEndMinutes && endMinutes > existingStartMinutes) ||
              (startMinutes === existingStartMinutes && endMinutes === existingEndMinutes) ||
              (existingStartMinutes === 0 && existingEndMinutes === 1439) || // Full day tariff
              (startMinutes === 0 && endMinutes === 1439) // New tariff is full day
            ) {
              console.log(`Conflict detected with slot ${existingStartMinutes}-${existingEndMinutes}`);
              conflict = true;
            }
          }
        });
      });
  
      if (conflict) {
        console.log('Conflict detected. Preventing addition of new tariff.');
        message.error('A tariff with overlapping time already exists for the selected day.');
        return; // Prevent adding the package
      }
  
      console.log('No conflict detected. Proceeding to add new tariff.');
  
      // No conflict: add the new tariff package
      const createdAt = firebase.firestore.Timestamp.now();
  
      const formattedTariffDetails = [{
        day,
        startTime: firebase.firestore.Timestamp.fromDate(startTime.toDate()),
        endTime: firebase.firestore.Timestamp.fromDate(endTime.toDate()),
        startMinutes,
        endMinutes,
        packages: addedPackages // Store selected packages
      }];
  
      await firestore().collection('tariffData').add({
        tariffDetails: formattedTariffDetails,
        createdAt,
      });
  
      console.log('New tariff added successfully.');
      message.success('Package created successfully!');
      addPackageForm.resetFields();
      fetchDataPackages();
    } catch (error) {
      console.error('Error creating package:', error.message);
      message.error('Failed to create package. Please try again.');
    }
  };


  const handleEditPackages = (record) => {
    setEditingPackage(record);
    setEditPackageModalVisible(true);
  };

  const handleDeletePackages = async (id) => {
    try {
      await refs.tariffData.doc(id).delete();
      message.success('Package deleted successfully!');
      fetchDataPackages();
    } catch (error) {
      console.error('Error deleting package:', error.message);
      message.error('Failed to delete package. Please try again.');
    }
  };

  const handleEditPackagesCancel = () => {
    setEditPackageModalVisible(false);
    editPackageForm.resetFields();
  };

  const handleEditPackagesFinish = async (values) => {
    try {
      await refs.tariffData.doc(editingPackage.id).update(values);
      message.success('Package updated successfully!');
      fetchDataPackages();
    } catch (error) {
      console.error('Error updating package:', error.message);
      message.error('Failed to update package. Please try again.');
    } finally {
      editPackageForm.resetFields();
      setEditPackageModalVisible(false);
    }
  };

  const dayOrder = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  const sortByDay = (a, b) => {
    const daysA = a.tariffDetails[0]?.days || [];
    const daysB = b.tariffDetails[0]?.days || [];
    return dayOrder.indexOf(daysA[0]) - dayOrder.indexOf(daysB[0]);
  };

  const columnsPackages = [
    {
      title: 'Day',
      dataIndex: ['tariffDetails', '0', 'day'], // Corrected to access the day field directly
      key: 'day',
      sorter: sortByDay,
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Time Range',
      key: 'timeRange',
      render: (text, record) => {
        const detail = record.tariffDetails[0];
        if (detail && detail.startTime && detail.endTime) {
          const start = moment(detail.startTime.toDate()).format('HH:mm');
          const end = moment(detail.endTime.toDate()).format('HH:mm');
          return `${start} - ${end}`;
        }
        return 'N/A';
      },
    },
    {
      title: 'Package Names',
      key: 'packageNames',
      render: (text, record) => {
        const packages = record.tariffDetails[0]?.packages || [];
        return packages.map(pkg => pkg.packageName).join(', ') || 'N/A';
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Space size="middle">
          <Popconfirm
            title="Are you sure to delete this package?"
            onConfirm={() => handleDeletePackages(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <a>Delete</a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const handleSelectChange = (selectedIndexes) => {
    const selectedPackages = selectedIndexes.map(index => oldDataPkg[index]);
    setAddedPackages(selectedPackages);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div className="header">
        <Title style={{ color: '#fff' }} level={2}>Packages Page</Title>
      </div>
      <div style={{ padding: '16px', background: 'white', borderRadius: '8px', marginBottom: '16px', width: '100%' }}>
        <h3>Create Package</h3>
        <Form
          form={addPackageForm}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={onFinishPackages}
          autoComplete="off"
        >
          <Form.Item label="Tariff Details" name="tariffDetails">
            <Form.List name="tariffDetails">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                      <Form.Item
                        {...restField}
                        name={[name, 'day']}
                        fieldKey={[fieldKey, 'day']}
                        rules={[{ required: true, message: 'Day is required' }]}
                      >
                        <Select placeholder="Select day">
                          <Option value="Monday">Monday</Option>
                          <Option value="Tuesday">Tuesday</Option>
                          <Option value="Wednesday">Wednesday</Option>
                          <Option value="Thursday">Thursday</Option>
                          <Option value="Friday">Friday</Option>
                          <Option value="Saturday">Saturday</Option>
                          <Option value="Sunday">Sunday</Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'startTime']}
                        fieldKey={[fieldKey, 'startTime']}
                        rules={[{ required: true, message: 'Start time is required' }]}
                      >
                        <TimePicker format="HH:mm" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'endTime']}
                        fieldKey={[fieldKey, 'endTime']}
                        rules={[{ required: true, message: 'End time is required' }]}
                      >
                        <TimePicker format="HH:mm" />
                      </Form.Item>
                      <Form.Item
                        style={{ width: 130 }}
                        {...restField}
                        name={[name, 'tariff']}
                        fieldKey={[fieldKey, 'tariff']}
                        rules={[{ required: true, message: 'Tariff is required' }]}
                      >
                        <Select
                          mode="multiple"
                          placeholder="Select tariffs"
                          onChange={(selectedIndexes) => handleSelectChange(selectedIndexes)}
                        >
                          {oldDataPkg.map((pkg, index) => (
                            <Select.Option key={pkg.id} value={index}>{pkg.packageName}</Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Button onClick={() => remove(name)}>Remove</Button>
                    </Space>
                  ))}
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block>
                      Add Tariff
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">Create Package</Button>
          </Form.Item>
        </Form>
        <Table
          dataSource={packagesData}
          columns={columnsPackages}
          loading={loading}
          rowKey="id"
          defaultSortOrder="ascend"
          sortDirections={['ascend']}
        />
      </div>
      <Modal
        title="Edit Package"
        visible={editPackageModalVisible}
        onCancel={handleEditPackagesCancel}
        footer={null}
      >
        <Form
          key={editingPackage ? editingPackage.id : 'newPackageForm'}
          form={editPackageForm}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={handleEditPackagesFinish}
          initialValues={editingPackage}
        >
          <Form.Item label="Tariff Details" name="tariffDetails">
            <Form.List name="tariffDetails">
              {(fields, { add, remove }) => (
                <>
                  {fields?.map(({ key, name, fieldKey, ...restField }) => (
                    <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                      <Form.Item
                        {...restField}
                        name={[name, 'days']}
                        fieldKey={[fieldKey, 'days']}
                        rules={[{ required: true, message: 'Days are required' }]}
                      >
                        <Select mode="multiple" placeholder="Select days">
                          <Option value="Monday">Monday</Option>
                          <Option value="Tuesday">Tuesday</Option>
                          <Option value="Wednesday">Wednesday</Option>
                          <Option value="Thursday">Thursday</Option>
                          <Option value="Friday">Friday</Option>
                          <Option value="Saturday">Saturday</Option>
                          <Option value="Sunday">Sunday</Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'startTime']}
                        fieldKey={[fieldKey, 'startTime']}
                        rules={[{ required: true, message: 'Start time is required' }]}
                      >
                        <TimePicker format="HH:mm" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'endTime']}
                        fieldKey={[fieldKey, 'endTime']}
                        rules={[{ required: true, message: 'End time is required' }]}
                      >
                        <TimePicker format="HH:mm" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'tariff']}
                        fieldKey={[fieldKey, 'tariff']}
                        rules={[{ required: true, message: 'Tariff is required' }]}
                      >
                        <Select
                          mode="multiple"
                          placeholder="Select tariffs"
                          onChange={(selectedIndexes) => handleSelectChange(selectedIndexes)}
                        >
                          {oldDataPkg.map((pkg, index) => (
                            <Select.Option key={pkg.id} value={index}>{pkg.packageName}</Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Button onClick={() => remove(name)}>Remove</Button>
                    </Space>
                  ))}
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block>
                      Add Tariff
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">Update Package</Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};