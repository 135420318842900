import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Form, Input, Button, Typography, message, Table, Popconfirm, Switch, Modal, Badge, Select, Tag, TimePicker, Checkbox } from 'antd';
import { auth } from 'firebase/app';
import 'firebase/auth';
import { refs } from '../firebase/firebase';
import { SearchOutlined, LeftOutlined } from '@ant-design/icons';
import 'firebase/firestore';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import { functions } from 'firebase/app';
import { Link } from 'react-router-dom';
import { debounce } from 'lodash';
import moment from 'moment';
import { CSVLink } from "react-csv";
import axios from 'axios';
import { isDev } from '../utils';



const { Title } = Typography;


const Users = () => {
    const formRef = useRef();

    const [userData, setUserData] = useState([]);
    const [loadingStates, setLoadingStates] = useState({});

    // console.log('userData: ', userData);

    const [packagesData, setPackagesData] = useState([]);
    const [addedPackages, setAddedPackages] = useState([
        {
            id: "egmoY2lddsrUodnYNQ3J",
            packageName: "Tarrif 3",
            pricePerkm: "2.73",
            start: "7.42",
            waitingTime: "0.95",
        },
        {
            id: "2VjgnYvwd9Icsq6ONxMj",
            packageName: "Tarrif 4",
            pricePerkm: "3.50",
            start: "6.72",
            waitingTime: "1.41",
        },
    ]);


    const [loading, setLoading] = useState(false);
    const [editUser, setEditUser] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const [showPackage, setShowPackage] = useState(false);
    const [selectedDriverLocation, setSelectedDriverLocation] = useState(null);


    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();

        setUserData((prevData) => {
            const filteredData = prevData.filter((item) => {
                const value = item[dataIndex] ? item[dataIndex].toString().toLowerCase() : '';
                return value.includes((selectedKeys && selectedKeys[0]) ? selectedKeys[0].toLowerCase() : '');
            });
            return filteredData;
        });
    };

    const prepareDataForDownload = () => {
        return userData.map(user => ({
            'Driver Name': user.name,
            'Driver ID': user.driverId
        }));
    };


    const handleReset = (clearFilters) => {
        clearFilters();
        fetchUsers();
    };


    const showModal = (record) => {
        formRef.current.setFieldsValue({
            name: record.name,
        });
        setEditUser(record);
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        formRef.current.resetFields();
        setEditUser(null);
        setIsModalVisible(false);
    };


    const onFinish = async (values) => {
        try {
            const { name, driverId, pin } = values;
            // Append "@driverappauspak.com" to the entered Driver ID
            const email = `${driverId}@driverappauspak.com`;

            if (editUser) {
                // If there's an editUser, update the existing user
                handleUpdateUser(values);
            } else {
                // Create a new user in Firebase Authentication
                const userCredential = await auth().createUserWithEmailAndPassword(email, pin);

                // Extract user UID from the userCredential
                const userId = userCredential.user.uid;

                // Add user data to the Firestore collection
                await refs.usersData.doc(userId).set({
                    name: name,
                    driverId: driverId,
                    userId: userId,
                    disabled: false, // Initially not disabled
                    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                });

                message.success(`User ${name} successfully registered!`);
                fetchUsers()
                // Clear all fields after a successful submission
                formRef.current.resetFields();
            }
        } catch (error) {
            console.error('Error during user registration:', error.message);
            message.error(error.message);
        }
    };


    useEffect(() => {
        fetchUsers();
        fetchDataPackages();
    }, []); // Fetch data on component mount

    const fetchUsers = async () => {
        try {
            setLoading(true);

            const usersSnapshot = await refs.usersData.orderBy('createdAt', 'desc').get();
            console.log('Users Snapshot:', usersSnapshot);

            const usersData = usersSnapshot.docs.map((doc) => {
                const data = doc.data();
                return {
                    id: doc.id,
                    ...data,
                    packageData: data.packageData || [],
                    totalLoginTime: data.totalLoginTime || 0,
                };
            });
            console.log('Users Data:', usersData);

            setUserData(usersData);
        } catch (error) {
            console.error('Error fetching users:', error);
            message.error('Failed to fetch users. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const fetchDataPackages = async () => {
        try {
            setLoading(true);

            const querySnapshot = await refs.packages.limit(300).get();

            const newArray = querySnapshot.docs.map((documentSnapshot) => ({
                id: documentSnapshot.id,
                ...documentSnapshot.data(),
            }));

            setPackagesData(newArray);
            console.log(newArray, "newArraysetPackagesData");
        } catch (err) {
            console.error('Error fetching toll data:', err);
            message.error('Failed to fetch toll data. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const calculateRemainingTime = (totalLoginTime) => {
        const now = moment();
        const totalMinutes = Math.max(0, totalLoginTime); // Ensure totalMinutes is non-negative
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        return { hours, minutes };
    };


    const handleUpdateUser = async (values) => {
        try {
            const { name } = values;

            // ... (your existing code)

            await refs.usersData.doc(editUser.id).update({
                name: name,
            });

            message.success('User updated successfully!');
            formRef.current.resetFields();
            setEditUser(null); // Reset the edit user state
            setIsModalVisible(false);
            fetchUsers(); // Refresh data after update
        } catch (error) {
            console.error('Error during user update:', error.message);
            message.error(error.message);
        }
    };


    const handleCancelEdit = () => {
        formRef.current.resetFields();
        setEditUser(null);
    };

    const debouncedSave = useCallback(
        debounce((userId, packageData) => {
            refs.usersData.doc(userId).update({ packageData })
                .then(() => console.log('Package data saved to database'))
                .catch(error => console.error('Error saving package data:', error));
        }, 1000),
        []
    );

    // Function to handle switch toggle
    const handleToggleSwitch = async (userId, checked, name) => {
        console.log("===================>>>>>>", userId);
        
        try {
            // Fetch the user's ride data
            const ridesSnapshot = await refs.ridesData
                .where("uuid", "==", userId)  // Assuming 'uuid' is correct for userId
                .orderBy("createdAt", "desc")
                .limit(1)
                .get();
            
            const lastRide = ridesSnapshot.docs[0]?.data(); // Get the last ride if it exists
    
            // Check if the last ride exists and its status
            if (lastRide && lastRide.status === "started" && checked) {
                // If trying to disable (checked === true) and the last ride is "started", block it
                message.error('User cannot be disabled as their last ride is still "started".');
                return; // Exit if last ride is still in progress
            }
    
            // Proceed with the toggle if no active ride or last ride is "ended"
            await refs.usersData.doc(userId).update({
                disabled: checked,
            });
    
            // Update local state to reflect changes
            const updatedUsers = userData.map(user => {
                if (user.id === userId) {
                    return { ...user, disabled: checked };
                }
                return user;
            });
            setUserData(updatedUsers);
    
            message.success(`User ${name} ${checked ? 'disabled' : 'enabled'} successfully!`);
        } catch (error) {
            console.error('Error toggling switch:', error.message);
            message.error('Failed to toggle switch. Please try again.');
        }
    };
    
    
    // const handleToggleSwitchResetApp = async (userId, checked, name) => {
    //     try {
    //         // Update the disabled state in Firestore
    //         await refs.usersData.doc(userId).update({
    //             isResetApp: checked,
    //         });

    //         // Update local state to reflect changes
    //         const updatedUsers = userData.map(user => {
    //             if (user.id === userId) {
    //                 return { ...user, isResetApp: checked };
    //             }
    //             return user;
    //         });
    //         setUserData(updatedUsers);

    //         message.success(`User ${name} ${checked ? 'disabled' : 'enabled'} successfully!`);
    //     } catch (error) {
    //         console.error('Error toggling switch:', error.message);
    //         message.error('Failed to toggle switch. Please try again.');
    //     }
    // };


    // const handleTogglePackageSwitch = async (userId, checked, name) => {
    //     try {
    //         // Update the "showPackage" status in Firestore
    //         await refs.usersData.doc(userId).update({
    //             showPackage: checked,
    //         });

    //         // Update the local state to reflect this change
    //         const updatedUsers = userData.map(user => {
    //             if (user.id === userId) {
    //                 return { ...user, showPackage: checked };
    //             }
    //             return user;
    //         });
    //         setUserData(updatedUsers);

    //         message.success(`Package visibility for ${name} has been ${checked ? 'hidden' : 'shown'} successfully!`);
    //     } catch (error) {
    //         console.error('Error toggling package switch:', error.message);
    //         message.error('Failed to toggle package visibility. Please try again.');
    //     }
    // };



    // const handleTogglePackageMode = async (userId, checked, name) => {
    //     try {
    //         // Update the user data in Firestore with the new "packageMode" value
    //         await refs.usersData.doc(userId).update({
    //             packageMode: checked,
    //         });

    //         // Locally update the userData to reflect this change
    //         const updatedUsers = userData.map(user => {
    //             if (user.id === userId) {
    //                 return { ...user, packageMode: checked };
    //             }
    //             return user;
    //         });
    //         setUserData(updatedUsers);

    //         message.success(`Package mode for ${name} set to ${checked ? 'Shown' : 'Hidden'} successfully!`);
    //     } catch (error) {
    //         console.error('Error toggling package mode:', error.message);
    //         message.error('Failed to toggle package mode. Please try again.');
    //     }
    // };


    const updateUserPackageData = (userId, updater) => {
        setUserData(prevUserData =>
            prevUserData.map(user =>
                user.id === userId
                    ? { ...user, packageData: updater(user.packageData || []) }
                    : user
            )
        );
    };



    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    const handleAddedPackages = useCallback(async (userId, packageData) => {
        try {
            await refs.usersData.doc(userId).update({
                packageData: packageData
            });
            console.log('Package data updated in Firestore:', packageData);
            message.success(`Package data updated successfully!`);
        } catch (error) {
            console.error('Error updating package data:', error);
            message.error('Failed to update package data. Please try again.');
        }
    }, []);


    const handleChange = (userId) => {
        const user = userData.find(u => u.id === userId);
        if (user) {
            handleAddedPackages(userId, user.packageData);
        }
    };


    const handlePackageChange = useCallback((selectedPackageArray, userId) => {
        setUserData(prevUserData => {
            const updatedUserData = prevUserData.map(user => {
                if (user.id === userId) {
                    const updatedPackages = selectedPackageArray.map(pkg => {
                        const existingPackage = user.packageData.find(p => p.id === pkg.id);
                        if (existingPackage) {
                            return existingPackage;
                        }
                        return {
                            ...pkg,
                            userId: userId,
                            days: daysOfWeek.reduce((acc, day) => {
                                acc[day] = { enabled: false, startTime: null, endTime: null };
                                return acc;
                            }, {})
                        };
                    });
                    return { ...user, packageData: updatedPackages };
                }
                return user;
            });

            const updatedUser = updatedUserData.find(u => u.id === userId);
            if (updatedUser) {
                handleAddedPackages(userId, updatedUser.packageData);
            }

            return updatedUserData;
        });
    }, [handleAddedPackages]);

    const handleTimeChange = useCallback((userId, packageId, day, timeType, time) => {
        setUserData(prevUserData => {
            const newUserData = prevUserData.map(user => {
                if (user.id === userId) {
                    const updatedPackageData = user.packageData.map(pkg => {
                        if (pkg.id === packageId) {
                            const formattedTime = time ? time.format('HH:mm:ss') : null;
                            console.log(`Updating ${timeType} for ${day} to ${formattedTime}`);
                            return {
                                ...pkg,
                                days: {
                                    ...pkg.days,
                                    [day]: {
                                        ...pkg.days[day],
                                        [timeType]: formattedTime,
                                        enabled: true
                                    }
                                }
                            };
                        }
                        return pkg;
                    });

                    // Schedule a save to the database
                    refs.usersData.doc(userId).update({ packageData: updatedPackageData })
                        .then(() => console.log('Package data saved to database'))
                        .catch(error => console.error('Error saving package data:', error));

                    return { ...user, packageData: updatedPackageData };
                }
                return user;
            });

            console.log('Updated userData:', newUserData);
            return newUserData;
        });
    }, []);

    const handleDayChange = (userId, packageId, day, checked) => {
        setUserData(prevUserData => {
            const newUserData = prevUserData.map(user => {
                if (user.id === userId) {
                    const updatedPackageData = user.packageData.map(pkg => {
                        if (pkg.id === packageId) {
                            return {
                                ...pkg,
                                days: {
                                    ...pkg.days,
                                    [day]: {
                                        ...pkg.days[day],
                                        enabled: checked
                                    }
                                }
                            };
                        }
                        return pkg;
                    });
                    return { ...user, packageData: updatedPackageData };
                }
                return user;
            });

            const updatedUser = newUserData.find(u => u.id === userId);
            if (updatedUser) {
                handleAddedPackages(userId, updatedUser.packageData);
            }

            return newUserData;
        });
    };


    const toggleIsRest = useCallback(async (record) => {
        setLoadingStates(prev => ({ ...prev, [record.id]: true }));
        try {
            const adminResetDriverSession = functions().httpsCallable('adminDriverEndRest');
            const adminEndDriverSession = functions().httpsCallable('adminDriverEndShift');

            if (record.isRest) {
                await adminResetDriverSession({ uid: record.id });
                message.success(`Driver session ended for ${record.name}.`);
            } else {
                await adminEndDriverSession({ uid: record.id });
                message.success(`Driver session reset for ${record.name}.`);
            }

            // Update the local state to reflect the change
            setUserData(prevData =>
                prevData.map(user =>
                    user.id === record.id ? { ...user, isRest: !user.isRest } : user
                )
            );
        } catch (error) {
            message.error('Error toggling driver session: ' + error.message);
        } finally {
            setLoadingStates(prev => ({ ...prev, [record.id]: false }));
        }
    }, []);


    // const toggleIsRest = useCallback(async (record) => {
    //     setLoadingStates(prev => ({ ...prev, [record.id]: true }));

    //     try {

    //         if (record.isRest) {
    //             await fetch('https://us-central1-harbourcabs-dev.cloudfunctions.net/driverEndRest', {
    //                 body: { uid: record.id },
    //                 method: 'POST',
    //                 headers: {
    //                     'content-type': 'application/json',
    //                     "x-gcp-functions-api-key": "13ffcd23-4beb-4b62-a6ac-1bd3957b7fa6",
    //                 }
    //             })
    //             message.success(`Driver session ended for ${record.name}.`);
    //         } else {
    //             await fetch('https://us-central1-harbourcabs-dev.cloudfunctions.net/driverEndShift', {
    //                 body: { uid: record.id },
    //                 method: 'POST',
    //                 headers: {
    //                     'content-type': 'application/json',
    //                     "x-gcp-functions-api-key": "13ffcd23-4beb-4b62-a6ac-1bd3957b7fa6",
    //                 }
    //             })
    //             message.success(`Driver session reset for ${record.name}.`);
    //         }

    //         // Update the local state to reflect the change
    //         setUserData(prevData =>
    //             prevData.map(user =>
    //                 user.id === record.id ? { ...user, isRest: !user.isRest } : user
    //             )
    //         );
    //     } catch (error) {
    //         message.error('Error toggling driver session: ' + error.message);
    //     } finally {
    //         setLoadingStates(prev => ({ ...prev, [record.id]: false }));
    //     }
    // }, []);



    const formatDuration = (seconds, lastLogin) => {
        let offset = 0;
        if (lastLogin) {
            offset = Date.now() / 1000 - lastLogin.seconds
        }
        const duration = moment.duration(seconds + offset, 'seconds');
        const hours = Math.floor(duration.asHours());
        const minutes = duration.minutes();
        const secs = duration.seconds();
        return `${hours}h ${minutes}m ${secs}s`;
    };

    const restDuration = (ts) => {


        // 8 prod   now changed to 5
        // 0.05 dev
        const delta = ( isDev() ? 6 : 6) * 3600 - (ts.toDate() - new Date()).valueOf() / 1000

        const duration = moment.duration(delta, 'seconds');
        const hours = Math.floor(duration.asHours());
        const minutes = duration.minutes();
        const secs = duration.seconds();
        return `${hours}h ${minutes}m ${secs}s`;
    };



    useEffect(() => {
        console.log('userData updated:', userData);
    }, [userData]);

    const columns = useMemo(() => [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                record ? record.name : '-'
            ),
            // ... (existing settings)
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        placeholder="Search Name"
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, 'name')}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, 'name')}
                        icon="search"
                        size="small"
                        style={{ width: 90 }}
                    >

                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </div>
            ),
            filterIcon: (filtered) => (
                <SearchOutlined style={{ fontSize: '22px', color: filtered ? '#1890ff' : undefined }} />
            ),
        },
        // {
        //     title: 'Total Login Time',
        //     dataIndex: 'totalLoginTime',
        //     key: 'totalLoginTime',
        //     render: (text) => {
        //         const { hours, minutes } = calculateRemainingTime(text);
        //         return `${hours}h ${minutes}m`;
        //     },
        // },
        // {
        //     title: 'Remaining Login Time',
        //     dataIndex: 'totalLoginTime',
        //     key: 'remainingLoginTime',
        //     render: (text) => {
        //         const { hours, minutes } = calculateRemainingTime(text);
        //         return `${hours}h ${minutes}m`;
        //     },
        // },
        {
            title: 'Driver ID',
            dataIndex: 'driverId',
            key: 'driverId',
            // ... (existing settings)
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        placeholder="Search Driver ID"
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, 'driverId')}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, 'driverId')}
                        icon="search"
                        size="small"
                        style={{ width: 90 }}
                    >

                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </div>
            ),
            filterIcon: (filtered) => (
                <SearchOutlined style={{ fontSize: '22px', color: filtered ? '#1890ff' : undefined }} />
            ),
        },
        {
            title: 'Car Number',
            dataIndex: 'carNumber',
            key: 'carNumber',
            render: (text, record) => (record.carData ? record.carData.carNumber : 'N/A'),
        },
        {
            title: 'App Version',
            key: 'appVersion',
            render: (text, record) => (
                record.appVersion ?   record?.appVersion : '-'
            )
        },
        {
            title: 'Disabled',
            dataIndex: 'disabled',
            key: 'disabled',
            render: (text, record) => (
                <Switch
                    checked={record.disabled}
                    onChange={(checked) => handleToggleSwitch(record.id, checked, record.name)}
                />
            ),
        },
        // {
        //     title: 'Reset App',
        //     dataIndex: 'resetApp',
        //     key: 'resetApp',
        //     render: (text, record) => (
        //         <Switch
        //             checked={record.isResetApp}
        //             onChange={(checked) => handleToggleSwitchResetApp(record.id, checked, record.name)}
        //         />
        //     ),
        // },
        // {
        //     title: 'Hide Packages',
        //     dataIndex: 'showPackage',
        //     key: 'showPackage',
        //     render: (text, record) => (
        //         <Switch
        //             checked={record.showPackage}
        //             onChange={(checked) => handleTogglePackageSwitch(record.id, checked, record.name)}
        //         />
        //     ),
        // },
        // {
        //     title: 'Show Location',
        //     key: 'showLocation',
        //     render: (text, record) => (
        //         <Button type="link" onClick={() => handleShowLocation(record)}>
        //             Show Location
        //         </Button>
        //     ),
        // },
        // {

        //     title: 'Packages Maxi / Packages Sedan',
        //     key: 'packagesMode',
        //     dataIndex: 'packagesMode',
        //     render: (text, record) => (
        //         <Switch
        //             checked={record.packageMode}
        //             onChange={(checked) => handleTogglePackageMode(record.id, checked, record.name)}
        //         />
        //     ),
        // },
        {
            title: 'Is Rest',
            key: 'isRest',
            render: (text, record) => (
                <Switch
                    checked={record?.isRest ?? false}
                    onChange={() => toggleIsRest(record)}
                    loading={loadingStates[record.id]}
                    disabled={loadingStates[record.id]}
                />
            )
        },
        {
            title: 'Shift Duration (12 hrs)',
            key: 'shiftDuration',
            render: (text, record) => (
                record ? formatDuration(record.shiftDuration ?? 0, record?.lastLogin) : '-'
            )
        },
        {
            title: 'Rest Duration (8 hrs)',
            key: 'restDuration',
            render: (text, record) => (
                record && record.isRest && record.restEndTask?.runsAt ? restDuration(record.restEndTask.runsAt ?? 0) : '-'
            )
        },
        {
            title: 'View Sessions',
            key: 'viewSessions',
            render: (text, record) => (
                <Link to={`/UserSessions/${record.id}`}>
                    <Button type="primary">View Sessions</Button>
                </Link>
            ),
        },
        // {
        //     title: 'Add or Remove Packages',
        //     key: 'addRemovePackages',
        //     render: (text, record) => {
        //       const userPackages = record.packageData || [];

        //       return (
        //         <div>
        //           <Select
        //             mode="multiple"
        //             style={{ width: '100%', marginBottom: '10px' }}
        //             placeholder="Select packages"
        //             value={userPackages.map(pkg => pkg.packageName)}
        //             onChange={(selectedPackageArray) => handlePackageChange(
        //               selectedPackageArray.map(packageName =>
        //                 packagesData.find(pkg => pkg.packageName === packageName) ||
        //                 userPackages.find(pkg => pkg.packageName === packageName)
        //               ),
        //               record.id
        //             )}
        //           >
        //             {packagesData.map(pkg => (
        //               <Select.Option key={pkg.id} value={pkg.packageName}>{pkg.packageName}</Select.Option>
        //             ))}
        //           </Select>
        //           <Collapse>
        //             {userPackages.map(pkg => (
        //               <Collapse.Panel header={pkg.packageName} key={pkg.id}>
        //                 {daysOfWeek.map(day => (
        //                   <div key={day} style={{ marginBottom: '5px' }}>
        //                     <Checkbox
        //                       checked={pkg.days && pkg.days[day] ? pkg.days[day].enabled : false}
        //                       onChange={(e) => handleDayChange(record.id, pkg.id, day, e.target.checked)}
        //                       style={{ marginRight: '5px' }}
        //                     >
        //                       {day}
        //                     </Checkbox>
        //                     {pkg.days && pkg.days[day] && pkg.days[day].enabled && (
        //                       <>
        //                         <TimePicker
        //                           format="HH:mm"
        //                           value={pkg.days[day].startTime ? moment(pkg.days[day].startTime, 'HH:mm:ss') : null}
        //                           onChange={(time) => handleTimeChange(record.id, pkg.id, day, 'startTime', time)}
        //                           style={{ marginRight: '5px' }}
        //                         />
        //                         <TimePicker
        //                           format="HH:mm"
        //                           value={pkg.days[day].endTime ? moment(pkg.days[day].endTime, 'HH:mm:ss') : null}
        //                           onChange={(time) => handleTimeChange(record.id, pkg.id, day, 'endTime', time)}
        //                         />
        //                       </>
        //                     )}
        //                   </div>
        //                 ))}
        //               </Collapse.Panel>
        //             ))}
        //           </Collapse>
        //         </div>
        //       );
        //     },
        //   }
    ], [handleDayChange, handleTimeChange, handlePackageChange, toggleIsRest, loadingStates]);




    // const handleShowLocation = (driver) => {
    //     try {
    //         // Extract latitude and longitude from the driver's currentLocation
    //         const { latitude, longitude } = driver.currentLocation;

    //         // Open Google Maps in a new tab with the driver's location
    //         openGoogleMaps(latitude, longitude, driver.name);
    //     } catch (error) {
    //         console.error('Error fetching driver location:', error.message);
    //         message.error('Failed to fetch driver location. Please try again.');
    //     }
    // };

    const openGoogleMaps = (latitude, longitude, driverName) => {
        // Construct the Google Maps URL with the driver's location
        const googleMapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;

        // Open Google Maps in a new tab
        window.open(googleMapsUrl, '_blank');

        // You can also show a message with the driver's name if needed
        message.success(`Showing location for ${driverName}`);
    };

    // Function to fetch the driver's location from your backend API
    const fetchDriverLocation = async (driverId) => {
        // Modify this part to fetch the driver's location from your backend
        // You may need to make an API call to get the real-time location
        // For simplicity, let's assume the backend returns a location object
        return { latitude: 123.456, longitude: 789.012 };
    };



    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {/* Blue Header */}
            <div className="header">
                <Title style={{ color: '#fff' }} level={2}>
                    Drivers Page
                </Title>

            </div>

            {/* Form and Counter */}
            <div style={{ width: '80%', margin: '16px auto', display: 'flex' }}>
                {/* Form */}
                <div style={{
                    background: 'white',
                    padding: '16px',
                    width: '50%',
                    boxSizing: 'border-box',
                    justifyContent: 'center',
                    border: '1px solid #1890ff',
                    borderRadius: '8px',
                    display: 'flex',

                    alignItems: 'center',

                }}>
                    <div style={{
                        // width: '100%',
                        // textAlign: 'left',
                        // margin: 'auto',
                    }}>
                        {/* <Form
                            style={{

                            }}
                            labelCol={{ span: 3 }}
                            wrapperCol={{ span: 16 }}
                            onFinish={onFinish}
                            autoComplete="off"
                            ref={formRef}
                        >
                            <Form.Item
                                label="Name"
                                name="name"
                                rules={[{ required: true, message: 'Please enter your name' }]}
                            >
                                <Input placeholder="Enter your name" />
                            </Form.Item>

                            <Form.Item
                                label="Driver ID"
                                name="driverId"
                                rules={[{ required: true, message: 'Please enter your Driver ID' }]}
                            >
                                <Input placeholder="Enter your Driver ID" />
                            </Form.Item>

                            <Form.Item
                                label="PIN"
                                name="pin"
                                rules={[{ required: true, message: 'Please enter your PIN' }]}
                            >
                                <Input.Password placeholder="Enter your PIN" />
                            </Form.Item>

                           


                            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form> */}
                        <a href={isDev() ? "https://harbour-registration-dev.web.app/" :"https://driverregister-90814.web.app/"} target="_blank" rel="noopener noreferrer">
                            <Button type="primary">Register New Driver</Button>
                        </a>
                        <CSVLink
                            data={prepareDataForDownload()}
                            filename={"drivers-list.csv"}
                            className="ant-btn ant-btn-primary"
                            style={{ marginLeft: '20px' }}
                        >
                            Download Drivers List
                        </CSVLink>

                    </div>
                </div>

                {/* Counter */}
                <div
                    style={{
                        background: 'white',
                        padding: '16px',
                        width: '100%',
                        boxSizing: 'border-box',
                        border: '1px solid #1890ff',
                        borderRadius: '8px',

                    }}
                >
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <span style={{ fontSize: '28px', marginBottom: '8px' }}>Total Drivers</span>
                        <Badge count={userData.length} style={{ backgroundColor: '#1890ff', fontSize: '28px', padding: 30, display: 'flex', alignItems: 'center' }} />                    </div>
                </div>
            </div>

            {/* Table for Users Data */}
            <div style={{ padding: '16px', background: 'white', borderRadius: '8px', width: '80%', boxSizing: 'border-box', marginTop: '16px' }}>
                <h3>Drivers</h3>
                <Table dataSource={userData} columns={columns} loading={loading} />
            </div>

            {/* Modal */}
            <Modal
                title="Edit User"
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={() => formRef.current.submit()}>
                        Update
                    </Button>,
                ]}
            >
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    onFinish={onFinish}
                    autoComplete="off"
                    ref={formRef}
                >
                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[{ required: true, message: 'Please enter your name' }]}
                    >
                        <Input placeholder="Enter your name" />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};


export default Users;
