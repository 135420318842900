// Login.js (updated component)
import React, { useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import { useNavigate } from 'react-router-dom'; // Import useNavigate instead of useHistory
import firebase from 'firebase';

const Login = () => {
  const navigate = useNavigate(); // Use useNavigate instead of useHistory
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      // Authenticate user with Firebase
      await firebase.auth().signInWithEmailAndPassword(values.email, values.password);
      navigate('/home'); 
    } catch (error) {
      console.error('Login error:', error.message);
      message.error(error.message)
      setLoading(false);
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', background: '#f0f2f5' }}>
      <div style={{ maxWidth: 400, width: '100%', padding: 20, borderRadius: 8, boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', background: '#fff' }}>
        <div style={{ textAlign: 'center', marginBottom: 20 }}>
          <img src={require('../assets/logo.png')} alt="Logo" style={{ height: 80, width: 'auto' }} />
        </div>
        <Form onFinish={onFinish}>
          <Form.Item name="email" rules={[{ required: true, message: 'Please enter your email!' }]}>
            <Input placeholder="Email" />
          </Form.Item>
          <Form.Item name="password" rules={[{ required: true, message: 'Please enter your password!' }]}>
            <Input.Password placeholder="Password" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading} style={{ width: '100%' }}>
              Log In
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Login;
