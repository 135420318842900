import React, { useState, useEffect } from 'react';
import { Typography, Table, Modal, Button, Space, Tag, Row, Col, Divider, message, Input, Select, Form, Upload } from 'antd';
import { FileOutlined, MailOutlined, CalendarOutlined, PhoneOutlined, HomeOutlined, CarOutlined, SearchOutlined, EditOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import 'firebase/firestore';
import { refs } from '../firebase/firebase';
import axios from 'axios';
import TextArea from 'antd/es/input/TextArea';
import firebase from 'firebase';

const { Title, Text } = Typography;
const { Option } = Select;


const VehicleInfo = () => {
    const [vehicleData, setVehicleData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [searchText, setSearchText] = useState('');

    const [isModalMail, setIsModalMail] = useState(false);
    const [messagee, setMessagee] = useState('');
    const [userEmail, setUserEmail] = useState('')
    const [userName, setUserName] = useState('')

    const [editModalVisible, setEditModalVisible] = useState(false);
    const [editingVehicle, setEditingVehicle] = useState(null);
    const [form] = Form.useForm();

    const [expiryFilter, setExpiryFilter] = useState('all');

    useEffect(() => {
        const fetchVehicleData = async () => {
            try {
                const snapshot = await refs.vehicleInfo.orderBy('createdAt', 'desc').get();
                const data = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setVehicleData(data);
                setFilteredData(data);
            } catch (error) {
                console.error("Error fetching vehicle data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchVehicleData();
    }, []);

    const handleSearch = (value) => {
        setSearchText(value);
        filterData(value, expiryFilter);
    };

    const handleExpiryFilter = (value) => {
        setExpiryFilter(value);
        filterData(searchText, value);
    };

    const filterData = (searchValue, expiryValue) => {
        let filtered = vehicleData.filter(
            item =>
                item.ownerName.toLowerCase().includes(searchValue.toLowerCase()) ||
                item.email.toLowerCase().includes(searchValue.toLowerCase()) ||
                item.phone.includes(searchValue)
        );

        if (expiryValue !== 'all') {
            filtered = filtered.filter(item => {
                const today = moment();
                if (expiryValue === 'allValid') {
                    return ['vehicleInsurance', 'cameraCertificate', 'licensePhoto'].every(doc => {
                        const expiryDate = moment(item[`${doc}Expiry`].toDate());
                        return expiryDate.diff(today, 'days') > 30;
                    });
                } else if (expiryValue === 'anyExpiring') {
                    return ['vehicleInsurance', 'cameraCertificate', 'licensePhoto'].some(doc => {
                        const expiryDate = moment(item[`${doc}Expiry`].toDate());
                        return expiryDate.diff(today, 'days') <= 30;
                    });
                } else {
                    const expiryDate = moment(item[`${expiryValue}Expiry`].toDate());
                    return expiryDate.diff(today, 'days') <= 30;
                }
            });
        }

        setFilteredData(filtered);
    };

    const daysUntilExpiry = (date) => {
        const expiryDate = moment(date.toDate());
        const today = moment();
        return expiryDate.diff(today, 'days');
    };

    const showEditModal = (vehicle) => {
        setEditingVehicle(vehicle);
        form.setFieldsValue({
            ...vehicle,
            vehicleInsuranceExpiry: moment(vehicle.vehicleInsuranceExpiry.toDate()),
            cameraCertificateExpiry: moment(vehicle.cameraCertificateExpiry.toDate()),
            licensePhotoExpiry: moment(vehicle.licensePhotoExpiry.toDate()),
        });
        setEditModalVisible(true);
    };

    const handleEdit = async (values) => {
        try {
            const updatedData = {
                ...values,
                vehicleInsuranceExpiry: values.vehicleInsuranceExpiry.toDate(),
                cameraCertificateExpiry: values.cameraCertificateExpiry.toDate(),
                licensePhotoExpiry: values.licensePhotoExpiry.toDate(),
            };

            // Include image fields only if they have been updated
            ['registrationPaper', 'vehicleInsurance', 'cameraCertificate', 'licensePhoto', 'pinkSlip'].forEach(field => {
                if (values[field] && values[field] !== editingVehicle[field]) {
                    updatedData[field] = values[field];
                }
            });

            await refs.vehicleInfo.doc(editingVehicle.id).update(updatedData);
            message.success('Vehicle information updated successfully');
            setEditModalVisible(false);
            // Refresh data
            const snapshot = await refs.vehicleInfo.orderBy('createdAt', 'desc').get();
            const data = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setVehicleData(data);
            setFilteredData(data);
        } catch (error) {
            console.error("Error updating vehicle data:", error);
            message.error('Failed to update vehicle information');
        }
    };

    const handleDelete = async (vehicleId) => {
        try {
            await refs.vehicleInfo.doc(vehicleId).delete();
            message.success('Vehicle deleted successfully');
            // Refresh data
            const snapshot = await refs.vehicleInfo.orderBy('createdAt', 'desc').get();
            const data = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setVehicleData(data);
            setFilteredData(data);
        } catch (error) {
            console.error("Error deleting vehicle:", error);
            message.error('Failed to delete vehicle');
        }
    };

    const columns = [
        {
            title: 'Owner Name',
            dataIndex: 'ownerName',
            key: 'ownerName',
            render: (text) => <Text strong>{text}</Text>,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (text) => <Text copyable>{text}</Text>,
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            render: (text) => <Text copyable>{text}</Text>,
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (date) => (
                <Tag color="blue">
                    {moment(date.toDate()).format('YYYY-MM-DD HH:mm')}
                </Tag>
            ),
            sorter: (a, b) => a.createdAt.toDate() - b.createdAt.toDate(),
        },
        {
            title: 'Expiring Soon',
            key: 'expiringSoon',
            render: (_, record) => {
                const expiryItems = [];

                const checkExpiry = (date, label) => {
                    const days = daysUntilExpiry(date);
                    if (days <= 30 && days > 0) {
                        expiryItems.push(<Tag color="orange" key={label}>{`${label}: ${days} days`}</Tag>);
                    } else if (days <= 0) {
                        expiryItems.push(<Tag color="red" key={label}>{`${label}: Expired`}</Tag>);
                    }
                };

                checkExpiry(record.vehicleInsuranceExpiry, 'Insurance');
                checkExpiry(record.cameraCertificateExpiry, 'Camera Cert');
                checkExpiry(record.licensePhotoExpiry, 'License');

                return expiryItems.length > 0 ? expiryItems : <Tag color="green">All Valid</Tag>;
            },
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Button type="primary" onClick={() => showVehicleDetails(record)}>View Details</Button>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space>
                    {/* <Button type="primary" onClick={() => showVehicleDetails(record)}>View</Button> */}
                    <Button type="default" icon={<EditOutlined />} onClick={() => showEditModal(record)}>Edit</Button>
                    <Button type="danger" icon={<DeleteOutlined />} onClick={() => handleDelete(record.id)}>Delete</Button>
                </Space>
            ),
        },
    ];

    const showVehicleDetails = (vehicle) => {
        setSelectedVehicle(vehicle);
        setModalVisible(true);
    };

    const renderExpiryDate = (date) => {
        const expiryDate = moment(date.toDate());
        const today = moment();
        const daysUntilExpiry = expiryDate.diff(today, 'days');

        let color = 'green';
        if (daysUntilExpiry <= 30) color = 'orange';
        if (daysUntilExpiry <= 0) color = 'red';

        return (
            <Tag color={color}>
                {expiryDate.format('YYYY-MM-DD')} ({daysUntilExpiry} days)
            </Tag>
        );
    };

    const handleImageUpload = async (file, fieldName) => {
        try {
            // Implement your image upload logic here
            // For example, using Firebase Storage:
            const storageRef = firebase.storage().ref();
            const fileRef = storageRef.child(`vehicle_documents/${editingVehicle.id}/${fieldName}`);
            await fileRef.put(file);
            const downloadURL = await fileRef.getDownloadURL();

            // Update the form field with the new image URL
            form.setFieldsValue({ [fieldName]: downloadURL });

            // Update the editingVehicle state
            setEditingVehicle(prev => ({ ...prev, [fieldName]: downloadURL }));

            message.success(`${fieldName} uploaded successfully`);
            return false; // Prevent default upload behavior
        } catch (error) {
            console.error(`Error uploading ${fieldName}:`, error);
            message.error(`Failed to upload ${fieldName}`);
            return false;
        }
    };
    // const renderPdfLink = (url, label) => (
    //     <Button
    //         type="link"
    //         icon={<FileOutlined />}
    //         onClick={() => window.open(url, '_blank')}
    //     >
    //         {label}
    //     </Button>
    // );

    const renderDocumentLink = (url, label) => {
        const isImage = url.toLowerCase().match(/\.(jpeg|jpg|gif|png)$/);
        if (isImage) {
            return (
                <Button
                    type="link"
                    icon={<FileOutlined />}
                    onClick={() => window.open(url, '_blank')}
                >
                    View {label}
                </Button>
            );
        } else {
            return (
                <Button
                    type="link"
                    icon={<FileOutlined />}
                    onClick={() => window.open(url, '_blank')}
                >
                    {label}
                </Button>
            );
        }
    };

    const handleSendEmail = (value) => {
        setUserEmail(value.email)
        setUserName(value.ownerName)
        setIsModalMail(true);
    };

    const handleSend = async () => {
        console.log(userEmail, userName, "--------------------");

        try {
            const apiKey = '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'; // Replace with your SendinBlue API key
            const senderEmail = 'Info@harbourcabs.com.au'; // Replace with your sender email address
            const senderName = 'Harbour Cabs'; // Replace with your sender name

            const response = await axios.post(
                'https://api.sendinblue.com/v3/smtp/email',
                {
                    sender: { name: senderName, email: senderEmail },
                    to: [{ email: userEmail }],
                    subject: 'Provide the mentioned Data on this mail Info@harbourcabs.com.au',
                    htmlContent: `
              <!DOCTYPE html>
              <html lang="en">
              <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>Welcome Email</title>
                <style>
                  body {
                    font-family: Arial, sans-serif;
                    line-height: 1.6;
                    background-color: #f9f9f9;
                    padding: 20px;
                  }
                  .container {
                    max-width: 600px;
                    margin: 0 auto;
                    background-color: #fff;
                    padding: 20px;
                    border-radius: 10px;
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                  }
                  h1 {
                    color: #333;
                  }
                  p {
                    margin-bottom: 10px;
                  }
                  .message {
                    background-color: #f3f3f3;
                    padding: 15px;
                    border-radius: 5px;
                  }
                  .footer {
                    margin-top: 20px;
                    text-align: center;
                  }
                </style>
              </head>
              <body>
                <div class="container">
                  <h1>To Proceed to  Harbour Cabs App Please complete the mentioned Details!</h1>
                  <div class="message">
                    <p>Hi ${userName},</p>
                    <p>${messagee}</p>
                    <p>If you have any questions or need assistance, feel free to contact us at Info@harbourcabs.com.au</p>
                  </div>
                  <div class="footer">
                    <p>Best regards,</p>
                    <p>${senderName}</p>
                    <p>${senderEmail}</p>
                  </div>
                </div>
              </body>
              </html>
                    `,
                },
                {
                    headers: {
                        'api-key': apiKey,
                        'Content-Type': 'application/json',
                    },
                }
            );
            setIsModalMail(false);
            console.log('Email sent successfully:', response.data);
            setUserEmail('')
            setUserName('')
            setMessagee('')
            message.success('Email sent successfully')
        } catch (error) {
            console.error('Error sending email:', error.message);
        }

    };


    return (
        <div style={{ padding: '24px' }}>
            <div className="header" style={{ marginBottom: '24px' }}>
                <Title style={{ color: '#fff' }} level={2}>
                    Vehicles Information
                </Title>
            </div>
            <div style={{ marginBottom: '24px' }}>
                <a href="https://vehicleinfo-5c247.web.app/" target="_blank" rel="noopener noreferrer">
                    <Button type="primary">Add New Vehicle</Button>
                </a>
            </div>

            <Row gutter={16} style={{ marginBottom: '16px' }}>
                <Col span={12}>
                    <Input
                        placeholder="Search by name, email, or phone"
                        prefix={<SearchOutlined />}
                        onChange={(e) => handleSearch(e.target.value)}
                        value={searchText}
                    />
                </Col>
                <Col span={12}>
                    <Select
                        style={{ width: '100%' }}
                        placeholder="Filter by expiry"
                        onChange={handleExpiryFilter}
                        value={expiryFilter}
                    >
                        <Option value="all">All Vehicles</Option>
                        <Option value="allValid">All Valid</Option>
                        <Option value="anyExpiring">Any Expiring</Option>
                        <Option value="vehicleInsurance">Insurance Expiring</Option>
                        <Option value="cameraCertificate">Camera Certificate Expiring</Option>
                        <Option value="licensePhoto">License Expiring</Option>
                    </Select>
                </Col>
            </Row>

            <Table
                columns={columns}
                dataSource={filteredData}
                loading={loading}
                rowKey="id"
                pagination={{ pageSize: 10 }}
                bordered
                scroll={{ x: true }}
            />

            <Modal
                title={<Title level={3}><CarOutlined /> Vehicle Details</Title>}
                visible={modalVisible}
                onCancel={() => setModalVisible(false)}
                footer={null}
                width={860}
            >
                {selectedVehicle && (
                    <div style={{ background: '#f0f2f5', padding: '20px', borderRadius: '8px' }}>
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Text strong><CalendarOutlined /> Created At:</Text>
                                <Text> {moment(selectedVehicle.createdAt.toDate()).format('YYYY-MM-DD HH:mm')}</Text>
                            </Col>
                            <Col span={12}>
                                <Button
                                    type="primary"
                                    icon={<MailOutlined />}
                                    onClick={() => handleSendEmail(selectedVehicle)}
                                >
                                    Send Email
                                </Button>
                            </Col>
                        </Row>
                        <Divider />
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Text strong>Owner Name:</Text>
                                <Text> {selectedVehicle.ownerName}</Text>
                            </Col>
                            <Col span={12}>
                                <Text strong><MailOutlined /> Email:</Text>
                                <Text> {selectedVehicle.email}</Text>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Text strong><PhoneOutlined /> Phone:</Text>
                                <Text> {selectedVehicle.phone}</Text>
                            </Col>
                            <Col span={12}>
                                <Text strong><HomeOutlined /> Address:</Text>
                                <Text> {selectedVehicle.address}</Text>
                            </Col>
                        </Row>
                        <Divider />
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <Text strong>Vehicle Insurance Expiry:</Text>
                                {renderExpiryDate(selectedVehicle.vehicleInsuranceExpiry)}
                            </Col>
                            <Col span={24}>
                                <Text strong>Camera Certificate Expiry:</Text>
                                {renderExpiryDate(selectedVehicle.cameraCertificateExpiry)}
                            </Col>
                            <Col span={24}>
                                <Text strong>License Expiry:</Text>
                                {renderExpiryDate(selectedVehicle.licensePhotoExpiry)}
                            </Col>
                        </Row>
                        <Divider />
                        <Row>
                            <Col span={24}>
                                <Text strong>Notes:</Text>
                                <p>{selectedVehicle.notes}</p>
                            </Col>
                        </Row>
                        <Divider />
                        <Row>
                            <Col span={24}>
                                <Space>
                                    {renderDocumentLink(selectedVehicle.registrationPaper, "Registration Paper")}
                                    {renderDocumentLink(selectedVehicle.vehicleInsurance, "Vehicle Insurance")}
                                    {renderDocumentLink(selectedVehicle.cameraCertificate, "Camera Certificate")}
                                    {renderDocumentLink(selectedVehicle.licensePhoto, "License Photo")}
                                    {renderDocumentLink(selectedVehicle.pinkSlip, "Pink Slip")}
                                </Space>
                            </Col>
                        </Row>
                    </div>
                )}
            </Modal>

            <Modal
                title="Send Email"
                visible={isModalMail}
                onCancel={() => setIsModalMail(false)}
                footer={[
                    <Button key="cancel" onClick={() => setIsModalMail(false)}>
                        Cancel
                    </Button>,
                    <Button key="send" type="primary" onClick={handleSend}>
                        Send
                    </Button>,
                ]}
            >
                <TextArea
                    rows={4}
                    value={messagee}
                    onChange={(e) => setMessagee(e.target.value)}
                    placeholder="Enter your message here"
                />
            </Modal>

            <Modal
                title="Edit Vehicle Information"
                visible={editModalVisible}
                onCancel={() => setEditModalVisible(false)}
                footer={null}
            >
                <Form form={form} onFinish={handleEdit} layout="vertical">
                    <Form.Item name="ownerName" label="Owner Name" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="email" label="Email" rules={[{ required: true, type: 'email' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="phone" label="Phone" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="address" label="Address" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="vehicleInsuranceExpiry" label="Vehicle Insurance Expiry" rules={[{ required: true }]}>
                        <Input type="date" />
                    </Form.Item>
                    <Form.Item name="cameraCertificateExpiry" label="Camera Certificate Expiry" rules={[{ required: true }]}>
                        <Input type="date" />
                    </Form.Item>
                    <Form.Item name="licensePhotoExpiry" label="License Expiry" rules={[{ required: true }]}>
                        <Input type="date" />
                    </Form.Item>
                    <Form.Item name="notes" label="Notes">
                        <TextArea rows={4} />
                    </Form.Item>
                    <Form.Item name="registrationPaper" label="Registration Paper">
                        <Upload
                            beforeUpload={(file) => handleImageUpload(file, 'registrationPaper')}
                            listType="picture"
                            fileList={editingVehicle?.registrationPaper ? [{ url: editingVehicle.registrationPaper, uid: '-1' }] : []}
                        >
                            <Button icon={<UploadOutlined />}>Upload</Button>
                        </Upload>
                    </Form.Item>

                    <Form.Item name="vehicleInsurance" label="Vehicle Insurance">
                        <Upload
                            beforeUpload={(file) => handleImageUpload(file, 'vehicleInsurance')}
                            listType="picture"
                            fileList={editingVehicle?.vehicleInsurance ? [{ url: editingVehicle.vehicleInsurance, uid: '-1' }] : []}
                        >
                            <Button icon={<UploadOutlined />}>Upload</Button>
                        </Upload>
                    </Form.Item>

                    <Form.Item name="cameraCertificate" label="Camera Certificate">
                        <Upload
                            beforeUpload={(file) => handleImageUpload(file, 'cameraCertificate')}
                            listType="picture"
                            fileList={editingVehicle?.cameraCertificate ? [{ url: editingVehicle.cameraCertificate, uid: '-1' }] : []}
                        >
                            <Button icon={<UploadOutlined />}>Upload</Button>
                        </Upload>
                    </Form.Item>

                    <Form.Item name="licensePhoto" label="License Photo">
                        <Upload
                            beforeUpload={(file) => handleImageUpload(file, 'licensePhoto')}
                            listType="picture"
                            fileList={editingVehicle?.licensePhoto ? [{ url: editingVehicle.licensePhoto, uid: '-1' }] : []}
                        >
                            <Button icon={<UploadOutlined />}>Upload</Button>
                        </Upload>
                    </Form.Item>

                    <Form.Item name="pinkSlip" label="Pink Slip">
                        <Upload
                            beforeUpload={(file) => handleImageUpload(file, 'pinkSlip')}
                            listType="picture"
                            fileList={editingVehicle?.pinkSlip ? [{ url: editingVehicle.pinkSlip, uid: '-1' }] : []}
                        >
                            <Button icon={<UploadOutlined />}>Upload</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Update Vehicle Information
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default VehicleInfo;