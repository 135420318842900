// Home.js
import React, { useState } from 'react';
import { Table, Button, Input, Spin, message } from 'antd';
import Title from 'antd/es/typography/Title';
import { refs } from '../firebase/firebase';
import * as XLSX from 'xlsx';
import { DownloadOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';

const DownloadRides = () => {
    const [rides, setRides] = useState([]);
    const [loading, setLoading] = useState(false);
    const [driverId, setDriverId] = useState('');

    const formatDateWithTimeStamp = (timeStamp) => {
        return moment.unix(timeStamp).tz('Australia/Sydney').format("MMMM Do, hh:mm A");
    }

    const fetchData = async () => {
        if (!driverId) {
            message.error('Please enter a Driver ID');
            return;
        }
        try {
            setLoading(true);
            const query = refs.ridesData.where('userId', '==', `${driverId}@driverappauspak.com`)
            .orderBy('createdAt', 'desc');
            
            const querySnapshot = await query.get();

            const newArray = querySnapshot.docs.map(doc => {
                const rideData = doc.data();
                return {
                    id: doc.id,
                    createdAt: formatDateWithTimeStamp(rideData?.createdAt?.seconds),
                    endedAt: formatDateWithTimeStamp(rideData?.endedAt?.seconds),
                    carNumber: rideData?.car?.carNumber,
                    totalAmount: rideData?.totalAmount,
                    package: rideData?.package?.packageName || rideData?.package?.name,
                    startLocationName: rideData?.startLocationName,
                    endLocationName: rideData?.endLocationName,
                    distance: rideData?.distance + 'KM',
                    status: rideData.status,
                };
            });

            setRides(newArray);
        } catch (err) {
            console.log('Error', err);
            message.error('Failed to fetch rides');
        } finally {
            setLoading(false);
        }
    };

    const handleDownload = () => {
        const worksheet = XLSX.utils.json_to_sheet(rides);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'DriverRides');
        XLSX.writeFile(workbook, `driver_${driverId}_rides.xlsx`);
    };

    const columns = [
        {
            title: 'Ride Time',
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
        {
            title: 'Car Number',
            dataIndex: 'carNumber',
            key: 'carNumber',
        },
        {
            title: 'Total Bill',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
        },
        {
            title: 'Package',
            dataIndex: 'package',
            key: 'package',
        },
        {
            title: 'Distance',
            dataIndex: 'distance',
            key: 'distance',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
    ];

    return (
        <div>
            <div className="header">
                <Title style={{ color: '#fff' }} level={2}>
                    Driver Rides
                </Title>
            </div>
            <div style={{ margin: '20px 0' }}>
                <Input 
                    placeholder="Enter Driver ID" 
                    value={driverId} 
                    onChange={(e) => setDriverId(e.target.value)}
                    style={{ width: '200px', marginRight: '10px' }}
                />
                <Button type="primary" onClick={fetchData}>Search</Button>
                {rides.length > 0 && (
                    <Button 
                        type="primary" 
                        icon={<DownloadOutlined />} 
                        onClick={handleDownload}
                        style={{ marginLeft: '10px' }}
                    >
                        Download XLS
                    </Button>
                )}
            </div>
            {loading ? (
                <div className="custom-spinner">
                    <Spin size="large" />
                </div>
            ) : (
                <Table
                    dataSource={rides}
                    columns={columns}
                    className="custom-table"
                />
            )}
        </div>
    );
};

export default DownloadRides;