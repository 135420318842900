import React, { useState, useRef, useEffect } from 'react';
import { GoogleMap, Marker, Autocomplete } from '@react-google-maps/api';
import { useJsApiLoader } from '@react-google-maps/api';
import { Api_Key } from './Constants';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Button, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const MapComponent = ({ startPoint, endPoint, onStartPointChange, onEndPointChange }) => {
  const [center, setCenter] = useState({ lat: 24.885548, lng: 67.119473 });
  const [searchValue, setSearchValue] = useState('');
  const [placingMarker, setPlacingMarker] = useState(null);
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: Api_Key,
    libraries: ['places'],
  });
  const autocomplete = useRef(null);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCenter({ lat: latitude, lng: longitude });
        },
        (error) => {
          console.error("Error getting current location:", error);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, []);

  const mapStyles = {
    height: '400px',
    width: '100%',
  };

  const formatCoordinates = (lat, lng) => {
    const latDir = lat >= 0 ? 'N' : 'S';
    const lngDir = lng >= 0 ? 'E' : 'W';
    return `[${Math.abs(lat).toFixed(6)}° ${latDir}, ${Math.abs(lng).toFixed(6)}° ${lngDir}]`;
  };

  const handleMapClick = (e) => {
    const newPosition = e.latLng.toJSON();
    const formattedCoords = formatCoordinates(newPosition.lat, newPosition.lng);
    const geoPoint = new firebase.firestore.GeoPoint(newPosition.lat, newPosition.lng);

    if (placingMarker === 'start') {
      onStartPointChange({ ...newPosition, formatted: formattedCoords, geoPoint });
      setPlacingMarker(null);
    } else if (placingMarker === 'end') {
      onEndPointChange({ ...newPosition, formatted: formattedCoords, geoPoint });
      setPlacingMarker(null);
    }
  };

  const handlePlaceSelect = () => {
    if (autocomplete.current !== null) {
      const place = autocomplete.current.getPlace();
      if (place.geometry && place.geometry.location) {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        const newPosition = { lat, lng };
        setCenter(newPosition);
      }
    }
  };

  const createCustomMarkerIcon = (color, label) => {
    return {
      url: `data:image/svg+xml;utf-8,${encodeURIComponent(`
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
          <circle cx="20" cy="20" r="18" fill="${color}" stroke="white" stroke-width="2"/>
          <text x="20" y="25" font-size="12" font-weight="bold" text-anchor="middle" fill="black">${label}</text>
        </svg>
      `)}`,
      scaledSize: new window.google.maps.Size(40, 40),
      anchor: new window.google.maps.Point(20, 20),
    };
  };

  return isLoaded ? (
    <div>
      <Autocomplete
        onLoad={(autocompleteInstance) => {
          autocomplete.current = autocompleteInstance;
        }}
        onPlaceChanged={handlePlaceSelect}
      >
        <Input
          size="large"
          placeholder="Search for a location"
          prefix={<SearchOutlined />}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          style={{
            width: '100%',
            maxWidth: '400px',
            marginBottom: '20px',
            boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
          }}
        />
      </Autocomplete>
      <div style={{ marginTop: 20, marginBottom: 20 }}>
        <Button style={{ marginRight: 10 }} onClick={() => setPlacingMarker('start')}>Place Start Marker</Button>
        <Button onClick={() => setPlacingMarker('end')}>Place End Marker</Button>
      </div>
      <GoogleMap
        mapContainerStyle={mapStyles}
        zoom={12}
        center={center}
        onClick={handleMapClick}
      >
        {startPoint && (
          <Marker
            position={{ lat: startPoint.lat, lng: startPoint.lng }}
            icon={createCustomMarkerIcon("#4CAF50", "Start")}
          />
        )}
        {endPoint && (
          <Marker
            position={{ lat: endPoint.lat, lng: endPoint.lng }}
            icon={createCustomMarkerIcon("#F44336", "End")}
          />
        )}
      </GoogleMap>

      <div style={{ marginTop: 20 }}>
        {startPoint && (
          <div>Start Point: {startPoint.formatted}</div>
        )}
        {endPoint && (
          <div>End Point: {endPoint.formatted}</div>
        )}
      </div>
    </div>
  ) : (
    <div>Error: {loadError}</div>
  );
};

export default MapComponent;