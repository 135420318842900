// Home.js
import React, { useEffect, useState, useRef } from 'react';
import { Col, Row, Table, Button, DatePicker, Modal, Space, Input, Spin, Badge, InputNumber } from 'antd';
import Title from 'antd/es/typography/Title';
import moment from 'moment';
import { refs } from '../firebase/firebase';
import * as XLSX from 'xlsx';
import { SearchOutlined } from '@ant-design/icons';
import { EyeOutlined, DownloadOutlined } from '@ant-design/icons';
import { Line } from 'rc-progress';

import { Statistic } from 'antd';
import '../pages/css/Home.css'
const Home = ({ toggleDrawer }) => {


  const threeDaysAgo = moment().subtract(3, 'days');

  const [rides, setRides] = useState([]);
  const [dataForExport, setDataForExport] = useState([]);
  const [startDate, setStartDate] = useState(); // State for start date
  const [endDate, setEndDate] = useState(); // State for end date
  const [loading, setLoading] = useState(false); // Add loading state
  const [totalRides, setTotalRides] = useState(0);
  const [allridess, setAllRides] = useState(7500 + '+');
  const [limit, setLimit] = useState(220);

  useEffect(() => {
    // AllRides()
    fetchData();
  }, [startDate, endDate , limit]);

  useEffect(() => {
    // AllRides()
    setTotalRides(rides.length);
  }, [rides]);

  const formatDateWithTimeStamp = (timeStamp) => {
    return moment.unix(timeStamp).utcOffset('+1100').format("MMMM Do, hh:mm A")
  }

  const fetchData = async () => {
    try {
      setLoading(true);
      const start = startDate.startOf('day').toDate();
      const end = endDate.endOf('day').toDate();

      const query = refs.rides
        .orderBy('createdAt', 'desc')
        .limit(limit)
        .where('createdAt', '>=', start)
        .where('createdAt', '<=', end);

      const querySnapshot = await query.get();

      //in this push all the data 

      // Push all the data
      // const promises = querySnapshot.docs.map(async (doc) => {
      //   try {
      //     await refs.ridesData.add(doc.data());
      //   } catch (error) {
      //     console.error("Error adding document to ridesData: ", error);
      //   }
      // });


      // // Wait for all documents to be added
      // await Promise.all(promises);

      const newArray = [];
      const workBookData = [];

      querySnapshot.forEach((documentSnapshot) => {
        const rideData = documentSnapshot.data();

        console.log(rideData, "ride dataaa");
        const formattedDate = formatDateWithTimeStamp(rideData?.createdAt?.seconds);
        const formattedDate2 = formatDateWithTimeStamp(rideData?.endedAt?.seconds);
        newArray.push({
          createdAt: formattedDate,
          endedAt: formattedDate2,
          carNumber: rideData?.car?.carNumber,
          totalAmount: rideData?.totalAmount,
          package: rideData?.package?.packageName ? rideData?.package?.packageName : rideData?.package?.name,
          userId: rideData?.userId.split('@')[0],
          startLocationName: rideData?.startLocationName,
          endLocationName: rideData?.endLocationName,
          distance: rideData?.distance + 'KM',
          totalToll: rideData?.tollCollection?.totalAmount,
          startLocationLng: rideData?.startLocation?.longitude,
          startLocationLat: rideData?.startLocation?.latitude,
          endLocationLng: rideData?.userLocation?.longitude,
          endLocationLat: rideData?.userLocation?.latitude,
          coordinatesArray: rideData?.coordinatesArray,
          ...documentSnapshot,
        });

        workBookData.push({
          Ride_Date: moment.unix(rideData.createdAt).utcOffset('+1100').format('MMMM Do, hh:mm A'),
          Drop_Date: moment.unix(rideData.endedAt).utcOffset('+1100').format('MMMM Do, hh:mm A'),
          Car_Number: rideData?.car?.carNumber,
          Package: rideData?.package?.packageName ? rideData?.package?.packageName : rideData?.package?.name,
          User_ID: rideData?.userId.split('@')[0],
          Start_Location: rideData?.startLocationName,
          End_Location: rideData?.endLocationName,
          Total_Toll: rideData?.tollCollection?.totalAmount,
          startLocationLng: rideData?.startLocation?.longitude,
          startLocationLat: rideData?.startLocation?.latitude,
          endLocationLng: rideData?.userLocation?.longitude,
          endLocationLat: rideData?.userLocation?.latitude,
          Distance: rideData?.distance + 'KM',
          Total_Bill: rideData?.totalAmount,
          coordinatesArray: rideData?.coordinatesArray,
        });
      });

      setRides(newArray);
      setDataForExport(workBookData);
    } catch (err) {
      console.log('Error', err);
    } finally {
      setLoading(false);
    }
  };


  // fech data old 


  // const fetchData = async () => {
  //   try {
  //     setLoading(true);
  //     const start = startDate.startOf('day').toDate();
  //     const end = endDate.endOf('day').toDate();

  //     const query = refs.rides
  //       .orderBy('createdAt', 'desc')
  //       .where('createdAt', '>=', start)
  //       .where('createdAt', '<=', end);

  //     const querySnapshot = await query.get();

  //     const newArray = [];
  //     const workBookData = [];

  //     querySnapshot.forEach((documentSnapshot) => {
  //       const rideData = documentSnapshot.data();

  //       console.log(rideData, "ride dataaa");

  //       newArray.push({
  //         createdAt: moment.unix(rideData.createdAt).utcOffset('+1100').format('MMMM Do, hh:mm A'),
  //         endedAt: moment.unix(rideData.endedAt).utcOffset('+1100').format('MMMM Do, hh:mm A'),
  //         carNumber: rideData?.car?.carNumber,
  //         totalAmount: rideData?.totalAmount,
  //         package: rideData?.package?.packageName ? rideData?.package?.packageName : rideData?.package?.name,
  //         userId: rideData?.userId.split('@')[0],
  //         startLocationName: rideData?.startLocationName,
  //         endLocationName: rideData?.endLocationName,
  //         distance: rideData?.distance + 'KM',
  //         totalToll: rideData?.tollCollection?.totalAmount,
  //         ...documentSnapshot,
  //       });

  //       workBookData.push({
  //         Ride_Date: moment.unix(rideData.createdAt).utcOffset('+1100').format('MMMM Do, hh:mm A'),
  //         Drop_Date: moment.unix(rideData.endedAt).utcOffset('+1100').format('MMMM Do, hh:mm A'),
  //         Car_Number: rideData?.car?.carNumber,
  //         Package: rideData?.package?.packageName ? rideData?.package?.packageName : rideData?.package?.name,
  //         User_ID: rideData?.userId.split('@')[0],
  //         Start_Location: rideData?.startLocationName,
  //         End_Location: rideData?.endLocationName,
  //         Total_Toll: rideData?.tollCollection?.totalAmount,
  //         Distance: rideData?.distance + 'KM',
  //         Total_Bill: rideData?.totalAmount,
  //       });
  //     });

  //     setRides(newArray);
  //     setDataForExport(workBookData);
  //   } catch (err) {
  //     console.log('Error', err);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // const AllRides = async () => {
  //   try {


  //     const query = refs.rides.orderBy('createdAt', 'desc');

  //     const querySnapshot = await query.get();

  //     const newArray = [];
  //     const workBookData = [];

  //     querySnapshot.forEach((documentSnapshot) => {
  //       const rideData = documentSnapshot.data();

  //     });
  //     // Calculate and set the total number of rides
  //     // setAllRides(querySnapshot.size);
  //   } catch (err) {
  //     console.log('Error', err);
  //   } finally {
  //     setLoading(false);
  //   }
  // };


  const handleDownload = () => {
    const worksheet = XLSX.utils.json_to_sheet(dataForExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'RidesData');
    XLSX.writeFile(workbook, 'rides_data.xlsx');
  };

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const [searchUserId, setSearchUserId] = useState('');
  const [searchCarNumber, setSearchCarNumber] = useState('');

  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    if (dataIndex === 'userId') {
      setSearchUserId(selectedKeys[0]);
    } else if (dataIndex === 'carNumber') {
      setSearchCarNumber(selectedKeys[0]);
    }
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              if (dataIndex === 'userId') {
                setSearchUserId(selectedKeys[0]);
              } else if (dataIndex === 'carNumber') {
                setSearchCarNumber(selectedKeys[0]);
              }
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      if (dataIndex === 'userId') {
        return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase());
      } else if (dataIndex === 'carNumber') {
        return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase());
      }
      return false;
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <div>{text ? text.toString() : ''}</div>
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: 'User ID',
      dataIndex: 'userId',
      key: 'userId',
      ...getColumnSearchProps('userId'),
    },
    {
      title: 'Ride Time',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Car Number',
      dataIndex: 'carNumber',
      key: 'carNumber',
      ...getColumnSearchProps('carNumber'),
    },
    {
      title: 'Total Bill',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
    },
    {
      title: 'Package',
      dataIndex: 'package',
      key: 'package',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <div>
          <Button type="dashed" onClick={() => openModal(record)}>
            <EyeOutlined /> View
          </Button>

        </div>
      ),
    },
  ];

  const [selectedRide, setSelectedRide] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  // const mapContainerRef = useRef(null);
  const [isMapLoading, setIsMapLoading] = useState(false);



  const mapRef = useRef(null);

  const clearMap = () => {
    if (mapRef.current) {
      // Clear the polyline
      if (mapRef.current.polyline) {
        mapRef.current.polyline.setMap(null);
        mapRef.current.polyline = null;
      }

      // Clear the directions renderer
      if (mapRef.current.directionsRenderer) {
        mapRef.current.directionsRenderer.setMap(null);
      }
    }
  };


  const getDirections = (coordinatesArray) => {
    const map = mapRef.current.map;

    if (mapRef.current.polyline) {
      mapRef.current.polyline.setMap(null);
    }
    if (mapRef.current.startMarker) {
      mapRef.current.startMarker.setMap(null);
    }
    if (mapRef.current.endMarker) {
      mapRef.current.endMarker.setMap(null);
    }

    const validCoordinates = coordinatesArray
      .filter((coord) => coord.latitude !== undefined && coord.longitude !== undefined)
      .map((coord) => new window.google.maps.LatLng(coord.latitude, coord.longitude));

    if (validCoordinates.length < 2) {
      console.error('Insufficient valid coordinates to draw a polyline.');
      return;
    }

    const polyline = new window.google.maps.Polyline({
      path: validCoordinates,
      geodesic: true,
      strokeColor: '#1E90FF',
      strokeOpacity: 0.8,
      strokeWeight: 5,
    });


    mapRef.current.polyline = polyline;
    polyline.setMap(map);


    const bounds = new window.google.maps.LatLngBounds();
    validCoordinates.forEach((point) => bounds.extend(point));


    const startMarker = new window.google.maps.Marker({
      position: validCoordinates[0],
      label: 'A',
      map: map,
    });

    const endMarker = new window.google.maps.Marker({
      position: validCoordinates[validCoordinates.length - 1],
      label: 'B',
      map: map,
    });


    mapRef.current.startMarker = startMarker;
    mapRef.current.endMarker = endMarker;


    console.log('Bounds:', bounds);

    map.fitBounds(bounds);


    const totalDistance = calculateTotalDistance(validCoordinates);


    const infoWindow = new window.google.maps.InfoWindow({
      content: `Total Distance: ${totalDistance.toFixed(2)} km`,
    });


    const centerPoint = bounds.getCenter();
    infoWindow.setPosition(centerPoint);
    infoWindow.open(map);
  };



  const calculateTotalDistance = (coordinates) => {
    let totalDistance = 0;

    for (let i = 1; i < coordinates.length; i++) {
      const distance = window.google.maps.geometry.spherical.computeDistanceBetween(
        coordinates[i - 1],
        coordinates[i]
      );
      totalDistance += distance;
    }


    return totalDistance / 1000;
  };





  const handleMapLoad = (record) => {

    console.log(record.coordinatesArray, ':record.coordinatesArray');

    clearMap();

    if (mapContainerRef.current && record && record.coordinatesArray) {
      const validCoordinates = record.coordinatesArray.filter(
        (coord) => coord.latitude !== undefined && coord.longitude !== undefined
      );

      if (validCoordinates.length > 1) {
        if (!window.google || !window.google.maps) {
          console.error('Google Maps API not loaded.');
          return;
        }

        const map = new window.google.maps.Map(mapContainerRef.current, {
          zoom: 14,
          center: new window.google.maps.LatLng(
            validCoordinates[0].latitude,
            validCoordinates[0].longitude
          ),
          draggable: true,
        });

        mapRef.current = {
          map,
          polyline: null,
        };

        getDirections(validCoordinates);
      } else {
        console.error('No valid coordinates found.');
      }
    }
  };


  const openModal = (ride) => {
    setSelectedRide(ride);
    setIsModalVisible(true);
    handleMapLoad(ride);
  };


  const handleCancel = () => {
    setIsModalVisible(false);
    clearMap(); // Add this line to clear the map when the modal is closed
  };



  // const openMapInNewTab = () => {
  //   if (selectedRide && selectedRide.coordinatesArray && selectedRide.coordinatesArray.length > 0) {
  //     setIsMapLoading(true);

  //     const coordinatesArray = selectedRide.coordinatesArray;

  //     // Convert coordinatesArray to triangleCoords format
  //     const triangleCoords = coordinatesArray.map(coord => [coord.latitude, coord.longitude]);

  //     // Create an array of LatLng objects
  //     const points = triangleCoords.map(coord => new google.maps.LatLng(coord[0], coord[1]));

  //     // Construct the polygon
  //     const polygon = new google.maps.Polygon({
  //       paths: points,
  //       strokeColor: '#FF0000', // Stroke color
  //       strokeOpacity: 0.8,      // Stroke opacity
  //       strokeWeight: 2,         // Stroke weight
  //       fillColor: '#FF0000',   // Fill color
  //       fillOpacity: 0.35        // Fill opacity
  //     });

  //     // Calculate centerLat and centerLng
  //     const minLat = Math.min(...triangleCoords.map(coord => coord[0]));
  //     const maxLat = Math.max(...triangleCoords.map(coord => coord[0]));
  //     const minLng = Math.min(...triangleCoords.map(coord => coord[1]));
  //     const maxLng = Math.max(...triangleCoords.map(coord => coord[1]));

  //     const centerLat = (minLat + maxLat) / 2;
  //     const centerLng = (minLng + maxLng) / 2;

  //     // Log URL for debugging
  //     console.log("Center Coordinates:", centerLat, centerLng);

  //     // Create a new map centered on the polygon
  //     const map = new google.maps.Map(document.createElement('div'), {
  //       center: new google.maps.LatLng(centerLat, centerLng),
  //       zoom: 15
  //     });

  //     // Set the polygon on the map
  //     polygon.setMap(map);

  //     // Log URL for debugging
  //     const mapUrl = `https://www.google.com/maps/@${centerLat},${centerLng},15z`;
  //     console.log("Map URL:", mapUrl);

  //     // Open map in a new tab
  //     window.open(mapUrl, '_blank');

  //     setIsMapLoading(false);
  //   } else {
  //     console.error("No valid coordinates array found.");
  //   }
  // };


  const mapContainerRef = useRef(null);








  // const openMapInNewTab = () => {
  //   if (selectedRide) {
  //     setIsMapLoading(true);
  //     const mapUrl = `https://www.google.com/maps/dir/?api=1&origin=${selectedRide.startLocationLat},${selectedRide.startLocationLng}&destination=${selectedRide.endLocationLat},${selectedRide.endLocationLng}`;
  //     window.open(mapUrl, '_blank');
  //     setIsMapLoading(false);
  //   }
  // };



  return (
    <div>
      {/* Blue Header */}
      <div className="header">
        <Title style={{ color: '#fff' }} level={2}>
          Rides
        </Title>
      </div>
      <Row gutter={[16, 16]} className="row-counter-container" style={{ height: '200px' }}>
        {/* First Counter */}
        {/* <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <div
            style={{
              background: 'white',
              padding: '16px',
              boxSizing: 'border-box',
              border: '1px solid #1890ff',
              borderRadius: '8px',
              marginLeft: '16px',
            }}
            className="counter center-content">
            <div className="counter-title">Total Rides</div>
            <div className="counter-value">{allridess}</div>
           
          </div>
        </Col> */}

        {/* Second Counter */}
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <div
            style={{
              background: 'white',
              padding: '16px',

              boxSizing: 'border-box',
              border: '1px solid #1890ff',
              borderRadius: '8px',
              marginLeft: '16px',
            }}
            className="counter secondary-counter">
            <div style={{ textAlign: 'center', marginBottom: '16px' }}>
              <Title level={4} className="secondary-counter-title">
                Total Rides Between Selected Dates
              </Title>
            </div>
            <Line
              percent={(totalRides / 1000) * 100} // Assuming a maximum of 1000 rides
              strokeWidth="2"
              strokeColor="#1890ff" // Adjust the color
              trailWidth="2"
            />
            <p className="line-chart-text">{totalRides}</p>
          </div>
        </Col>
      </Row>
      <div className="table-container">
        <div className="date-picker-container">
          <Row gutter={[16, 16]}>
            <Col>
              <DatePicker.RangePicker
                value={[startDate, endDate]}
                onChange={(dates) => {
                  if (dates === null || dates[0] === null || dates[1] === null) {
                    // Handle null values when dates are canceled
                    setStartDate(null);
                    setEndDate(null);
                  } else {
                    setStartDate(dates[0]);
                    setEndDate(dates[1]);
                  }
                }}
              />
            </Col>
            <Col>
              <InputNumber
                min={1}
                max={1000}
                defaultValue={220}
                onChange={value => setLimit(value)}
              />
            </Col>
          </Row>
        </div>
        {/* Conditional rendering of loader */}
        {loading ? (
          <div className="custom-spinner">
            <Spin size="large" />
          </div>
        ) : (
          <Table onRow={(record) => ({
            onClick: () => openModal(record),
          })} dataSource={rides} columns={columns} className="custom-table" />
        )}
        <div className="download-button-container">
          <Button type="primary" onClick={handleDownload}>
            Download Data
          </Button>
        </div>

        {/* Modal to display ride details */}
        <Modal
          // title="Ride Details"
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Close
            </Button>,
            // <Button key="showMap" type="primary">
            //   Show on Map
            // </Button>,
          ]}
        >
          <div className="modal-card">
            <div className="modal-card-header">
              <Title level={4} style={{ margin: 0 }}>
                Ride Details
              </Title>
            </div>
            <div className="modal-card-content">
              {/* Render the ride details in the modal */}
              {selectedRide && (
                <div>
                  <p>User Id: {selectedRide?.userId}</p>
                  <p>Ride Time: {selectedRide?.createdAt}</p>
                  <p>Drop Time: {selectedRide?.endedAt}</p>

                  <p>Car Number: {selectedRide?.carNumber}</p>

                  <p>Package: {selectedRide?.package}</p>
                  <p>Start Location: {selectedRide?.startLocationName}</p>
                  <p>Drop Location: {selectedRide?.endLocationName}</p>
                  <p>Distance: {selectedRide?.distance}</p>
                  {/* <p>Extra: {selectedRide?.extraCostAdd > 0 ? selectedRide?.extraCostAdd : 0}</p> */}
                  <p>Toll: {selectedRide?.totalToll > 0 ? selectedRide?.totalToll.toFixed(2) : 0}</p>

                  <p>Total Bill: {selectedRide?.totalAmount}</p>
                  {/* Add more details as needed */}

                  <div ref={mapContainerRef} style={{ height: '400px', width: '100%' }}></div>

                </div>

              )}
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Home;
